import api from '@/api/Api'

export default {

  create (post) {
    return api.post('/posts', post)
  },

  find (id) {
    return api.get(`/posts/${id}`)
  },

  update (post) {
    return api.put(`/posts/${post.id}`, post)
  },

  delete (id) {
    return api.delete(`/posts/${id}`)
  },

  list (query, options) {
    return api.get('/posts', query, options)
  },

  recommended (query, options) {
    return api.get('/posts/recommended', query, options)
  },

  timeline (query, options) {
    return api.get('/timeline', query, options)
  },

  like (id) {
    return api.post(`/posts/${id}/like`)
  },

  unlike (id) {
    return api.delete(`/posts/${id}/like`)
  },

  save (id) {
    return api.post(`/posts/${id}/save`)
  },

  unsave (id) {
    return api.delete(`/posts/${id}/save`)
  },

  share (id) {
    return api.post(`/posts/${id}/share`)
  },

  unshare (id) {
    return api.delete(`/posts/${id}/share`)
  },

  buy (id, attrs) {
    return api.post(`/posts/${id}/buy`, attrs)
  },

  requestPayment (id, attrs) {
    return api.post(`/posts/${id}/request_payment`, attrs)
  },

  count () {
    return api.get('/posts/count')
  },
  twitterShare(postId, attrs = {}) {
    return api.post('/twitter/publish_post', { postId, ...attrs })
  }
}
