<template>
  <v-row v-if="loading">
    <v-col v-for="i in items" :key="i"
      cols="6" sm="4" md="3">
      <v-skeleton-loader type="image, list-item-two-line" :dark="darkTheme" />
    </v-col>
  </v-row>
  <section v-else>
    <slot />
  </section>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ThumbListSkeleton',
  props: {
    loading: Boolean,
    items: { type: Number, default: 8 }
  },
  computed: {
    ...mapState('preferences', ['darkTheme'])
  }
}

</script>
