<template>
  <v-row v-if="value"
    align="center" justify="center" class="my-8">
    <v-progress-circular
      color="#E400A0"
      indeterminate
      class="p-4">
    </v-progress-circular>
  </v-row>
</template>

<script>

export default {
  name: "LoadingSpinner",
  props: { value: Boolean }
}
</script>

