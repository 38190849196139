import api from '@/api/Api'
import cookies from '@/cookies'

const SentWindow = 3 * 60 * 1000
const checkSendingWindow = (date) => {
  if (!date) {
    return true
  }

  const now = new Date().getTime()
  const sentAt = date.getTime()
  return now > (sentAt + SentWindow)
}

export default {

  auth () {
    return api.post('/account/auth')
  },

  oauth (token) {
    return api.post('/account/oauth', { token })
  },

  signup (attrs) {
    attrs.referral = cookies.get('referral')
    return api.post('/account', attrs)
  },

  finishSignup (attrs) {
    return api.put('/account', attrs)
  },

  login (login, password) {
    return api.post('/account/login', { login, password })
  },

  logout () {
    return api.delete('/account/logout')
  },

  requestPassword (email) {
    return api.post('/account/password_request', { email })
  },

  resetPassword (token, password) {
    return api.put('/account/password_reset', { token, password })
  },

  changePassword ({ currentPassword, password }) {
    return api.put('/account/passwords', { currentPassword, password })
  },

  requestValidation () {
    if (checkSendingWindow(this.emailSentAt)) {
      this.emailSentAt = new Date()
      return api.post('/account/email_validation')
    } else {
      return { error: true }
    }
  },

  validate (token) {
    return api.post('/account/validate', { token })
  }

}
