import Box from '@/components/custom/Box'
import PasswordInput from '@/components/custom/PasswordInput'
import CountrySelect from '@/components/custom/CountrySelect'
import Carousel from '@/components/custom/Carousel'
import Swiper from '@/components/custom/Swiper'
import ThumbListSkeleton from '@/components/skeletons/ThumbListSkeleton'
import VideoPageSkeleton from '@/components/skeletons/VideoPageSkeleton'
import Slider from '@/components/custom/Slider'
import Dialog from '@/components/custom/Dialog'
import Button from '@/components/custom/Button'
import LoadingSpinner from '@/components/custom/LoadingSpinner'
import UsernameInput from '@/components/custom/UsernameInput'
import SectionTitle from '@/components/custom/SectionTitle'
import Image from '@/components/custom/Image'

export default {
  install (Vue) {
    Vue.component(Box.name, Box)
    Vue.component(PasswordInput.name, PasswordInput)
    Vue.component(CountrySelect.name, CountrySelect)
    Vue.component(Carousel.name, Carousel)
    Vue.component(Swiper.name, Swiper)
    Vue.component(ThumbListSkeleton.name, ThumbListSkeleton)
    Vue.component(VideoPageSkeleton.name, VideoPageSkeleton)
    Vue.component(Slider.name, Slider)
    Vue.component(Dialog.name, Dialog)
    Vue.component(Button.name, Button)
    Vue.component(Image.name, Image)
    Vue.component(LoadingSpinner.name, LoadingSpinner)
    Vue.component(UsernameInput.name, UsernameInput)
    Vue.component(SectionTitle.name, SectionTitle)
  }
}
