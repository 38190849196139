<template>
  <v-footer class="app-footer footer" :style="isDark">
    <v-row no-gutters justify="space-between">
      <v-spacer></v-spacer>
      <v-col cols="9">
        <v-row justify="center" class="link" style="font-weight: bold">
          <router-link class="item link semi-opaque" to="/cookies">
            {{ $t('footer.cookies') }}
          </router-link>
          <router-link class="item link semi-opaque" to="/conditions">
            {{ $t('footer.terms_conditions') }}
          </router-link>
          <router-link class="item link semi-opaque" to="/privacy">
            {{ $t('footer.privacy') }}
          </router-link>
        </v-row>
        <v-row justify="center">
          <span class="item">{{$t('footer.disclaimer')}}</span>
        </v-row>
        <v-row justify="center">
          <img src="images/artemisa3000.png" style="margin-top: 4px;width: 220px;height: 15px;margin-right: 5px;"/>
          CIF:<img src="images/cif_white.png" style="margin-top: 3px; margin-right: 3px; margin-left: 3px; width: 6rem; height: 12px"/>
          <span class="item text-center">
            {{ $t('application.company_address') }},
            {{ $t('application.company_city') }},
            {{ $t('application.company_country') }}
          </span>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-footer>
</template>
<script>
import project from '@/project'

export default {
  name: 'Footer',
  computed: {
    isDark () {
      return {
        backgroundColor: project.theme === 'dark' ? '#656565' : '#c5c5c5',
        color: project.theme === 'dark' ? '#B9B9B9' : '#f2f2f2'
      }
    }
  },
  data () {
    return {
      project
    }
  }
}
</script>
<style lang="scss">
@use 'src/scss/colors';

.app-footer {
  &.footer {
    padding: 30px 40px;
  }
  .item {
    font-size: 12px;
    line-height: 24px;
    margin: 0 5px;
  }

  .link {
    text-decoration: none;
    color: #f2f2f2 !important;
    &:hover {
      color: #2e2b2b !important;
      opacity: 1;
    }
  }
}
</style>
