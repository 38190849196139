import SessionView from '@/views/SessionView'
import HomeView from '@/views/HomeView'
import VideoView from '@/views/VideoView'
import EmptyRouterView from '@/views/EmptyRouterView'
import ProfileView from '@/views/ProfileView'
import CookiesView from '@/views/CookiesView'
import PrivacyView from '@/views/PrivacyView'
import ConditionsView from '@/views/ConditionsView'
import UnloggedLightLayout from '@/components/layouts/UnloggedLight'

const routes = [
  {
    path: '/cookies',
    name: 'cookies',
    component: CookiesView,
    meta: { layout_set: true, layout: UnloggedLightLayout }
  },
  {
    path: '/conditions',
    name: 'conditions',
    component: ConditionsView,
    meta: { layout_set: true, layout: UnloggedLightLayout }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
    meta: { layout_set: true, layout: UnloggedLightLayout }
  },
  {
    path: '/',
    component: EmptyRouterView,
    props: true,
    children: [{
      path: '',
      name: 'home',
      component: HomeView,
      props: true
    }, {
      path: '/home',
      name: 'home',
      component: HomeView,
      props: true
    }, {
      path: '/categories/:category',
      name: 'category_home',
      component: HomeView,
      props: true
    }, {
      path: '/:username',
      name: 'user',
      component: ProfileView,
      props: true
    }]
  },
  {
    path: '/login',
    name: 'login',
    component: SessionView,
    props: { form: 'login' }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SessionView,
    props: true
  },
  {
    path: '/videos/:id',
    name: 'video',
    component: VideoView,
    props: true
  }
]

export default routes
