<template>
  <div class="box-title">
    <h1 class="section-title upper"
      :class="sectionClasses" :style="isDark">
      <img v-if="icon" :src="`/assets/${icon}`" />
      <strong class="mx-1" style="font-family: Oswald-Regular, sans-serif"><span class="sect-border">❚</span> {{ title }}</strong>
    </h1>
  </div>
</template>

<script>
import project from '@/project'

export default {
  name: 'SectionTitle',
  props: {
    icon: String,
    title: String,
    boxClass: { type: String, default: 'background-grey' },
    textColor: String,
    backgroundClass: { type: String, default: 'background-grey-dark' }
  },
  computed: {
    sectionClasses () {
      return {
        [`${this.textColor}-text`]: !!this.textColor
      }
    },
    isDark () {
      return {
        color: project.theme === 'dark' ? 'white' : 'black'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .box-title {
    width: 100%;
    margin: 20px 0;
  }
  .section-title {
    font-size: 1.5em;
    color: #000000;
    padding: 0 10px 0 0;
    display: inline-block;
    vertical-align: bottom;
  }
  .blue-text {
    color: #347FD3;
  }
  .pink-text {
    color: #E400A0;
  }
  .sect-border {
    color: var(--v-primary-base)
  }
</style>
