<template>
<v-form
  class="session-wrapper"
  @submit="startSession" style="text-align: center">
  <div class="img-wrapper">
		<img class="img-responsive" src="/assets/logo.svg">
	</div>
  <v-row>
    <v-col v-if="!isLoggedIn"
      cols="12">
      <SignupForm v-if="!login"
        v-bind.sync="signupModel" />
      <LoginForm v-else
        v-bind.sync="loginModel"  />
    </v-col>
  </v-row>
  <v-alert v-if="error"
    dense text
    type="error">
    {{ error }}
  </v-alert>
  <v-row v-if="!this.user || !this.offer">
    <v-col cols="12">
      <v-btn block depressed color="#41b849" class="white-text"
        @click="startSession" :disabled="loading" :loading="loading">
        {{ $t('actions.continue') }}
      </v-btn>
    </v-col>
  </v-row>
  <div v-if="!user" class="text-center">
    <span class="mr-1"><strong>{{ $t(`session.${questionSentence}`) }}</strong></span>
    <span class="mr-1" @click="$emit('switchForm')"><strong :style="colorCss">{{ $t(`session.${linkSentence}`) }}</strong></span>
  </div>
</v-form>
</template>

<script>
import { mapState } from 'vuex'
import project from '@/project'
import { SessionActions } from '@/store'
import LoginForm from '@/components/sessions/Login'
import SignupForm from '@/components/sessions/Signup'

export default {
  name: 'SessionForm',
  components: { LoginForm, SignupForm },
  props: {
    login: Boolean,
    origin: { type: String, default: 'landing' }
  },
  data () {
    return {
      project,
      error: '',
      isLoggedIn: false,
      signupModel: {
        username: '',
        email: '',
        password: '',
        acceptedTerms: true,
        isAdult: true
      },
      loginModel: {
        login: '',
        password: ''
      },
      loading: false
    }
  },
  computed: {
    ...mapState('session', ['user']),
    questionSentence () {
      return this.showSignup ? 'already_member_question' : 'not_registered_question'
    },
    linkSentence () {
      return this.showSignup ? 'click_login' : 'click_signup_seconds'
    },
    otherSessionRoute () {
      const name = this.showSignup ? 'login' : 'signup'
      return { name }
    },
    colorCss () {
      return {
        color: project.primary_color
      }
    }
  },
  watch: {
    login: {
      handler(val) {
        this.$emit('update:login-model', val)
      },
      deep: true
    },
    signup: {
      handler(val) {
        this.$emit('update:signup-model', val)
      },
      deep: true
    }
  },
  methods: {
    async startSession() {
      this.loading = true
      let logged = this.user
      if (!logged && !this.login) {
        logged = await this.doSignup()
      } else if (!logged) {
        logged = await this.doLogin()
      }
      if (logged) this.$emit('close')
      this.loading = false
    },
    async doSignup() {
      const { username, email, password } = this.signupModel
      if (!(username && email && password)) {
        this.error = 'All fields are mandatories'
        return
      }
      const { data, error } = await this.$store.dispatch(SessionActions.Signup, {
        username: username.trim(), email, password, acceptedTerms: true, isAdult: true
      })
      if (error) {
        const field = this.$t(`models.user.${data.data.field}`)
        this.error = this.$t(`remote_errors.${data.errorCode}`, { field, value: data.data.value })
      } else {
        this.$emit('close')
      }
      this.isLoggedIn = !!this.user
      return !error
    },
    async doLogin() {
      const { login, password } = this.loginModel
      if (!(login && password)) {
        this.error = 'All fields are mandatories'
        return
      }
      const { data, error } = await this.$store.dispatch(SessionActions.Login, this.loginModel)
      if (error) {
        this.error = this.$t(`remote_errors.${data.errorCode}`)
      }
      this.isLoggedIn = !!this.user
      return !error
    }
  }
}
</script>
<style lang="scss" scoped>
.session-wrapper {
  margin: 15px auto;
  padding: 15px;
  max-width: 100%;
}
.img-wrapper {
  position: relative;
}
.img-responsive {
  max-width: 100%;
  height: auto;
  min-width: 40px;
  vertical-align: middle;
  border-style: none;
  border: none;
}
.logochica {
  position: absolute;
  width: 220px;
}
.subtit-registro {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  line-height: 1em;
  position: absolute;
  top: 36px;
  left: 56px;
  filter: drop-shadow( 2px 2px 3px rgba(0, 0, 0, .8));
}
</style>
