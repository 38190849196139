<template>
  <section :style="{maxWidth: isMobile ? '98%' : '100%', margin: 'auto'}"  :class="isDark">
    <v-row v-once justify="center" >
      <v-col cols="12" md="6" style="font-size: 16px !important;">
      <h1 class="h1--title">Cookies Policy</h1>
      <p>This document informs about the use of data storage and recovery devices in terminal equipment of the Users.</p>
      <br>
      <h3 id="access">1. What are Cookies?</h3>
      <p>Cookies are files that are downloaded to the User's computer / smartphone / tablet when accessing to certain web pages and applications that allow the user's
        browsing preferences to be stored, making the interaction between the User and the Website faster and easy.
      </p>
      <p>The information collected by cookies is anonymous and doesn't contain any sensitive information since they don't collect data that can personally identify
        the User. In any case, the User may access the settings of their browser to modify and / or block the installation of Cookies sent from the Website,
        without preventing access to the content.
      </p>
      <br>
      <h3 id="object">2. What does the owner use cookies for?</h3>
      <p>In accordance with Recital 30 of the General Data Protection Regulation ("RGPD"), this Website uses cookies or other devices for storing and retrieving
        information to track the interactions of Users with the services offered in the website.
      </p>
      <p>Cookies allow the recognition of a User's browser, as well as the type of device from which the Website is accessed and are used to facilitate the
        User's next visit and make the Website more useful to them.
      </p>
      <br>
      <h3 id="functionallity">3. How to disable cookies?</h3>
      <p>Those cookies that aren't mandatory for browsing this Website may be disabled in the configuration of the Website. These settings are found in the
        footer of the Website. In addition, all browsers allow changes to be made to disable cookie settings.
      </p>
      <p>This is the reason why most browsers offer the possibility of managing cookies, to obtain more precise control over privacy. These settings are
        located in the "options" or "preferences" of the menu of your browser.
      </p>
      <br>
      <h3 id="functionallity">4. Cookies in Mobile Devices</h3>
      <p>The owner of the Website also uses cookies or other storage devices on mobile devices.</p>
      <p>Those cookies that aren't mandatory for browsing this Website may be disabled in the configuration of the Website.</p>
      <p>These settings can be found in the footer of the Website. In addition, as in computer browsers, mobile device browsers allow you to make
        changes to privacy options or settings to disable or delete cookies.
      </p>
      <p>If you want to modify the privacy options, follow the instructions specified by the developer of your mobile device browser.</p>
      <br>
      <h3 id="conduct">5. Cookie acceptance</h3>
      <p>This Website doesn't install our cookies on users' devices until they have accepted their installation.</p>
      <p>We inform you that in the case of not accepting the installation of cookies or disabling any of these in the configuration, it's possible that certain services are not
        available without the use of these or that you can't access certain services or take full advantage of everything what this Website offers you.
      </p>
      </v-col>
    </v-row>
  </section>
</template>
<script>

import { mapState } from 'vuex'
import project from '@/project'

export default {
  name: 'CoockiesView',
  computed: {
    ...mapState('application', ['isMobile', 'darkTheme']),
    isDark () {
      return {
        dark: project.project === 'scatface'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dark {
  color: white !important;
}
</style>
