<template>
<ThumbListSkeleton :loading="firstLoading">
  <section class="mb-4">
    <v-row>
      <v-col v-for="post in posts" style="min-width: 170px"
             :key="`fun-post-${post.id}`" xs="12" md="3" sm="4">
        <VideoThumb :video="post" />
      </v-col>
      <v-col cols="12" v-if="loading">
        <LoadingSpinner :value="loading" />
      </v-col>
      <h1 v-else-if="posts.length === 0"
        class="title-text px-5" style="text-align: center; margin: auto">
        {{ $t('listview.no_content') }}
      </h1>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn elevation="0" @click="loadMore">
          {{ $t('actions.see_more') }}
        </v-btn>
      </v-col>
    </v-row>
  </section>
</ThumbListSkeleton>
</template>

<script>
import PostApi from '@/api/PostApi'
import VideoThumb from '@/components/videos/VideoThumb'
import AffiliatesApi from '@/api/AffiliatesApi'

export default {
  name: 'FunVideos',
  components: { VideoThumb },
  props: {
    category: Array,
    username: String
  },
  data () {
    return {
      firstLoading: true,
      loading: false,
      limit: 40,
      nextToken: '',
      posts: []
    }
  },
  watch: {
    category () {
      this.nextToken = ''
      this.posts = []
      this.loadMore()
    }
  },
  computed: {
    query () {
      const q = {
        limit: this.limit,
        nextCursorToken: this.nextToken,
        visibility: 'open',
        type: 'video',
        postCategoryKeys: this.category
      }
      if (this.username) {
        q.publisher = this.username
      }
      return q
    }
  },
  methods: {
    async loadMore () {
      this.loading = true
      const action = this.username ? PostApi.list : AffiliatesApi.getAffiliatePost
      const { data, error } = await action(this.query)
      if (!error) {
        if (data.data.length) {
          this.posts.splice(this.posts.length, 0, ...data.data)
        }
        this.nextToken = data.metadata.nextCursorToken
        if (this.posts.length < 1 && this.category.length) {
          this.$router.replace('/')
        }
      }
      this.firstLoading = false
      this.loading = false
    }
  },
  mounted () {
    this.loadMore()
  }
}
</script>
