import api from '@/api/Api'
import store, { AppActions, ProfileActions, PostActions } from '@/store'

const ws = api.websocket()

const updateNotifications = (event) => {
  store.dispatch(AppActions.UpdateHasNotifications)
}

const updateChat = () => {
  store.dispatch(AppActions.UpdateHasMessages, true)
}

const updateProfile = (event) => {
  store.dispatch(ProfileActions.Set, event)
}

const updateWallet = (event) => {
  store.dispatch(ProfileActions.UpdateWallet, event)
}

const updatePost = (event) => {
  store.dispatch(PostActions.Fetch, { id: event.id, force: true })
}

const WebsocketPlugin = {
  install (Vue, options) {
    Vue.prototype.$ws = ws
    ws.subscribe('notification', updateNotifications)
    ws.subscribe('new-message', updateChat)
    ws.subscribe('update-profile', updateProfile)
    ws.subscribe('update-wallet', updateWallet)
    ws.subscribe('post-updated', updatePost)
  }
}
export const websocket = ws
export default WebsocketPlugin
