import Vue from 'vue'
import Vuex from 'vuex'
import preferences, { Actions as PrefActions } from './preferences'
import application, { Actions as AActions } from './application'
import session, { Actions as SActions } from './session'
import profile, { Actions as PActions } from './profile'
import users, { Actions as UActions } from './modules/users'
import posts, { Actions as PSActions } from './modules/posts'
import checkout, { Actions as CheckActions } from './modules/checkout'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    [preferences.namespaceName]: preferences,
    [application.namespaceName]: application,
    [session.namespaceName]: session,
    [profile.namespaceName]: profile,
    [users.namespaceName]: users,
    [posts.namespaceName]: posts,
    [checkout.namespaceName]: checkout
  },
  actions: {
    reset ({ commit }) {
      commit(`${preferences.namespaceName}/reset`)
      commit(`${session.namespaceName}/reset`)
      commit(`${application.namespaceName}/reset`)
      commit(`${profile.namespaceName}/reset`)
      commit(`${users.namespaceName}/reset`)
      commit(`${posts.namespaceName}/reset`)
      commit(`${checkout.namespaceName}/reset`)
    }
  },
  strict: debug
})

function mapActions (namespace, actions) {
  const mappedActions = {}

  Object.entries(actions).forEach(([key, value]) => {
    mappedActions[key] = `${namespace}/${value}`
  })

  return mappedActions
}
/*
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('service-worker.js').then(function(registration) {
    console.log('ServiceWorker registration successful with scope:',  registration.scope);
  }).catch(function(error) {
    console.log('ServiceWorker registration failed:', error);
  });
}
*/
const actions = {
  PreferenceActions: mapActions(preferences.namespaceName, PrefActions),
  AppActions: mapActions(application.namespaceName, AActions),
  SessionActions: mapActions(session.namespaceName, SActions),
  ProfileActions: mapActions(profile.namespaceName, PActions),
  UserActions: mapActions(users.namespaceName, UActions),
  PostActions: mapActions(posts.namespaceName, PSActions),
  CheckoutActions: mapActions(checkout.namespaceName, CheckActions)
}

export const PreferenceActions = actions.PreferenceActions
export const AppActions = actions.AppActions
export const SessionActions = actions.SessionActions
export const ProfileActions = actions.ProfileActions
export const UserActions = actions.UserActions
export const PostActions = actions.PostActions
export const ProductActions = actions.ProductActions
export const ChatActions = actions.ChatActions
export const WithdrawActions = actions.WithdrawActions
export const CheckoutActions = actions.CheckoutActions
export default store
