<template>
  <section>
    <v-row>
      <v-col cols="12" v-if="isMobile">
        <v-btn
          block depressed
          color="#41B849"
          class="white-text"
          @click="goTo()">
          {{ getText }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="title-video" :style="isDark">
        {{ post.title }}
      </v-col>
      <v-col cols="12">
        <div class="tab-wrapper">
          <v-row>
            <v-col cols="auto" class="tagUser">
              <span style="margin: auto">
                <router-link :to="{name: 'user', params: {username: post.username}}" class="text" style="font-family: 'Oswald', sans-serif">
                  {{post.username}}
                </router-link>
              </span>
            </v-col>
            <v-col class="tagCategory" cols="auto" v-for="cat in post.categories" :key="cat.name">
              <router-link :to="{name: 'category_home', params: { category: cat.name } }" class="text" style="font-family: 'Oswald', sans-serif">
                {{ getLanguage(cat) }}
              </router-link>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" class="relative">
        <v-row>
          <v-col :style="cssVideo">
            <div class="video-wrapper">
              <VideoPlayer
                          ref="video"
                          :key="post.id"
                          contain
                          max-height="505"
                          controls
                          controlslist="nodownload"
                          class="page-resource"
                          :sources="videoSrc" />
            </div>
          </v-col>

          <v-col v-if="!isMobile " style="text-align: end; margin-left: auto;max-width: 325px">
            <div class="thumb-link">
              <BannerAffiliated :width="300" :height="250" />
            </div>
            <div class="thumb-link" style="margin-top: 10px;">
              <BannerAffiliated isImage :width="300" :height="250" />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="!isMobile" style="margin-top: 25px;">
        <v-btn
          block depressed
          color="#41B849"
          class="white-text"
          style="font-family: Arial"
          target="_blank"
          :href="starLink">
          BE MY FAN AND SEE ALL MY VIDEOS ➤
        </v-btn>
      </v-col>
    </v-row>
    <VideoActions :post="post" />
    <v-row>
      <v-col cols="12" v-if="isMobile">
        <v-row justify="center" :style="cssStyle" @click="goTo()">
          <v-col cols="2" style="max-height: 112px;" class="user-image">
            <img style="border-radius: 100%; height: auto; width: 100%; max-width: 100px;max-height: 100px;" :src="post.author.avatarS3Route">
          </v-col>
          <v-col cols="8" class="bannerText" v-resize-text="getResizeProps">
            {{ post.username }} is on <span style="font-family: 'Oswald', sans-serif; text-transform: Capitalize; font-weight: 700;">{{ project.linkProject }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import VideoPlayer from '@/components/custom/VideoPlayer'
import project from '@/project'
import { mapState } from 'vuex'
import ResizeText from 'vue-resize-text'
import VideoActions from './VideoActions.vue'
import BannerAffiliated from '@/views/BannerAffiliated'

export default {
  name: 'VideoPage',
  components: { BannerAffiliated, VideoPlayer, VideoActions },
  props: {
    post: Object
  },
  directives: {
    ResizeText
  },
  data () {
    return {
      videoMask: null,
      imgMask: null,
      affiliateUser: null,
      affiliatePost: null,
      categ: [],
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'affiliate', 'affiliateSource']),
    cssVideo () {
      return {
        width: this.isMobile ? '100%' : 'calc(100% - 320px)',
        position: 'relative'
      }
    },
    preview () {
      return (this.post && this.post.preview) || {}
    },
    video () {
      return (this.post && this.post.resources[0]) || {}
    },
    isVisible () {
      return this.post.visible
    },
    videoSrc () {
      return [this.isVisible ? this.video.url : this.preview.url]
    },
    decodecContent () {
      try {
        return decodeURI(this.post.content)
      } catch (ex) {
        return this.post.content
      }
    },
    cssStyle () {
      return {
        maxHeight: '112px',
        backgroundColor: '#ECECEC',
        backgroundSize: 'cover',
        color: 'white'
      }
    },
    isDark () {
      return {
        color: project.theme === 'dark' ? 'white' : 'black'
      }
    },
    getResizeProps () {
      return {
        ratio: 0.7,
        minFontSize: this.isMobile ? '14px' : '20px',
        maxFontSize: this.isMobile ? '24px' : '30px',
        delay: 60
      }
    },
    getText () {
      return this.isMobile ? 'BE MY FAN ➤' : 'BE MY FAN AND SEE ALL MY VIDEOS ➤'
    },
    starLink () {
      let link = `${project.linkUrl}${this.post.author.username}`
      if (this.affiliate) link = `${link}?af=${this.affiliate}`
      if (this.affiliateSource) link = `${link}&utm_source=${this.affiliateSource}`
      return link
    }
  },
  methods: {
    sendCategory (cat) {
      if (this.categ.find(x => x === cat)) {
        this.categ.splice(this.categ.indexOf(cat), 1)
      } else {
        this.categ.push(cat)
      }
      this.$emit('setCat', this.categ)
    },
    getLanguage (cat) {
      return this.$i18n.locale === 'es' ? cat.es : cat.en
    }
  }
}
</script>

<style lang="scss" scoped>

  .title-video {
    font-family: 'Oswald', sans-serif !important;
    font-size: 32px !important;
  }

  .relative {
    position: relative;
  }
  .video-label {
    /* font-family: 'Roboto Condensed', sans-serif; */
  }
  .page-resource {
    max-height: 667px;
  }

  .link-video {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 12px;
    font-weight: bold;
    font-family: 'Montserrat';
    text-align: right;
    div {
      background-color: black;
      color: white;
      width: 100%;
      line-height: 25px;
      padding: 10px;
    }
  }

  .bannerText {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    color: black;
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
  }

  .tagUser {
    background-color: black;
    color: white;
    border-left: var(--v-primary-base);
    border-left-width: 5px;
    border-left-style: groove;
    .text {
      /* font-family: 'Oswald', sans-serif;*/
      font-size: 16px;
      color: white !important;
    }
  }

  .tagCategory {
    background-color: #DADADA;
    color: black;
    margin-left: 10px;
    margin-right: 10px;
    .text {
      font-family: 'Oswald', sans-serif;
      font-size: 16px;
      color: black !important;
    }
  }

  .thumb-link {
    width: 300px;
    height: 250px;
    background-position: center;
    background-size: cover;
  }
  .thumb-link:last-child {
    margin-bottom: 0;
  }
  .tab-wrapper {
    padding: 0 12px;
  }

  .video-wrapper {
    background-color: black;
  }

  .user-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
