<template>
  <section :style="{backgroundColor: project.backgroundColor, height: '100%'}" v-if="!loading">

    <div class="user-cover">
      <c-img aspect-ratio="4.6" height="200"
             gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) 99%"
             :src="user.bannerS3Route || '/images/foncabecera.jpg'">
      </c-img>
      <CoverPortrait class="user-profile" :user="user"/>
    </div>

    <v-row justify="center">
      <v-col cols="12">
        <v-btn
          block depressed
          color="#41B849"
          class="white-text"
          style="font-size: 16px;font-family: Arial" v-html="$t('actions.be_fan')"
          target="_blank"
          :href="starLink">
        </v-btn>
      </v-col>
      <v-col cols="12" md="12" :style="{backgroundColor: project.backgroundColor}" style="max-width: 98%;">
        <VideosSection :username="user.username" :category="category" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import project from '@/project'
import CoverPortrait from '@/users/covers/CoverPortrait'
import VideosSection from '@/components/home/VideosSection'
import NavBarMenu from '@/components/layouts/NavBarMenu'
import { mapState } from 'vuex'
import { UserActions } from '@/store'

export default {
  name: 'ProfileView',
  components: { CoverPortrait, VideosSection, NavBarMenu },
  props: {
    username: String
  },
  metaInfo () {
    return {
      title: this.$t(`meta.title.${project.project}.user`, { username: this.username }),
      meta: [
        { name: 'description', content: this.$t(`meta.description.${project.project}.user`, { username: this.username }) },
        { property: 'og:title', content: this.$t(`meta.title.${project.project}.user`, { username: this.username }) },
        { property: 'og:site_name', content: this.$t(`meta.title.${project.project}.user`, { username: this.username }) },
        { property: 'og:description', content: this.$t(`meta.description.${project.project}.user`, { username: this.username }) },
        { property: 'og:type', content: 'profile' },
        { property: 'og:url', content: project.domains.baseUrl + '/' + this.username }
      ]
    }
  },
  data () {
    return {
      user: {},
      loading: true,
      category: [],
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'affiliate', 'affiliateSource']),
    starLink () {
      let link = `${project.linkUrl}${this.username}`
      if (this.affiliate) link = `${link}?af=${this.affiliate}`
      if (this.affiliateSource) link = `${link}&utm_source=${this.affiliateSource}`
      return link
    }
  },
  methods: {
    setCategory (cat) {
      this.category = cat
    }
  },
  async mounted () {
    this.loading = true
    this.user = await this.$store.dispatch(UserActions.Fetch, { username: this.username })
    if (this.user.errorCode) window.location = '/'
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>

.user-cover {
  color: white !important;
  position: relative;
  .user-profile {
    position: absolute;
    flex-wrap: inherit;
    top: 0;
    left: 20px;
    right: 0;
    bottom: 0;
  }
  .profile-actions {
    position: absolute;
    text-align: right;
    top: 22px;
    right: 22px;
  }
}
</style>
