import cookies from '@/cookies'
import config from '@/project'

const actionsTypes = {
  SetTags: 'set_tags',
  UpdateCookies: 'update_cookies',
  UpdateDarkTheme: 'update_dark_theme',
  UpdateRecommended: 'update_recommended',
  UpdateTagPrefs: 'update_tag_prefs',
  UpdateAge: 'update_age_prefs',
  UpdateGender: 'update_gender_prefs',
  UpdateCategory: 'update_category_prefs'
}

const tagPrefs = cookies.get('tag-prefs-selected')
const genderCookies = cookies.get('gender-prefs') || ''
const categoriesCookies = cookies.get('categories-prefs') || ''
const ageCookies = cookies.get('age-prefs') || ''

const state = () => ({
  darkTheme: cookies.get('darkTheme') ? cookies.get('darkTheme') === 'true' : config.theme === 'dark',
  showCookies: !cookies.get('allow-cookies'),
  recommended: cookies.get('timeline-recommended') !== 'false',
  tagPrefs: tagPrefs || genderCookies || categoriesCookies || ageCookies,
  // avoiding empty values
  genderTags: genderCookies.split(',').filter((t) => !!t.trim()),
  categoryTags: categoriesCookies.split(',').filter((t) => !!t.trim()),
  ageTags: ageCookies.split(',').filter((t) => !!t.trim())
})

const mutations = {
  reset: (s) => {
    s = state()
  },

  set_tags (state, tags) {
    const gender = state.genderTags.map(name => tags.find(t => t.name === name))
    const categories = state.categoryTags.map(name => tags.find(t => t.name === name))
    const age = state.ageTags.map(name => tags.find(t => t.name === name))
    this.commit('preferences/set_gender_tags', gender)
    this.commit('preferences/set_category_tags', categories)
    this.commit('preferences/set_age_tags', age)
  },

  set_cookies (state, show) {
    state.showCookies = !show
  },

  set_dark_theme (state, theme) {
    state.darkTheme = theme
    cookies.set('darkTheme', theme)
  },

  set_recommended: (state, recommended) => {
    state.recommended = recommended
    cookies.set('timeline-recommended', recommended)
  },

  set_tag_prefs: (state, selected) => {
    state.tagPrefs = selected
    cookies.set('tag-prefs-selected', selected)
  },

  set_gender_tags: (state, tags) => {
    state.genderTags = tags
    cookies.set('gender-prefs', tags.map(t => t.name).join(','))
  },

  set_category_tags: (state, tags) => {
    state.categoryTags = tags
    cookies.set('categories-prefs', tags.map(t => t.name).join(','))
  },

  set_age_tags: (state, tags) => {
    state.ageTags = tags
    cookies.set('age-prefs', tags.map(t => t.name).join(','))
  }
}

const getters = {
  selected_tags_ids: (state) => {
    return {
      ageIds: state.ageTags.map(t => t.id),
      categoryIds: state.categoryTags.map(t => t.id),
      genderIds: state.genderTags.map(t => t.id)
    }
  }
}

const actions = {
  [actionsTypes.SetTags]: (context, tags) => {
    context.commit('set_tags', tags)
  },
  [actionsTypes.UpdateCookies]: (context, show) => {
    context.commit('set_cookies', show)
  },

  [actionsTypes.UpdateDarkTheme]: (context, theme) => {
    context.commit('set_dark_theme', theme)
  },

  [actionsTypes.UpdateRecommended]: (context, recommended) => {
    context.commit('set_recommended', recommended)
  },

  [actionsTypes.UpdateTagPrefs]: (context, pref) => {
    context.commit('set_tag_prefs', pref)
  },

  [actionsTypes.UpdateGender]: (context, tags) => {
    if (tags.length) {
      context.commit('set_tag_prefs', true)
    }
    context.commit('set_gender_tags', tags)
  },

  [actionsTypes.UpdateCategory]: (context, tags) => {
    if (tags.length) {
      context.commit('set_tag_prefs', true)
    }
    context.commit('set_category_tags', tags)
  },

  [actionsTypes.UpdateAge]: (context, tags) => {
    if (tags.length) {
      context.commit('set_tag_prefs', true)
    }
    context.commit('set_age_tags', tags)
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'preferences',
  namespaced: true,
  state: state(),
  getters,
  mutations,
  actions
}
