<template>
  <section :style="{maxWidth: isMobile ? '98%' : '60%', margin: 'auto'}" :class="{dark: isDark}">

    <div class="col-1" style="margin-bottom: 20px; margin-left: auto; margin-right: auto">
      <c-btn
        to="/">
        {{ $t('actions.back') }}
      </c-btn>
    </div>
    <h1 class="h1--title" style="font-weight: bold" v-html="$t('privacy.tittle')"></h1>

    <br>
    <h3 id="access" style="font-weight: bold" v-html="$t('privacy.section_1.tittle')"></h3>
    <p v-html="$t('privacy.section_1.desc_1', {color: isDark ? 'white' : 'black'})"></p>
    <p v-html="$t('privacy.section_1.desc_2', {color: isDark ? 'white' : 'black'})"></p>
    <p v-html="$t('privacy.section_1.desc_3')"></p>

    <br>
    <h3 id="object" style="font-weight: bold" v-html="$t('privacy.section_2.tittle')"></h3>
    <p v-html="$t('privacy.section_2.desc_1')"></p>
    <p v-html="$t('privacy.section_2.desc_2')"></p>
    <p v-html="$t('privacy.section_2.desc_3')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_3.tittle')"></h3>
    <p v-html="$t('privacy.section_3.desc_1')"></p>
    <ol>
      <li v-html="$t('privacy.section_3.desc_2')"></li>
      <li v-html="$t('privacy.section_3.desc_3')"></li>
    </ol>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_4.tittle')"></h3>
    <p v-html="$t('privacy.section_4.desc_1')"></p>
    <p v-html="$t('privacy.section_4.desc_2')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_5.tittle')"></h3>
    <p v-html="$t('privacy.section_5.desc_1')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_6.tittle')"></h3>
    <p v-html="$t('privacy.section_6.desc_1')"></p>
    <ol>
      <li v-html="$t('privacy.section_6.desc_2')"></li>
      <li v-html="$t('privacy.section_6.desc_3')"></li>
      <li v-html="$t('privacy.section_6.desc_4')"></li>
      <li v-html="$t('privacy.section_6.desc_5')"></li>
      <li v-html="$t('privacy.section_6.desc_6')"></li>
      <li v-html="$t('privacy.section_6.desc_7')"></li>
      <li v-html="$t('privacy.section_6.desc_8')"></li>
      <li v-html="$t('privacy.section_6.desc_9')"></li>
    </ol>
    <p v-html="$t('privacy.section_6.desc_10')"></p>
    <p v-html="$t('privacy.section_6.desc_11')"></p>
    <p v-html="$t('privacy.section_6.desc_12')"></p>
    <p v-html="$t('privacy.section_6.desc_13')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_7.tittle')"></h3>
    <p v-html="$t('privacy.section_7.desc_1')"></p>
    <p v-html="$t('privacy.section_7.desc_2')"></p>
    <p v-html="$t('privacy.section_7.desc_3')"></p>
    <p v-html="$t('privacy.section_7.desc_4')"></p>
    <p v-html="$t('privacy.section_7.desc_5')"></p>
    <p v-html="$t('privacy.section_7.desc_6')"></p>
    <p v-html="$t('privacy.section_7.desc_7')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_8.tittle')"></h3>
    <p v-html="$t('privacy.section_8.desc_1')"></p>
    <p v-html="$t('privacy.section_8.desc_2')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_9.tittle')"></h3>
    <p v-html="$t('privacy.section_9.desc_1')"></p>
    <p v-html="$t('privacy.section_9.desc_2')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_10.tittle')"></h3>
    <p v-html="$t('privacy.section_10.desc_1')"></p>

    <br>
    <h3 style="font-weight: bold" v-html="$t('privacy.section_11.tittle')"></h3>
    <p v-html="$t('privacy.section_11.desc_1')"></p>

    <br>

    <div class="col-2" style="margin-bottom: 20px; margin-left: auto; margin-right: auto">
      <c-btn
        to="/">
        {{ $t('actions.back') }}
      </c-btn>
    </div>
  </section>
</template>
<script>

import project from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'PrivacyView',
  data () {
    return {
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    isDark () {
      return project.project === 'scatface'
    }
  },
  metaInfo () {
    return {
      title: this.$t('meta.privacy.title')
    }
  }
}
</script>
<style lang="scss" scoped>
.dark {
  color: white !important;
}
</style>
