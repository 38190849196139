import project from '@/project'

export default (to) => {
  const query = to.fullPath.split('?')[1] || ''

  if (project.project === 'scatbook') {
    if (to.path === '/welcome') {
      window.location.assign(window.location.origin + `/scatbook/welcome.html?${query}`)
    }
  }
}
