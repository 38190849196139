import api from '@/api/Api'

export default {

  getRandomUser () {
    return api.get('/affiliates/users/random')
  },

  getRandomPost () {
    return api.get('/affiliates/posts/random')
  },

  getAffiliatePost (query = {}, options = {}) {
    return api.get('/affiliates/posts', query, options)
  },

  getAffiliateUsers (query = {}, options = {}) {
    return api.get('/affiliates/users', query, options)
  }

}
