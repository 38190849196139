<template>
  <a target="_blank" :href="resourceLink">
    <span v-if="!isImage && affiliatePost">
      <video id="video" muted loop autoplay="autoplay" controls="false" style="display: none">
      <source v-for="(source, index) in sourcesWithMime"
              :key="`source-${index}`"
              :src="source.src"
              :type="source.type">
      </video>
      <span class="canvas">
        <canvas id="canvas" style="width: 300px; height: 250px;"></canvas>
      </span>
    </span>
    <croppa
      v-else-if="isImage && affiliateUser"
      ref="croppa"
      class="crop"
      prevent-white-space
      disable-drag-and-drop
      disable-click-to-choose
      disable-drag-to-move
      disable-scroll-to-zoom
      disable-rotation
      :width="width"
      :height="height"
      :show-remove-button="false"
      v-model="myCroppa"
      :initial-image="affiliateUser.avatarS3Route"
      @draw="handleCroppaInit">
    </croppa>
  </a>
</template>

<script>
import { mapState } from 'vuex'
import project from '@/project'
import Utils from '@/utils'
import AffiliatesApi from '@/api/AffiliatesApi'

const masks = ['Mask_0001', 'Mask_0002', 'Mask_0003', 'Mask_0004', 'Mask_0005', 'Mask_0006']
const Images = {}

masks.forEach(mask => {
  const image = new Image()
  const name = `/masks/${project.project}/${mask}.png`
  image.src = name
  Images[name] = image
})

const Mime = {
  mp4: 'video/mp4',
  m3u8: 'application/x-mpegURL',
  extensionFromUrl: (url) => {
    const [path] = url.split('?')
    return Utils.extractExtension(path)
  },
  fromUrl: (url) => {
    return Mime[Mime.extensionFromUrl(url)]
  }
}

export default {
  name: 'BannerAffiliated',
  props: {
    isImage: Boolean,
    videoSrc: Array,
    width: Number,
    height: Number
  },
  data () {
    return {
      myCroppa: null,
      videoMask: null,
      affiliatePost: {},
      affiliateUser: null,
      imgMask: null,
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'affiliate', 'affiliateSource']),
    resource () {
      return this.affiliateUser || this.affiliatePost || {}
    },
    croppa () {
      return this.isImage ? this.$refs.croppa : this.$refs['vid-croppa']
    },
    preview () {
      return (this.affiliatePost && this.affiliatePost.preview) || {}
    },
    sourcesWithMime () {
      return [this.preview.url].filter(s => !!s).map(s => ({
        src: s,
        type: Mime.fromUrl(s)
      }))
    },
    resourceLink() {
      let link = `${project.linkUrl}${this.resource.username}`
      if (this.affiliate) link = `${link}?af=${this.affiliate}`
      if (this.affiliateSource) link = `${link}&utm_source=${this.affiliateSource}`
      return link
    }
  },
  methods: {
    handleCroppaInit () {
      if (this.isImage) {
        this.affiliateImage()
      }
    },
    affiliatePublication () {
      var canvas = document.getElementById('canvas')
      var ctx = canvas.getContext('2d')
      var video = document.getElementById('video')

      var videoMask = `/masks/${project.project}/Mask_000${Math.floor(Math.random() * (3 - 1 + 1) + 1)}.png`

      video.addEventListener('loadedmetadata', function () {
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
      })

      video.addEventListener('play', function () {
        var self = this;
        (function loop () {
          if (!self.paused && !self.ended) {
            ctx.drawImage(self, 0, 0)
            setTimeout(loop, 1000 / 30) // drawing at 30fps

            const mask = Images[videoMask]

            ctx.drawImage(mask, 0, 0, canvas.width + 5, canvas.height)
          }
        })()
      }, 0)
    },
    affiliateImage () {
      this.imgMask = `/masks/${project.project}/Mask_000${Math.floor(Math.random() * (6 - 4 + 1) + 4)}.png`
      const ctx = this.croppa.getContext()
      const canvas = ctx.canvas
      const mask = Images[this.imgMask]

      ctx.drawImage(mask, 0, 0, canvas.width, canvas.height + 15)
      this.myCroppa.getMetadata()
    },
    async getRandomContent () {
      if (this.isImage) {
        const { data, error } = await AffiliatesApi.getRandomUser()
        if (!error) {
          this.affiliateUser = data
        }
      } else {
        const { data, error } = await AffiliatesApi.getRandomPost()
        if (!error) {
          this.affiliatePost = data
        }
      }
    }
  },
  activated () {
    if (!this.isImage) {
      this.$refs.video.restart()
      this.$refs.video.play()
    }
  },
  mounted () {
    this.getRandomContent()
    if (!this.isImage) {
      this.affiliatePublication()
      var video = document.getElementById('video')
      video.play()
    }
  }
}
</script>

<style lang="scss" scoped>

.crop :hover{
  cursor: pointer
}
.canvas :hover{
  cursor: pointer
}

</style>
