<template>
  <v-app-bar app flat class="app-bar small-text" :style="{backgroundColor: project.header.backgroundColor }">

    <router-link class="mr-4"
                 :to="{ name: 'home' }">
      <img src="/assets/logo.svg" alt="logo"
           :width="brandWidth"/>
    </router-link>
    <v-spacer />
    <v-col cols="auto" class="box-slogan full center centrado fuente-destacada" :style="textStyle" v-if="!isMobile">
      {{ $t(`meta.home.${project.project}`) }}
    </v-col>
    <v-spacer />
    <UserAvatar v-if="authenticated" small :user="currentUser">
      <div slot="name" class="title-text-3 bold greyish-text">{{ currentUser.displayName }}</div>
      <div slot="username" class="regular-text light-text">{{ `@${currentUser.username}` }}</div>
    </UserAvatar>
    <v-btn depressed small
           color="#41B849"
           class="white-text mr-1"
           v-if="!authenticated"
           @click="setLogin(false)">
      <span style="font-family: Arial, sans-serif; font-size: 13px; text-transform: Capitalize;">Sign Up</span>
    </v-btn>
    <v-btn v-if="!authenticated"
           text
           class="ml-1"
           @click="setLogin(true)">
      <span :style="{'color': isDarkTheme() ? 'white' : 'black'}"><strong style="font-family: Arial, sans-serif; font-size: 13px;text-transform: Capitalize;">Log In</strong></span>
    </v-btn>
    <template v-else>
      <span class="title mr-5">{{ currentUser.username }}</span>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            depressed icon
            class="white-text"
            v-on="on"
            @click="doLogout">
            <v-icon color="primary">logout</v-icon>
          </v-btn>
        </template>
        <span><strong style="text-transform: Capitalize;">LogOut</strong></span>
      </v-tooltip>
    </template>
    <c-dialog persistent
              width="800"
              :dark="project.theme === 'dark'"
              v-model="dialog">
      <SessionForm :login="login" @close="emitClose" @switchForm="login = !login" class="mt-2" />
    </c-dialog>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import { SessionActions } from '@/store'
import project from '@/project'
import SessionForm from '../sessions/SessionForm'
import dialog from '@/components/mixins/dialog'
import UserAvatar from '@/components/users/UserAvatar'

export default {
  name: 'Header',
  mixins: [dialog],
  components: { SessionForm, UserAvatar },
  data () {
    return {
      project,
      login: false,
      dialog: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('session', ['authenticated']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['currentUser']),
    brandWidth () {
      return this.isMobile ? 160 : undefined
    },
    textStyle () {
      return {
        color: this.isDarkTheme() ? '#FFFFFF !important' : '#575757 !important',
        fontFamily: 'Oswald, Sans-serif'
      }
    },
    getText () {
      return this.isMobile ? 'mini_' : ''
    }
  },
  methods: {
    isDarkTheme () {
      return this.darkTheme || project.theme === 'dark'
    },
    doLogout () {
      this.$store.dispatch(SessionActions.Logout)
    },
    setLogin (login) {
      this.login = login
      this.dialog = true
    },
    emitClose () {
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>

.box-slogan {
  font-weight: 300;
  font-size: 1.3em;
}

.fuente-destacada {
  font-family: 'Oswald', sans-serif;
}

.centrado {
  margin: 0 auto;
  display: block;
  text-align: center;
  float: none;
}

.v-toolbar__extension {
  background-color: #5d6c7b !important;
}

.test {
  font-family: Arial, sans-serif !important;
}

</style>
