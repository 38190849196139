<template>
  <section :style="{maxWidth: isMobile ? '98%' : '60%', margin: 'auto'}" :class="isDark">
    <div class="col-1" style="margin: auto">
      <c-btn to="/">
        {{ $t('actions.back') }}
      </c-btn>
    </div>
    <TermsConditions class="conditions" type="user" />
    <div class="col-1" style="margin: auto">
      <c-btn to="/">
        {{ $t('actions.back') }}
      </c-btn>
    </div>
  </section>
</template>
<script>
import TermsConditions from '@/components/terms/TermsConditions'
import project from '@/project'
import { mapState } from 'vuex'
export default {
  name: 'ConditionsView',
  components: { TermsConditions },
  computed: {
    ...mapState('application', ['isMobile']),
    isDark () {
      return {
        dark: project.project === 'scatface'
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('meta.conditions.title'),
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t('meta.conditions.description')
      }]
    }
  }
}
</script>
<style lang="scss" scoped>
.dark {
  color: white !important;
}
</style>
