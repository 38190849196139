export default (formRef) => {
  return {
    data () {
      return {
        validForm: false,
        submittingForm: false
      }
    },
    methods: {
      async submit () {
        if (this.submittingForm) {
          return
        }
        if (!this.validForm) {
          this.$refs[formRef].validate()
          return this.onValidationError()
        }

        this.submittingForm = true
        try {
          await this.onSubmit()
          this.emitSubmitted()
        } catch (ex) {
          console.log(ex)
        }

        this.submittingForm = false
      },
      onValidationError () {

      },
      onSubmit () {

      },
      emitCancel () {
        this.$emit('cancel', true)
      },
      emitSubmitted (data) {
        this.$emit('submitted', data)
      }
    }
  }
}
