<template>
  <v-row v-if="loading">
    <v-col cols="12">
      <v-skeleton-loader type="chip" tile :dark="darkTheme" />
    </v-col>
    <v-col cols="12" md="9" class="video-skeleton">
      <v-skeleton-loader height="525" type="image" :dark="darkTheme" />
    </v-col>
    <v-col v-if="!isMobile" cols="3" class="thumb-skeleton">
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="image" height="250" width="300" :dark="darkTheme" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="image" height="250" width="300" :dark="darkTheme" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <section v-else>
    <slot />
  </section>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'VideoPageSkeleton',
  props: {
    loading: Boolean
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme'])
  }
}

</script>
<style lang="scss" scoped>
  .video-skeleton {
    ::v-deep .v-skeleton-loader__image {
      height: 525px;
    }
  }
  .thumb-skeleton {
    ::v-deep .v-skeleton-loader__image {
      height: 250px;
    }
  }
</style>
