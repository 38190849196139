<template>
<div>
	<div class="center full">
		<div class="separa xs-size"></div>
		<p class="txt-sign">{{ $t('session.login') }}</p>
		<div class="separa xs-size"></div>
	</div>
	<input class="formulario full obligatorio" name="email" type="text" title="Email" placeholder="Email or Username" required=""
    :value="login"
    @input="emitLogin">
	<div class="my-4"></div>
	<input class="formulario full" type="password" name="password" placeholder="Password" required=""
    :value="password"
    @input="emitPassword">
</div>
</template>

<script>
export default {
  name: "Login",
  props: {
    row: Boolean,
    login: String,
    password: String
  },
  methods: {
    emitLogin(event) {
      this.$emit('update:login', event.target.value)
    },
    emitPassword(event) {
      this.$emit('update:password', event.target.value)
    }
  }
}
</script>
