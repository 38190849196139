<template>
  <v-row>
    <v-col :cols="isMobile ? 'auto' : 3" class="user-info col-md-2" style="min-width: 110px;">
      <UserAvatar vertical :x-large="!isMobile" profile :style="userAvatarCss"
                  :user="user"
                  @click.native="enableCarousel">
      </UserAvatar>
    </v-col>
    <v-col :cols="isMobile ? 7 : 9" class="col-md-10" style="margin-bottom: auto; margin-top: auto;padding: 15px;">
      <v-row align="center">
        <div slot="name" class="title-text-2 bold white--text mx-2">{{ user.displayName }}</div>
      </v-row>
      <v-row align="center">
        <div slot="username" class="regular-text white-light-text mx-2">{{ `@${user.username}` }}</div>
      </v-row>
      <v-row align="center">
        <v-col v-if="!isMobile" cols="12" sm="" class="user-description">
          <div class="truncate five-lines">
            {{ getDescription }}
          </div>
        </v-col>
        <v-col v-else cols="12" sm="" class="user-description">
          <div>
            <v-tooltip v-model="show" top>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="truncate two-lines" @click="show = !show">
                  {{ getDescription }}
                </span>
              </template>
              <span>{{ getDescription }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import UserAvatar from '@/components/users/UserAvatar'
import config from '@/project'

export default {
  name: 'CoverPortrait',
  components: { UserAvatar },
  props: {
    user: Object,
    edittingMode: Boolean
  },
  data () {
    return {
      show: false,
      showCarousel: false,
      enabledScatModal: config.enable_scat_modal,
      showBlockDialog: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile']),
    ...mapState('session', ['authenticated']),
    showInfo () {
      if (this.isCurrentUser) {
        return true
      } else if (this.user.showStats) {
        return this.authenticated && this.user.followersCount > 99 && this.user.subscribersCount > 19
      }
      return false
    },
    showBlock () {
      return !this.isCurrentUser && this.isInfluencer
    },
    isCurrentUser () {
      return this.authenticated && this.user.username === this.currentUser.username
    },

    subscribersNumber () {
      const count = this.user.subscribersCount || 0
      return this.$tc('user_profile.subscribers_count', count, { count })
    },

    followersNumber () {
      const count = this.user.followersCount || 0
      return this.$tc('user_profile.followers_count', count, { count })
    },

    postNumber () {
      const count = this.user.postCount || 0
      return this.$tc('user_profile.post_count', count, { count })
    },

    premiumNumber () {
      const count = this.user.premiumCount || 0
      return this.$tc('user_profile.premium_count', count, { count })
    },

    followingsNumber () {
      const count = this.user.followingCount || 0
      return this.$tc('user_profile.followings_count', count, { count })
    },

    resources () {
      return [{ url: this.user.avatarS3Route }, { url: this.user.bannerS3Route }]
    },

    showScatLink () {
      return this.enabledScatModal && this.user.scatProfile && this.user.profileMigrated && (this.isCurrentUser || this.user.following || this.user.subscribed)
    },

    blockUserColor () {
      return this.user.blockListed ? 'primary' : 'white'
    },

    getDescription () {
      try {
        return decodeURI(this.user.description)
      } catch (ex) {
        return this.user.description
      }
    },
    userAvatarCss () {
      return {
        marginLeft: !this.isMobile ? 'auto' : null
      }
    }
  },
  methods: {
    enableCarousel () {
      this.showCarousel = true
    },
    copyLink () {
      this.showCarousel = false
      const copyText = document.createElement('textarea')
      copyText.value = `https://${config.project}.com/${this.user.username}`
      copyText.setAttribute('readonly', '')
      copyText.style.position = 'absolute'
      copyText.style.left = '-9999px'
      document.body.appendChild(copyText)
      copyText.select()
      document.execCommand('copy')
      document.body.removeChild(copyText)
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.clipboard_copied')
      })
    },
    async swithcBlocked () {
      if (this.user.blockListed) {
        await this.$store.dispatch(ProfileActions.UnblockUser, this.user)
      } else {
        this.showBlockDialog = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user-info {
  display: flex;
  align-items: center;
}
.user-description {
  align-self: flex-end;
}
.row .star-info {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.title-text-2 {
  font-size: 40px;
  font-family: 'Oswald', sans-serif;
}

.regular-text {
  font-size: 16px;
  font-family: 'Oswald', sans-serif;
}

.user-description .truncate{
  font-size: 16px;
  font-family: 'Oswald', sans-serif !important;
}

.scatProfile {
  width: 185px;
  height: 21px;
  margin: 0.5px 0 0.5px 9px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

</style>
