<template>
  <Layout />
</template>

<script>
import Layout from './components/layouts/Layout.vue'
import project from '@/project'
import { AppActions } from './store'

function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)

    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default {
  name: 'Tubes',
  components: { Layout },
  metaInfo () {
    return {
      title: this.$t(`meta.title.${project.project}.home`),
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t(`meta.description.${project.project}.home`)
      }]
    }
  },
  methods: {
    onResize () {
      const dim = {
        width: window.innerWidth,
        height: window.innerHeight
      }
      this.$store.dispatch(AppActions.UpdateScreenSize, dim)
    }
  },
  created () {
    this.onResize()
  },
  mounted  () {
    window.addEventListener('resize', debounce(this.onResize, 100))
  },
  beforeDestroy  () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import 'src/scss/theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@font-face {
  font-family: "AllertaStencil";
  src: local("AllertaStencil"),
  url(/fonts/AllertaStencil-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Cabin";
  src: local("Cabin"),
  url(/fonts/CabinCondensed-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "AllertaStencil";
  src: local("AllertaStencil"),
  url(/fonts/AllertaStencil-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "CabinCondensed";
  src: local("CabinCondensed"),
  url(/fonts/CabinCondensed-Regular.ttf) format("truetype");
  font-display: swap;
}


</style>
