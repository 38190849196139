import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import project from '@/project'
import BlockUserIcon from '@/components/icons/BlockUser'
import TwitterIcon from '@/components/icons/Twitter'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: project.primary_color,
        secondary: '#3c3c3c',
        accent: '#1583d8',
        error: '#d0021b',
        info: '#1583d8',
        success: '#35cf95',
        warning: '#f5a623'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      block_user: {
        component: BlockUserIcon
      },
      twitter: {
        component: TwitterIcon
      }
    }
  }
})
