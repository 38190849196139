import PostApi from '@/api/PostApi'

const actionsTypes = {
  Set: 'set',
  Search: 'search',
  Recommended: 'recommended',
  Timeline: 'timeline',
  Fetch: 'fetch',
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Acquire: 'acquire',
  ToggleLike: 'toggle_like',
  ToggleSave: 'toggle_save',
  ToggleShare: 'toggle_share'
}
const state = {
  timeline: [],
  posts: {},
  promises: {}
}

const getters = {
  timeline: (state) => state.timeline.map(postId => state.posts[postId])
}

const mutations = {
  reset: (state) => {
    state.timeline = []
    state.posts = {}
    state.promises = {}
  },

  insert_post (state, post) {
    this.commit('posts/set_post', post)
  },

  remove_post (state, postId) {
    state.promises[postId] = null
    state.posts[postId] = null
    const index = state.timeline.findIndex(p => p.id === postId)
    if (index !== -1) {
      state.timeline.splice(index, 1)
    }
  },

  set_post (state, post) {
    state.promises[post.id] = null
    if (state.posts[post.id]) {
      Object.keys(post).forEach(key => {
        state.posts[post.id][key] = post[key]
      })
    } else {
      state.posts[post.id] = post
    }
  },

  set_post_promise (state, id, promise) {
    state.promises[id] = promise
  }
}

const fetchPost = (context, postId) => {
  const promise = PostApi.find(postId).then(({ data, error }) => {
    if (!error) {
      context.commit('set_post', data)
      return context.state.posts[postId]
    } else {
      context.commit('set_post_promise', postId, null)
    }
    return data
  })
  context.commit('set_post_promise', postId, promise)
  return promise
}

const actions = {
  [actionsTypes.Set]: (context, post) => {
    context.commit('set_post', post)
  },

  [actionsTypes.Timeline]: async (context, query) => {
    const response = await PostApi.timeline(query)
    if (!response.error) {
      response.data.data = response.data.data.map(post => {
        context.commit('set_post', post)
        return context.state.posts[post.id]
      })
    }
    return response
  },

  [actionsTypes.Fetch]: (context, { id, force }) => {
    if (context.state.promises[id]) {
      return context.state.promises[id]
    }
    const post = context.state.posts[id]
    if (!post || force) {
      return fetchPost(context, id)
    }

    return post
  },

  [actionsTypes.Search]: async (context, query) => {
    const response = await PostApi.list(query)
    if (!response.error) {
      response.data.data = response.data.data.map(post => {
        context.commit('set_post', post)
        return context.state.posts[post.id]
      })
    }
    return response
  },

  [actionsTypes.Recommended]: async (context, query) => {
    const response = await PostApi.recommended(query)
    if (!response.error) {
      response.data.data = response.data.data.map(post => {
        context.commit('set_post', post)
        return context.state.posts[post.id]
      })
    }
    return response
  },

  [actionsTypes.Timeline]: async (context, query) => {
    const response = await PostApi.timeline(query)
    if (!response.error) {
      response.data.data = response.data.data.map(post => {
        context.commit('set_post', post)
        return context.state.posts[post.id]
      })
    }
    return response
  },

  [actionsTypes.Create]: (context, post) => {
    return PostApi.create(post)
      .then(({ data, error }) => {
        if (!error) {
          context.commit('insert_post', data)
        }
        return { data: context.state.posts[data.id], error }
      })
  },

  [actionsTypes.Update]: async (context, post) => {
    const response = await PostApi.update(post)
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { id: post.id, force: true })
    }
    return response
  },

  [actionsTypes.Delete]: async (context, postId) => {
    const response = await PostApi.delete(postId)
    if (!response.error) {
      context.commit('remove_post', postId)
    }
    return response
  },

  [actionsTypes.Acquire]: async (context, payload) => {
    const response = await PostApi.buy(payload.id, payload.attrs)
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { id: payload.id, force: payload.force })
    }
    return response
  },

  [actionsTypes.ToggleLike]: async (context, id) => {
    const post = context.state.posts[id]
    if (!post) {
      return
    }
    const action = post.liked ? PostApi.unlike : PostApi.like
    context.commit('set_post', { id, liked: !post.liked })
    return action(id)
  },

  [actionsTypes.ToggleSave]: async (context, id) => {
    const post = context.state.posts[id]
    if (!post) {
      return
    }
    const action = post.saved ? PostApi.unsave : PostApi.save
    context.commit('set_post', { id, saved: !post.saved })
    return action(id)
  },

  [actionsTypes.ToggleShare]: async (context, id) => {
    const post = context.state.posts[id]
    if (!post) {
      return
    }
    const action = post.shared ? PostApi.unshare : PostApi.share
    context.commit('set_post', { id, shared: !post.shared })
    return action(id)
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'posts',
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
