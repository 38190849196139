<template>
    <div class="container-fluid">
      <LoadingSpinner :value="loading" />
      <VideoPageSkeleton :loading="firstLoad">
        <VideoPage :post="post" />
      </VideoPageSkeleton>
      <RelatedVideos :username="post.username" :categories="categories"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PostApi from '@/api/PostApi'
import VideoPage from '@/components/videos/VideoPage'
import RelatedVideos from '@/components/videos/RelatedVideos'
import NavBarMenu from '@/components/layouts/NavBarMenu'
import project from '@/project'

export default {
  name: 'VideoView',
  components: { VideoPage, RelatedVideos, NavBarMenu },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      post: {},
      categories: [],
      resource: null,
      firstLoad: true,
      loading: true,
      project
    }
  },
  metaInfo () {
    return {
      title: this.$t(`meta.title.${project.project}.video`, { title: this.post.title, username: this.post.username }),
      meta: [
        {
          name: 'description',
          content: this.$t(`meta.description.${project.project}.video`, { title: this.post.title, username: this.post.username })
        },
        { property: 'og:title', content: this.$t(`meta.title.${project.project}.video`, { title: this.post.title, username: this.post.username }) },
        { property: 'og:site_name', content: this.$t(`meta.title.${project.project}.home`) },
        { property: 'og:description', content: this.$t(`meta.description.${project.project}.video`, { title: this.post.title, username: this.post.username }) },
        { property: 'og:type', content: 'video' },
        { property: 'og:url', content: project.domains.baseUrl + '/videos/' + this.post.id }
      ]
    }
  },
  computed: {
    ...mapState('session', ['star']),
    ...mapState('application', ['isMobile']),
  },
  methods: {
    async loadPost () {
      const { data } = await PostApi.find(this.id)
      this.post = data
      this.categories = data.categories.map((c) => c.name)
      this.firstLoad = false
      this.loading = false
    }
  },
  beforeMount () {
    this.loadPost()
  }
}
</script>
