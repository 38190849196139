<template>
  <v-text-field
    v-bind="$attrs"
    :label="inputLabel"
    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
    :type="showPassword ? 'text' : 'password'"
    :value="value"
    :rules="allRules"
    @input="emitInput"
    :dark="dark"
    @click:append="showPassword = !showPassword"></v-text-field>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'PasswordInput',
  props: {
    value: [String, Number],
    rules: { type: Array, default: () => [] },
    overrideRules: { type: Array, default: () => null },
    label: String,
    dark: { type: Boolean, default: false }
  },
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    allRules () {
      return this.overrideRules || [...this.rules, this.$vPassword]
    },
    inputLabel () {
      return this.label || this.$t('models.user.password')
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}

</script>
