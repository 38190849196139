<template>
  <section>

    <v-col cols="12" class="box-slogan full center centrado fuente-destacada" :style="textStyle" v-if="isMobile">
      {{ $t(`meta.home.${project.project}`) }}
    </v-col>
    <v-row :style="{backgroundColor: project.navBar.backgroundColor }" style="border-bottom-color: #ffffff6b; border-bottom-style: groove;">
      <v-col class="nav-wrapper" cols="12">
        <v-btn v-if="category"
          color="primary"
          class="cat-btn"
          :to="{ name: 'home' }"
          rel="nofollow">
          <span class="txt">{{ $t('words.all') }}</span>
        </v-btn>
        <v-btn depressed
          v-for="cat in categories"
          :key="cat.name" color="primary"
          class="cat-btn"
          :to="categoryLink(cat.name)"
          rel="nofollow">
          <span class="txt">{{ cat.en }} <span v-if="isOnQuery(cat.name)">-</span> </span>
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import project from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'NavBarMenu',
  props: {
    category: String
  },
  computed: {
    ...mapState('application', ['isMobile', 'categories']),
    textStyle () {
      return {
        color: project.theme === 'dark' ? '#FFFFFF !important' : '#575757 !important',
        textAlign: 'center',
        backgroundColor: project.theme === 'dark' ? '#2D2D2D' : '#FBFBFB',
        fontFamily: 'Oswald, Sans-serif'
      }
    }
  },
  data () {
    return {
      project
    }
  },
  methods: {
    categoryLink (category) {
      return { name: 'category_home', params: { category }}
    },
    isOnQuery (cat) {
      return cat === this.category 
    }
  }
}
</script>
<style lang="scss" scoped>
.sub-header {
  background-color: #5d6c7b;
}

.cat-btn {
  margin-right: 5px;
  height: 21px !important;
  .txt {
    color: white;
    font-size: 16px;
    font-family: Oswald, sans-serif;
    text-transform: Capitalize;
  }
  .txt:hover{
    color: black;
  }
}
.nav-wrapper {
  padding: 15px 0 10px 23px;
  display: flex;
  overflow-x: auto;
}
</style>
