<template>
  <section>
    <NavBarMenu :category="category" />
    <div class="container-fluid">
      <VideosSection :category="categories" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/layouts/NavBarMenu'
import VideosSection from '@/components/home/VideosSection'
import project from '@/project'

export default {
  name: 'HomeView',
  components: { VideosSection, NavBarMenu },
  props: {
    category: { type: String }
  },
  metaInfo () {
    return {
      title: this.$t(`meta.title.${project.project}.home`),
      meta: [
        {
          name: 'description',
          content: this.$t(`meta.description.${project.project}.home`)
        },
        { property: 'og:title', content: this.$t(`meta.title.${project.project}.home`) },
        { property: 'og:site_name', content: project.project },
        { property: 'og:description', content: this.$t(`meta.description.${project.project}.home`) },
        { property: 'og:type', content: 'home' },
        { property: 'og:url', content: project.domains.baseUrl }
      ]
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    categories() {
      return this.category ? [this.category] : undefined
    }
  }
}
</script>
