const ProjectConfig = {
  develop: {
    project: 'onlyvids',
    linkProject: 'Loverfans',
    linkUrl: 'https://loverfans.com/',
    primary_color: '#1AA1FF',
    header: {
      backgroundColor: '#FFFFFF'
    },
    navBar: {
      backgroundColor: '#FFFFFF'
    },
    titleTextColor: '#000000',
    fontColorDest: '#1AA1FF',
    promotion: false,
    enable_resources: true,
    show_footer: true,
    show_imprint: 'https://loverfans.com/loverfans_imprint',
    enable_themes: true,
    enable_scat_modal: true,
    twitter_enabled: true,
    twitter: 'LOVERFANS__',
    multicurrency: true,
    domains: {
      baseUrl: 'https://onlyvids.fans',
      api: 'https://api.develop.loverfans.com/api',
      websocket: 'wss://ws.develop.loverfans.com'
    },
    payments: {
      provider: 'commercegate'
    },
    locales: {
      project: 'Loverfans',
      project_upper: 'LOVERFANS',
      site: 'loverfans.com',
      contact_email: 'soporte@loverfans.com',
      info_email: 'info@loverfans.com',
      payments_imprint: {
        company: 'Inet-Cash GmbH',
        address: 'Am Stadtbad 14',
        city: '59065 Hamm',
        country: 'Germany',
        contact: {
          phone: 'Tel: +49 (2381) 338-9860',
          fax: 'Fax: +49 (2381) 338-9861',
          email: 'Email: support@inet-cash.com'
        }
      }
    },
    analytics: {
      ga: 'UA-153181968-1'
    }
  },
  onlyvids: {
    project: 'onlyvids',
    linkProject: 'Loverfans',
    linkUrl: 'https://loverfans.com/',
    primary_color: '#1AA1FF',
    header: {
      backgroundColor: '#FFFFFF'
    },
    navBar: {
      backgroundColor: '#FFFFFF'
    },
    titleTextColor: '#000000',
    fontColorDest: '#1AA1FF',
    promotion: false,
    enable_resources: true,
    show_footer: true,
    show_imprint: 'https://loverfans.com/loverfans_imprint',
    enable_themes: true,
    enable_scat_modal: true,
    twitter_enabled: true,
    twitter: 'LOVERFANS__',
    multicurrency: false,
    domains: {
      baseUrl: 'https://onlyvids.fans',
      api: 'https://api.loverfans.com/api',
      websocket: 'wss://ws.loverfans.com'
    },
    payments: {
      provider: 'commercegate',
      backup: 'inetcash'
    },
    locales: {
      project: 'Onlyvids',
      project_upper: 'ONLYVIDS',
      site: 'onlyvids.fans',
      contact_email: 'soporte@loverfans.com',
      info_email: 'info@loverfans.com',
      payments_imprint: {
        company: 'Inet-Cash GmbH',
        address: 'Am Stadtbad 14',
        city: '59065 Hamm',
        country: 'Germany',
        contact: {
          phone: 'Tel: +49 (2381) 338-9860',
          fax: 'Fax: +49 (2381) 338-9861',
          email: 'Email: support@inet-cash.com'
        }
      }
    }
  },
  scatface: {
    project: 'scatface',
    linkProject: 'Scatbook',
    linkUrl: 'https://scatbook.com/',
    primary_color: '#B0601E',
    header: {
      backgroundColor: '#2D2D2D'
    },
    navBar: {
      backgroundColor: '#212121'
    },
    titleTextColor: '#FFFFFF',
    fontColorDest: '#B0601E',
    promotion: false,
    enable_resources: true,
    show_footer: false,
    show_imprint: 'https://scatbook.com/scatbook_imprint',
    enable_themes: false,
    twitter_enabled: true,
    theme: 'dark',
    multicurrency: false,
    domains: {
      baseUrl: 'https://scatface.com',
      api: 'https://api.scatbook.com/api',
      websocket: 'wss://ws.scatbook.com'
    },
    payments: {
      provider: 'inetcash'
    },
    locales: {
      project: 'Scatface',
      project_upper: 'SCATFACE',
      site: 'scatface.com',
      contact_email: 'soporte@scatbook.com',
      info_email: 'info@scatbook.com',
      payments_imprint: {
        company: 'Inet-Cash GmbH',
        address: 'Am Stadtbad 14',
        city: '59065 Hamm',
        country: 'Germany',
        contact: {
          phone: 'Tel: +49 (2381) 338-9860',
          fax: 'Fax: +49 (2381) 338-9861',
          email: 'Email: support@inet-cash.com'
        }
      }
    }
  }
}

module.exports = ProjectConfig[process.env.VUE_APP_PROJECT || 'develop']
