<template>
  <div class="thumb-video">
    <router-link :to="{ name: 'video', params: { id: video.id }}">
      <v-img
        :aspect-ratio="1.777778" min-height="100"
        :src="video.preview.cover || video.preview.preview"
        class="download pointer thumb-image">
      </v-img>
    </router-link>
    <div class="thumb-content">
      <div class="thumb-title" :style="isDark">{{ video.title }}</div>
      <div class="thumb-description"><span class="username" :style="fontColor"><router-link :key="video.username" :to="{ name: 'user', params: { username: video.username } }" class="no-decoration username" :style="{color: project.fontColorDest}">{{ video.username }}</router-link><span class="desc">: {{video.viewsCount}} Views</span> | <span class="desc">{{ getVideoLength }} min</span></span></div>
    </div>
  </div>
</template>

<script>
import project from '@/project'
const padTo = (elem, count, char) => ('' + elem).padStart(count, char)

export default {
  name: 'VideoThumb',
  props: {
    video: Object
  },
  data () {
    return {
      project
    }
  },
  computed: {
    decodecContent () {
      try {
        return decodeURI(this.video.content)
      } catch (ex) {
        return this.video.content
      }
    },
    getVideoLength () {
      const length = this.video.metadata.clip ? this.video.metadata.clip.duration : '0'
      const minutes = Math.floor(length / 60)
      const seconds = length % 60
      if (isNaN(minutes) || isNaN(seconds)) return '--:--'
      return `${padTo(minutes, 2, '0')}:${padTo(seconds, 2, '0')}`
    },
    isDark () {
      return {
        color: project.theme === 'dark' ? 'white' : 'black'
      }
    },
    fontColor () {
      return {
        color: project.titleTextColor
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  .thumb-video {
    .thumb-content {
      padding: 5px 0;
    }
    .thumb-title {
      font-family: "Oswald", sans-serif;
      font-size: 16px;
      font-weight: 600;
    }
    .thumb-description {
      font-family: "Oswald", sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #575757;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .username {
        font-size: 14.4px;
        font-family: 'Oswald', sans-serif;

        .desc {
          color: #CACACA;
          font-size: 14.4px;
          font-family: 'Oswald', sans-serif;
        }
      }

    }
  }
</style>
