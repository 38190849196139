<template>
  <div class="user-avatar" :class="userAvatarClass">
    <div class="avatar transparent" style="position: relative;">
      <div class="boxshadow circle" style="height: fit-content">
        <component :is="userLinkComponent" :to="link" class="d-block">
          <v-avatar :size="avatarSize">
            <c-img placeholder="false" :height="avatarSize" :src="user.avatarS3Route"></c-img>
          </v-avatar>
        </component>
      </div>
    </div>
    <template v-if="!mini && !profile">
      <component :is="userLinkComponent"  class="user truncatable" :to="link">
        <div class="name truncate "  :class="{dark: darkTheme, 'light-text': !darkTheme }">
          <slot name="name" :user="user"><span :class="{dark: darkTheme}">{{ user.displayName }}</span></slot>
        </div>
        <div class="username truncate lightest-text">
          <slot name="username" :user="user">{{ `@${user.username}` }}</slot>
        </div>
      </component>
      <slot :user="user" />
    </template>
    <template v-else-if="!mini">
      <slot :user="user" />
    </template>
  </div>
</template>

<script>
import { UserActions } from '@/store'
import { mapState } from 'vuex'

export default {
  name: 'UserAvatar',
  props: {
    user: Object,
    username: String,
    small: Boolean,
    vertical: Boolean,
    center: Boolean,
    large: Boolean,
    xLarge: Boolean,
    linkable: { type: Boolean, default: true },
    profile: Boolean
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    userLinkComponent () {
      return this.linkable ? 'router-link' : 'span'
    },
    link () {
      return this.linkable ? { name: 'user', params: { username: this.user.username } } : ''
    },
    userAvatarClass () {
      return {
        small: this.small,
        'x-small-text': this.small,
        vertical: this.vertical,
        center: this.center,
        large: this.large,
        'x-large': this.xLarge
      }
    },
    avatarSize () {
      return this.small ? 48 : this.xLarge ? 150 : 100
    },
    liveFontClass () {
      return {
        large: this.large, small: this.small, 'x-large': this.xLarge
      }
    }
  },
  methods: {
    async reloadUser () {
      if (this.user) {
        return
      }
      this.localUser = await this.$store.dispatch(UserActions.Fetch, { username: this.username })
    }
  },
  mounted () {
    this.reloadUser()
  }
}
</script>
<style lang="scss">
@use 'src/scss/colors.scss';
@use 'src/scss/styles.scss';

.user-avatar {
  position: relative;
  align-items: center;
  display: flex;
  height: 5px;
  line-height: 22px;
  max-width: 100%;
  text-decoration: none;

  &:visited {
    color: rgba(0, 0, 0, 0.6);
  }

  .circle {
    background-color: white;
  }

  .avatar {
    height: 104px;
    width: 104px;
    margin-right: 3px;
    background-color: white;
  }

  .user {
    flex: 1;
    text-decoration: none;
    min-width: 0;
    padding: 0 8px;
    .name, .username {
      height: 22px;
    }
  }

  &.small {
    height: 38px;
    line-height: 16px;
    .avatar {
      height: 52px;
      width: 52px;
    }
    .user {
      .name, .username {
        height: 16px;
      }
    }
  }

  &.large {
    height: 83px;
    .avatar {
      height: 81px;
      width: 81px;
    }
  }

  &.x-large {
    height: 130px;
    .avatar {
      height: 154px;
      width: 154px;
    }
  }

  &.vertical {
    align-items: center;
    height: auto;
    flex-direction: column;
    .avatar {
      margin: 0 0 5px 0;
      display: flex;
    }
    .user {
      max-width: 100%;
      padding: 0;
      .name {
        @extend .textshadow;
      }
    }
    &.small {
      width: 40px;
    }
    &.large {
      width: 83px;
    }
    &.x-large {
      width: 130px;
    }
  }

  &.center {
    text-align: center;
    .user {
      text-align: center;
      width: 100%;
    }
    .avatar {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.rank {
  min-height: 100%;
  min-width: 100%;
  .auto {
    max-width: 25px;
  }
}

.dark {
  color: white !important;
}
</style>
