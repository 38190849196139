'user strict'

import api from '@/api/Api'

export default {

  profile () {
    return api.get('/profile')
  },

  alerts () {
    return api.get('/alerts')
  },

  update (profile) {
    return api.put('/profile', profile)
  },

  updateAccountData (profile) {
    return api.put('/profile/account_data', profile)
  },

  updatePersonalData (profile) {
    return api.put('/profile/personal_data', profile)
  },

  updateSubscriptionOffers (profile) {
    return api.put('/profile/subscription_offers', profile)
  },

  notifications (params = {}) {
    return api.get('/profile/notifications', params)
  },

  requestPromotion (promotion) {
    return api.put('/profile/promotion', promotion)
  },

  delete () {
    return api.delete('/profile')
  },

  listBlockedCountries () {
    return api.get('/profile/blocked_countries')
  },

  blockCountry (country) {
    return api.post('/profile/blocked_countries', { country })
  },

  unblockCountry (country) {
    return api.delete(`/profile/blocked_countries/${country}`)
  },

  listBlockedUsers () {
    return api.get('/profile/blocked_users')
  },

  blockUser (username) {
    return api.post('/profile/blocked_users', { username })
  },

  unblockUser (username) {
    return api.delete(`/profile/blocked_users/${username}`)
  },

  listTrials (attrs = {}) {
    return api.get('/profile/trials', attrs)
  }
}
