<template>
  <v-overlay :value="value"
    class="carousel"
    opacity="0.9">
    <div class="carousel-wrapper">
      <v-row class="actions"
        align="center" justify="end">
        <v-col cols="auto">
          <c-btn outlined color="white"
            css="mx-5"
            @click="close">
            <v-icon>close</v-icon>
            {{ $t('actions.close') }}
          </c-btn>
        </v-col>
      </v-row>
      <div class="slider">
        <Resource rounded
          contain
          v-bind="$attrs"
          :resource="currentResource"
          :max-height="maxHeight"
          :max-width="1020"
          class="mb-4" />
      </div>
      <c-btn css="previous-resource"
        outlined color="white"
        @click="previous"><v-icon>chevron_left</v-icon></c-btn>
      <c-btn css="next-resource"
        outlined color="white"
        @click="next"><v-icon>chevron_right</v-icon></c-btn>
    </div>
  </v-overlay>
</template>
<script>
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import validatable from '@/components/mixins/validatable'
import Resource from './Resource'

export default {
  name: 'Carousel',
  components: { Resource },
  mixins: [dialog, validatable('form')],
  props: {
    value: Boolean,
    resources: Array,
    selected: Object
  },
  data () {
    return {
      current: 0
    }
  },

  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('application', ['screenHeight']),
    currentResource () {
      return this.resources[this.current]
    },
    total () {
      return this.resources ? this.resources.length : 0
    },
    maxHeight () {
      return this.screenHeight - 130
    }
  },

  watch: {
    selected (value) {
      this.current = this.resources.indexOf(value)
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
    },
    previous () {
      this.current = Math.abs((this.current - 1) % this.total)
    },
    next () {
      this.current = (this.current + 1) % this.total
    }
  },
  async mounted () {
    if (this.selected) {
      this.current = this.resources.indexOf(this.selected)
    }
  }
}

</script>
<style lang="scss">
.carousel {
  .v-overlay__content {
    margin-top: 50px;
    height: 100% !important;
    width: 100% !important;
  }

  .carousel-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    .actions {
      height: 65px;
    }
    .slider {
      height: calc(100% - 65px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      .image {
        max-height: calc(100% - 65px);
        padding: 0 15px;
      }
    }
    .previous-resource {
      position: absolute;
      left: 15px;
      top: 50%;
      bottom: 50%;
    }
    .next-resource {
      position: absolute;
      right: 15px;
      top: 50%;
      bottom: 50%;
    }
  }

  .votos {
    margin-right: 50% !important;
  }
}
</style>
