import store from '@/store'

export default (to) => {
  if (to.fullPath.startsWith('/admin')) {
    const currentUser = store.state.profile.currentUser
    let role = (currentUser && currentUser.role) || 'guest'
    if (role !== 'admin') {
      return { redirect: '/' }
    }
  }
}
