import store, { SessionActions, AppActions, PreferenceActions, CheckoutActions } from '@/store'

export default async (to) => {
  // First we check if session is setup
  if (!store.state.session.isSetup) {
    const { data } = await store.dispatch(AppActions.FetchMasterData)
    await store.dispatch(SessionActions.ResetSession)
    await store.dispatch(CheckoutActions.LoadToken)
    await store.dispatch(PreferenceActions.SetTags, data.tags)
  }

  if (to.query.oa) {
    await store.dispatch(SessionActions.OAuth, to.query.oa)
    delete to.query.oa
  }

  // check if this route can be shown being authenticated or unauthenticated
  const authenticated = store.state.session.authenticated
  const authenticatedRoute = to.matched.some(m => m.meta.authenticated === true)
  const unauthenticatedRoute = to.matched.some(m => m.meta.authenticated === false)
  if (authenticatedRoute && !authenticated) {
    return { redirect: '/home' }
  } else if (unauthenticatedRoute && authenticated) {
    return { redirect: '/home' }
  }
}
