import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store, { SessionActions } from './store'
import api from '@/api/Api'
import FormValidations from './plugins/form_validations'
import EventBus from './plugins/events'
import Websockets from './plugins/websockets'
import vuetify from './plugins/vuetify'
import I18nPlugin from './plugins/i18n'
import Currency from './plugins/currency'
import CustomComponents from './plugins/custom_components'
import project from '@/project'

import Croppa from 'vue-croppa'
import VueGtag from 'vue-gtag'
import VueResource from 'vue-resource'
import VueResizeText from 'vue-resize-text'
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'

import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import VueCountdownTimer from 'vuejs-countdown-timer'

require('./polly.js')

Vue.config.productionTip = false

Vue.use(Croppa)
Vue.use(CustomComponents)
Vue.use(VueResource)
Vue.use(VueScrollTo)
Vue.use(FormValidations)
Vue.use(EventBus)
Vue.use(Websockets)
Vue.use(Currency)
Vue.use(I18nPlugin)
Vue.use(VueMeta)

if (project.analytics && project.analytics.ga) {
  Vue.use(VueGtag, {
    config: { id: project.analytics.ga }
  }, router)
}
Vue.use(VueResizeText)
Vue.use(VueCountdownTimer)

api.intercepResponse(undefined, function (error) {
  const response = error.response
  return new Promise(function (resolve, reject) {
    if (response && response.status === 401 && response.config && !response.config.__isRetryRequest) {
      store.dispatch(SessionActions.Logout)
    }
    reject(error)
  })
})

new Vue({
  router,
  store,
  vuetify,
  i18n: I18nPlugin.i18n,
  render: h => h(App),
  mounted () {
    document.addEventListener('contextmenu', e => {
      e.preventDefault()
    })
  }
}).$mount('#app')
