import store, { AppActions } from '@/store'

export default (to) => {
  if (to.query.af) {
    store.dispatch(AppActions.UpdateAffiliate, {
      affiliate: to.query.af,
      affiliateSource: to.query.utm_source || ''
    })
  }
}
