import i18n from '@/i18n'

const padTo = (elem, count, char) => ('' + elem).padStart(count, char)

const imageTypes = ['webp', 'jpg', 'jpeg', 'png', 'gif']
const videoTypes = ['m3u8', 'mov', 'mpg', 'mpeg', 'mp4', 'wmv', 'avi', 'webm', 'quicktime']

const Utils = {
  extractFilename: (name) => {
    const parts = name.split('/')
    const filename = parts[parts.length - 1]
    const extIndex = filename.lastIndexOf('.')
    if (extIndex === -1) {
      return filename
    }

    return name.substring(0, extIndex)
  },
  extractExtension: (name) => {
    const extIndex = name.lastIndexOf('.')
    if (extIndex === -1) {
      return false
    }

    return name.substring(extIndex).slice(1).toLowerCase()
  },
  isGif (name) {
    return Utils.extractExtension(name) === 'gif'
  },
  isImage (name) {
    return imageTypes.includes(Utils.extractExtension(name))
  },
  isVideo (name) {
    return videoTypes.includes(Utils.extractExtension(name))
  },
  formatNumber: (number, decimals) => Utils.maskNumber(number, decimals || 0, ''),
  maskNumber: (value, decimals, symbol) => {
    if (decimals !== void (0)) {
      var exp = Math.pow(10, decimals)
      value = value.toFixed(Math.round(decimals * exp) / exp)
    } else {
      value = value.toString()
    }
    value = value.replace('.', ',')
    while (true) {
      var valueSep = value.replace(/(\d)(\d{3})($|,|\.)/g, '$1.$2$3')

      if (value === valueSep) {
        break
      }

      value = valueSep
    }

    return value + ' ' + symbol
  },
  formatDate (date, format) {
    if (!date) {
      return ''
    }
    const dateObject = new Date(date)
    const dateFormat = format || i18n.t('types.date.format')
    return dateFormat
      .replace('YYYY', dateObject.getFullYear())
      .replace('MM', padTo(dateObject.getMonth() + 1, 2, '0'))
      .replace('DD', padTo(dateObject.getDate(), 2, '0'))
  },
  formatCurrency (amount, cents = true) {
    const newAmount = cents ? Utils.formatNumber(parseInt(amount, 10) / 100, 2) : amount
    return i18n.t('types.currency', { amount: newAmount })
  }
}

export default Utils
