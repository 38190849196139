<template>
  <v-dialog
    persistent
    :fullscreen="isMobile"
    :max-width="maxWidth"
    v-bind="$attrs"
    :dark="dark"
    v-on:input="$emit('input', $event.target.value)">
    <v-toolbar flat>
      <slot name="title"></slot>
      <v-spacer></v-spacer>
      <v-btn v-if="closable"
        icon
        @click.stop="close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card flat tile>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions v-if="actions">
        <v-spacer />
        <c-btn
          text color="secondary"
          @click="close">
          {{ $t('actions.close') }}
        </c-btn>
        <c-btn
          v-if="isMedia"
          text color="primary"
          @click="saveFunction()">
          {{ $t('actions.save') }}
        </c-btn>
        <slot name="actions" />
        <a id='hiddenLink' :href=canSaveFunction style='display:none;' download>Download Pic</a>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CDialog',
  props: {
    actions: { type: Boolean, default: true },
    isMedia: { type: Boolean, default: false },
    canSave: { type: Boolean, default: false },
    canSaveFunction: { type: String, default: null },
    closable: { type: Boolean, default: true },
    maxWidth: { type: [String, Number], default: 650 },
    dark: { type: Boolean, default: false }
  },
  computed: {
    ...mapState('application', ['isMobile'])
  },

  methods: {
    close () {
      this.$emit('input', false)
      this.$emit('close')
    },
    saveFunction () {
      document.getElementById('hiddenLink').click()
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  display: inline-block !important;
}
</style>
