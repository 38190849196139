<template>
  <v-card flat tile>
    <v-card-text>
      <div class="small-text">Write the comment. Keep in mind Twitter's length limits if you are sharing via twitter as the url will be included</div>
      <v-textarea rows="2" counter outlined v-model="text" placeholder="Write some text" />
      <div>URL: {{ link }}</div>
    </v-card-text>
    <v-card-text class="pt-4">
      <a @click="shareTwitter" :href="twitterUrl" target="_blank" class="no-decoration">
        <v-row no-gutters align="center">
          <v-col cols="auto" class="mr-4"><img class="brand-icon" src="/images/twitter_button.svg"/></v-col>
          <v-col cols="auto" class="title-text-2 semibold">Twitter</v-col>
        </v-row>
       <div v-if="isTwitterConnected" class="small-text pl-16">As this account is connected to twitter, we will share automatically and upload the teaser if you chose twitter</div>
      </a>
      <a :href="whatsappUrl" target="_blank" class="no-decoration">
        <v-row no-gutters align="center">
          <v-col cols="auto" class="mr-4"><img class="brand-icon" src="/images/whatsapp_button.png"/></v-col>
          <v-col cols="auto" class="title-text-2 semibold">WhatsApp</v-col>
        </v-row>
      </a>
      <a :href="telegramUrl" target="_blank" class="no-decoration">
        <v-row no-gutters align="center">
          <v-col cols="auto" class="mr-4"><img class="brand-icon" src="/images/telegram_button.svg"/></v-col>
          <v-col cols="auto" class="title-text-2 semibold">Telegram</v-col>
        </v-row>
      </a>
    </v-card-text>
  </v-card>
</template>

<script>
import project from '@/project'
import { mapState } from 'vuex'
import PostApi from '@/api/PostApi'

export default {
  name: 'ShareForm',
  props: {
    post: Object
  },
  data () {
    return { text: '' }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['affiliate', 'affiliateSource']),
    isTwitterConnected () {
      return this.currentUser && this.currentUser.isTwitterConnected
    },

    twitterUrl () {
      return `https://twitter.com/intent/tweet?text=${this.text}&url=${this.link}`
    },

    telegramUrl () {
      return `https://telegram.me/share/url?url=${this.link}&text=${this.text}`
    },

    whatsappUrl () {
      return `https://api.whatsapp.com/send?text=${this.text} ${this.link}`
    },

    link () {
      let link = `${project.linkUrl}posts/${this.post.id}`
      if (this.affiliate) link = `${link}?af=${this.affiliate}`
      if (this.affiliateSource) link = `${link}&utm_source=${this.affiliateSource}`
      return link
    }
  },
  methods: {
    async shareTwitter(event) {
      if (this.isTwitterConnected) {
        event.preventDefault()
        await PostApi.twitterShare(this.post.id, { text: this.text, link: this.link })
      }
    },
    share () {
      navigator.share({
        title: this.$t('project'),
        text: this.text,
        url: this.url
      })
    }
  }
}
</script>
<style scoped>
.brand-icon {
  height: 45px;
  width: 45px;
  border-radius: 5px;
}
</style>
