<template>
  <v-row align="center">
    <v-col cols="auto">
      <span class="video-label">
        <strong>{{ $t('videos.views_label') }}:</strong> {{ post.viewsCount }}
      </span>
    </v-col>
    <v-col cols="auto">
      <span class="video-label">
        <strong>{{ $t('videos.likes_label') }}:</strong> {{ post.likesCount }}
      </span>
    </v-col>
    <v-col cols="auto">
      <span class="video-label">
        <strong>{{ $t('videos.duration_label') }}:</strong> {{ duration }}
      </span>
    </v-col>
    <v-col cols="auto">
      <v-btn icon rounded color="primary"
        @click="dialog = true"><v-icon>share</v-icon></v-btn>
    </v-col>
    <v-dialog v-model="dialog"
      max-width="680px">
      <v-toolbar flat>
        <v-spacer />
        <v-btn icon
          @click.stop="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <ShareForm :post="post" />
    </v-dialog>
  </v-row>
</template>

<script>
import project from '@/project'
import ShareForm from './ShareForm.vue'
const padTo = (elem, count, char) => ('' + elem).padStart(count, char)

export default {
  name: 'VideoActions',
  components: { ShareForm },
  props: {
    post: Object
  },
  data () {
    return { dialog: false }
  },
  computed: {
    isDark () {
      return {
        color: project.theme === 'dark' ? 'white' : 'black'
      }
    },
    duration () {
      const length = this.post.metadata.clip ? this.post.metadata.clip.duration : '0'
      const minutes = Math.floor(length / 60)
      const seconds = length % 60
      if (isNaN(minutes) || isNaN(seconds)) return '--:--'
      return `${padTo(minutes, 2, '0')}:${padTo(seconds, 2, '0')}`
    },
  }
}
</script>

<style lang="scss" scoped>
  .video-label {
    font-family: 'Roboto Condensed', sans-serif;
  }
</style>

