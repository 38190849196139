<template>
  <div ref="videoWrapper"
    class="video-player"
    :class="{ rounded }">
    <video ref="videoPlayer"
      playsinline
      v-bind="$attrs"
      :class="videoClasses"
      :style="videoStyle"
      :poster="poster"
      @loadeddata="resizeVideo"
      @ended="onEnd">
      <source v-for="(source, index) in sourcesWithMime"
        :key="`source-${index}`"
        :src="source.src"
        :type="source.type">
    </video>
    <div v-if="resizing"
      class="video-overlay d-flex align-center justify-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js'
import Utils from '@/utils'

const Mime = {
  mp4: 'video/mp4',
  m3u8: 'application/x-mpegURL',
  extensionFromUrl: (url) => {
    const [path] = url.split('?')
    return Utils.extractExtension(path)
  },
  fromUrl: (url) => {
    return Mime[Mime.extensionFromUrl(url)]
  }
}

export default {
  name: 'VideoPlayer',
  props: {
    height: Number,
    maxHeight: [Number, String],
    width: Number,
    contain: Boolean,
    rounded: Boolean,
    autoload: Boolean,
    poster: String,
    sources: { type: Array, default: () => [] }
  },
  data () {
    return {
      hls: null,
      player: null,
      landscape: false,
      resizing: false,
      innerWidth: 0,
      innerHeight: 0
    }
  },
  computed: {
    cover () {
      return !this.contain
    },
    videoStyle () {
      const style = {}
      if (this.cover) {
        return style
      }
      if (this.maxHeight) {
        style['max-height'] = `${this.maxHeight}px`
      }
      return style
    },
    sourcesWithMime () {
      return this.sources.filter(s => !!s).map(s => ({
        src: s,
        type: Mime.fromUrl(s)
      }))
    },
    videoSources () {
      const sources = { others: [] }
      this.sources.filter(f => f).forEach(source => {
        const ext = Mime.extensionFromUrl(source)
        if (ext === 'm3u8') {
          sources.m3u8 = source
        } else {
          sources.others.push(source)
        }
      })
      return sources
    },
    videoClasses () {
      return {
        'contain-video': this.contain,
        'cover-height': this.cover && this.landscape,
        'cover-width': this.cover && !this.landscape,
        rounded: this.rounded
      }
    },
    videoWidth () {
      return this.innerWidth || this.width
    },
    videoHeight () {
      return this.innerHeight || this.height
    }
  },
  methods: {
    onEnd () {
      this.$emit('videoEnded')
    },
    restart () {
      this.player.pause()
      this.player.currentTime = 0
    },
    play () {
      this.player.play()
    },
    pause () {
      this.player.pause()
    },

    resizeVideo (event) {
      if (!this.width || !this.height) {
        return
      }
      const viewport = this.$refs.videoWrapper
      this.landscape = this.width > this.height
      if (this.width <= viewport.offsetWidth && this.height <= viewport.offsetHeight) {
        return
      }
      this.resizing = true
      this.$nextTick(() => {
        this.finishResize()
      })
    },

    finishResize () {
      setTimeout(() => {
        if (this.landscape) {
          this.centerHorizontal()
        } else {
          this.centerVertical()
        }
        this.resizing = false
      }, 500)
    },

    centerHorizontal () {
      const viewport = this.$refs.videoWrapper
      const scrolling = (this.player.offsetWidth - viewport.offsetWidth) / 2
      viewport.scrollTo(scrolling, 0)
    },

    centerVertical () {
      const viewport = this.$refs.videoWrapper
      const scrolling = (this.player.offsetHeight - viewport.offsetHeight) / 2
      viewport.scrollTo(0, scrolling)
    },
    prepareHls () {
      if (this.videoSources.m3u8 && Hls.isSupported()) {
        const hls = new Hls()
        hls.loadSource(this.videoSources.m3u8)
        hls.attachMedia(this.player)
        let firstLoad = true
        hls.on(Hls.Events.FRAG_PARSING_INIT_SEGMENT, () => {
          !this.autoload && firstLoad && this.stopInitLoading(hls)
          firstLoad = false
        })
        this.player.onplay = () => hls.startLoad(-1)
        this.player.onpause = () => hls.stopLoad()
      }
    },
    stopInitLoading (hls) {
      setTimeout(() => {
        hls.stopLoad()
      }, 150)
    }
  },
  mounted () {
    this.player = this.$refs.videoPlayer
    this.prepareHls()
    if (this.cover) {
      this.resizeVideo()
    }
  },
  beforeDestroy () {
    if (this.hls) {
      this.hls.destroy()
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'src/scss/colors';

.video-player {
  overflow: hidden;
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;

  .contain-video {
    width: 100%;
    height: 100%;
  }
  .cover-width {
    width: 100%;
  }

  .cover-height {
    height: 100%;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: colors.$light-pink;
  }
}
</style>
