const loadCookies = () => {
  const map = {}
  var cookies = document.cookie.split('; ') || []
  cookies.forEach((cookie) => {
    if (!cookie.includes('invalid')) {
      const splitted = cookie.split('=')
      if (splitted.length > 1) {
        const [key, value] = splitted
        map[key] = value.split(';')[0]
      }
    }
  })
  return map
}

const persistCookie = (name, value) => {
  document.cookie = `${name}=${value}`
}

const cookies = {
  cookies: loadCookies(),

  set (name, value, props = {}) {
    this.cookies[name] = value
    const time = props.time || 214748347
    persistCookie(name, `${value}; Path=/; max-age=${time};`)
  },

  get (name) {
    return this.cookies[name]
  },

  getBoolean (name) {
    const value = this.get(name)
    return value === true || value === 'true'
  },

  getInt (name) {
    return parseInt(this.get(name, 10))
  },

  delete (name) {
    this.cookies[name] = undefined
    persistCookie(name, 'invalid; Path=/; Max-Age=-99999999;')
  }
}

export default cookies
