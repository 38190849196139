'user strict'

import api from '@/api/Api'

export default {

  find (username) {
    return api.get(`/users/${username}`)
  },

  list (query, options) {
    return api.get('/users', query, options)
  },

  follow (username) {
    return api.post(`/users/${username}/follow`)
  },

  unfollow (username) {
    return api.delete(`/users/${username}/follow`)
  },

  recommendations (perPage, options) {
    return api.get('/users/recommended', { perPage }, options)
  },

  checkUsername (username) {
    return api.get(`/users/check_name/${username}`)
  },

  referred (query, options) {
    return api.get('/payments/referred', query, options)
  },

  count () {
    return api.get('/users/count')
  }
}
