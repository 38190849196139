<template>
<div>
	<input
    class="formulario full obligatorio"
    name="nombre"
    type="text"
    title="Name"
    placeholder="Username"
    required=""
    v-model="username"
    @input="checkUsername">
  <span v-if="validUsernameError" class="error--text small-text">
    {{ validUsernameError }}
  </span>
</div>
</template>
<script>
import UserApi from '@/api/UserApi'

export default {
  name: 'UsernameInput',
  props: {
    rules: { type: Array, default: () => [] },
    value: String
  },
  data () {
    return {
      loadingValidUsername: false,
      validUsername: null,
      username: ''
    }
  },
  computed: {
    allRules () {
      return [...this.rules, this.$vMinMaxLength(3, 25), this.$vOnlyAlphanumeric]
    },
    usernameIcon () {
      return !this.loadingValidUsername && this.validUsername && !this.$refs.username.hasError
        ? 'done'
        : !this.loadingValidUsername && this.validUsername === false
          ? 'block'
          : ''
    },
    usernameIconColor () {
      return this.validUsername ? 'success' : 'error'
    },
    validUsernameError () {
      return this.validUsername === false
        ? this.$t('errors.already_taken_username')
        : null
    }
  },

  methods: {
    checkUsername () {
      this.username = this.username.trim()
      clearTimeout(this.timeout)
      this.$emit('input', this.username)
      return new Promise((resolve, reject) => {
        this.timeout = setTimeout(async () => {
          this.loadingValidUsername = true
          const { data, error } = await UserApi.checkUsername(this.username)
          if (!error) {
            this.validUsername = data.success
            resolve()
          } else {
            this.validUsername = null
          }
          this.loadingValidUsername = false
        }, 500)
      })
    }
  },
  mounted () {
    this.username = this.value
  }
}
</script>
