<template>
<div>
	<div class="center full">
		<div class="separa xs-size"></div>
		<p class="txt-sign">{{ $t('session.signup') }}</p>
		<div class="separa xs-size"></div>
	</div>
	<UsernameInput
    :value="username"
    @input="emitUpdateUsername"/>
	<div class="my-4"></div>
	<input class="formulario full obligatorio" name="email" type="text" title="Email" placeholder="Email" required=""
    :value="email"
    @input="emitUpdateEmail">
	<div class="my-4"></div>
	<input class="formulario full" type="password" name="password" placeholder="Password" required=""
    :value="password"
    @input="emitUpdatePassword">
  <div class="small-text">
    {{ $t('errors.bad_password') }}
  </div>
</div>
</template>

<script>
export default {
  name: "SignUp",
  props: {
    username: String,
    email: String,
    password: String
  },
  methods: {
    emitUpdateUsername(username) {
      this.$emit('update:username', username)
    },
    emitUpdateEmail(event) {
      this.$emit('update:email', event.target.value)

    },
    emitUpdatePassword(event) {
      this.$emit('update:password', event.target.value)
    }
  }
}
</script>
