'user strict'

import api from '@/api/Api'

export default {
  findResource (query) {
    return api.get('/payments/resource', query)
  },

  list (query) {
    return api.get('/payments', query)
  },

  refund (id) {
    return api.put(`/payments/${id}`, { refund: true })
  },

  statistics (query) {
    return api.get('/payments/statistics', query)
  },

  checkout (items) {
    return api.post('/payments/checkout', { items })
  }
}
