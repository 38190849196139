import cookies from '@/cookies'
import AppApi from '@/api/AppApi'
import CategoryApi from '@/api/CategoryApi'

const actionsTypes = {
  FetchMasterData: 'fetch_master_data',
  UpdateScreenSize: 'update_screen_size',
  UpdateSearching: 'update_searching',
  UpdateHasNotifications: 'update_has_notifications',
  UpdateHasMessages: 'update_has_messages',
  UpdateReferral: 'update_referral',
  UpdateAffiliate: 'update_affiliate',
  UpdateCookies: 'update_cookies',
  UpdateDarkTheme: 'update_dark_theme',
  CreateCategory: 'create_category',
  UpdateCategory: 'update_category',
  DeleteCategory: 'delete_category'
}

const state = {
  isMobile: false,
  isSmallScreen: false,
  isMediumScreen: false,
  isLargeScreen: false,
  isSmallScreenOrGreater: false,
  isMediumScreenOrGreater: false,
  isSearching: false,
  screenWidth: 0,
  screenHeight: 0,
  hasNotifications: false,
  hasMessages: false,
  affiliate: cookies.get('affiliate') || null,
  affiliateSource: cookies.get('affiliateSource') || null,
  referral: null,
  darkTheme: cookies.get('darkTheme') === 'true',
  showCookies: !cookies.get('allow-cookies'),
  billing: {},
  tags: [],
  categories: []
}

const mutations = {
  reset: (state) => {
    state.isSearching = false
  },

  screen_size: (state, dimensions) => {
    state.screenWidth = dimensions.width
    state.screenHeight = dimensions.height
    state.isLargeScreen = dimensions.width >= 1264
    state.isMediumScreen = dimensions.width >= 960 && dimensions.width < 1264
    state.isSmallScreen = dimensions.width >= 768 && dimensions.width < 960
    state.isMobile = dimensions.width < 768

    state.isMediumScreenOrGreater = dimensions.width >= 960
    state.isSmallScreenOrGreater = dimensions.width >= 768
  },
  searching_state: (state, isSeaching) => {
    state.isSearching = isSeaching
  },

  has_notifications: (state, hasNew) => {
    state.hasNotifications = hasNew
  },

  has_messages: (state, hasNew) => {
    state.hasMessages = hasNew
  },

  set_referral: (state, referral) => {
    state.referral = referral
  },

  set_affiliate: (state, { affiliate, affiliateSource }) => {
    state.affiliate = affiliate
    state.affiliateSource = affiliateSource
  },

  set_cookies: (state, show) => {
    state.showCookies = !show
  },

  set_darkTheme: (state, theme) => {
    state.darkTheme = theme
    cookies.set('darkTheme', theme)
  },
  set_billing: (state, billing) => {
    state.billing = billing
  },
  set_tags: (state, tags) => {
    state.tags = tags
  },
  set_categories: (state, categories) => {
    state.categories = categories
  },
  set_category: (state, category) => {
    const idx = state.categories.findIndex(c => c.id === category.id)
    if (idx !== -1) {
      state.categories.splice(idx, 1, category)
    } else {
      state.categories.push(category)
    }
  },
  remove_category: (state, category) => {
    const idx = state.categories.findIndex(c => c.id === category.id)
    if (idx !== -1) {
      state.categories.splice(idx, 1)
    }
  }
}

const actions = {
  [actionsTypes.FetchMasterData]: async (context) => {
    const { data, error } = await AppApi.masterData()
    if (error) {
      return data
    }
    context.commit('set_billing', data.billing)
    context.commit('set_tags', data.tags)
    context.commit('set_categories', data.categories)
    return { data }
  },
  [actionsTypes.UpdateScreenSize]: (context, dimensions) => {
    context.commit('screen_size', dimensions)
  },

  [actionsTypes.UpdateSearching]: (context, searching) => {
    context.commit('searching_state', searching)
  },

  [actionsTypes.UpdateHasNotifications]: (context, hasNew) => {
    context.commit('has_notifications', hasNew)
  },

  [actionsTypes.UpdateHasMessages]: (context, hasNew) => {
    context.commit('has_messages', hasNew)
  },

  [actionsTypes.UpdateReferral]: (context, ref) => {
    context.commit('set_referral', ref)
    cookies.set('referral', ref)
  },

  [actionsTypes.UpdateAffiliate]: (context, payload) => {
    if (cookies.get('affiliate')) {
      return
    }
    context.commit('set_affiliate', payload)
    const time = 24 * 60 * 60 // one day
    cookies.set('affiliate', payload.affiliate, { time })
    cookies.set('affiliateSource', payload.affiliateSource, { time })
  },

  [actionsTypes.UpdateCookies]: (context, show) => {
    context.commit('set_cookies', show)
  },

  [actionsTypes.UpdateDarkTheme]: (context, theme) => {
    context.commit('set_darkTheme', theme)
  },

  [actionsTypes.CreateCategory]: async (context, category) => {
    const { data, error } = await CategoryApi.create(category)
    if (!error) {
      context.commit('set_category', data)
    }
    return data
  },

  [actionsTypes.UpdateCategory]: async (context, category) => {
    const { data, error } = await CategoryApi.update(category)
    if (!error) {
      context.commit('set_category', data)
    }
    return data
  },

  [actionsTypes.DeleteCategory]: async (context, category) => {
    const { error } = await CategoryApi.delete(category.id)
    if (!error) {
      context.commit('remove_category', category)
    }
    return data
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'application',
  namespaced: true,
  state,
  mutations,
  actions
}
