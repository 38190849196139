<template>
  <v-app :style="rootStyle">
    <v-content>
      <v-container fill-height
                   class="unlogged">
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-content>
    <Footer theme="dark" app />
  </v-app>
</template>

<script>
import Footer from '@/components/layouts/Footer'
import project from '@/project'

export default {
  name: 'App',
  components: { Footer },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    rootStyle () {
      return {
        background: this.$vuetify.theme.themes[this.theme].background
      }
    }
  },
  mounted () {
    this.$vuetify.theme.dark = project.theme === 'dark'
  }
}
</script>
<style lang="scss">
.unlogged {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  max-width: 960px !important;
  margin: 0 auto;
}
</style>
