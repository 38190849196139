<template>
  <div class="swiper">
    <div
      class="preview-wrapper"
      :style="previewStyle"
      @mouseover="emitHover(true)"
      @mouseleave="emitHover(false)">
      <Resource
        rounded contain
        :poster="selectedResource.preview"
        :preview="preview"
        :class="{ pointer: carousel }"
        :max-height="previewHeight"
        :resource="selectedResource"
        :hover.sync="hover"
        v-bind="videoOptions"
        @click.native="showCarousel = true"
        @videoEnded="emitVideoEnded">
        <slot name="resource-slot"
          v-bind="resourceSlot"></slot>
      </Resource>
    </div>

    <Carousel v-if="carousel"
      v-model="showCarousel"
      :selected="selectedResource"
      :resources="resources" />

    <v-slide-group v-if="resources.length > 1"
      show-arrows center-active mandatory
      v-model="selectedIndex">
      <v-slide-item v-for="(resource, index) in resources"
        :key="`slide-${index}`"
        v-slot:default="{ active, toggle }">
        <v-card flat
          class="pointer ma-2 pa-1"
          :color="active ? 'primary' : ''">
          <c-img rounded
            :src="resource.thumb"
            :width="thumbSize"
            :height="thumbSize"
            @click.native="selectResource(resource, index, toggle)">
          </c-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>
<script>
import Resource from './Resource'

export default {
  name: 'Swiper',
  components: { Resource },
  props: {
    value: { type: Number, default: null },
    preview: { type: Boolean, default: true },
    carousel: { type: Boolean, default: true },
    previewHeight: [Number, String],
    resources: { type: Array, default: () => [] },
    thumbSize: { type: [String, Number], default: 74 },
    videoOptions: { type: Object, default: () => ({}) }
  },

  data () {
    return {
      hover: false,
      selectedIndex: 0,
      showCarousel: false
    }
  },

  computed: {
    previewStyle () {
      // height will be applied when there are
      // multiple resources to avoid moving thumbs on differents preview sizes
      // max-height where there is only one resource to avoid
      // a lot of blank spacing
      if (!this.previewHeight) return {}
      const key = this.resources.length > 1 ? 'height' : 'max-height'
      return {
        [key]: `${this.previewHeight}px`
      }
    },
    selected () {
      return this.value || this.selectedIndex
    },

    selectedResource () {
      return this.resources[this.selected]
    },
    resourceSlot () {
      return {
        hover: this.hover
      }
    }
  },

  watch: {
    value (value) {
      this.selectedIndex = value
    }
  },

  methods: {
    emitVideoEnded () {
      this.$emit('videoEnded')
    },
    selectResource (resource, index, toggle) {
      this.$emit('input', index)
      this.$emit('selected', resource)
      toggle()
    },
    emitHover (value) {
      this.$emit('update:hover', value)
    }
  },

  mounted () {
    this.selectedIndex = this.value || 0
  }
}

</script>
<style lang="scss" scoped>
.swiper {
  ::v-deep .v-slide-group__content {
    justify-content: center;
  }
  .preview-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
