<template>
  <section>
    <SectionTitle
      :title="$t('related.title')"
    />
    <div class="pa-2">
      <ThumbListSkeleton :loading="firstLoad">
        <v-row>
          <v-col v-for="post in thumbs"
            :key="`related-post-${post.id}`"
            cols="6" md="3">
            <VideoThumb :video="post" />
          </v-col>
        </v-row>
      </ThumbListSkeleton>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Api from '@/api/Api'
import AffiliatesApi from '@/api/AffiliatesApi'
import VideoThumb from './VideoThumb'
import project from '@/project'

export default {
  name: 'RelatedVideos',
  components: { VideoThumb },
  props: {
    username: String,
    categories: Array,
    post: Object
  },
  data () {
    return {
      posts: [],
      firstLoad: true,
      loadingPosts: false,
      nextPostCursor: '',
      project
    }
  },
  watch: {
    categories () {
      this.nextToken = ''
      this.loadPosts()
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    thumbs () {
      const count = this.isMobile ? 4 : 8
      return this.posts.slice(0, count)
    }
  },
  methods: {
    async loadPosts () {
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      this.loadingPosts = true
      const cancelToken = Api.cancelToken()
      this.cancelToken = cancelToken
      const query = {
        nextCursorToken: '',
        limit: 20,
        type: 'video',
        visibility: 'open'
      }
      if (this.categories.length) {
        query.postCategoryKeys = this.categories
      }
      const { data, error } = await AffiliatesApi.getAffiliatePost(query,{ cancelToken: cancelToken.token })
      if (!error) {
        this.posts = data.data
      }
      this.cancelToken = null
      this.loadingPosts = false
      this.firstLoad = false
    }
  },
  mounted () {
    this.loadPosts()
  }
}
</script>
