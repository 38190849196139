<template>
  <v-app>
    <Header />
    <v-content :style="isDark">
      <v-container class="unlogged">
        <keep-alive>
          <router-view v-if="cachePage" :key="$route.fullPath"/>
        </keep-alive>
        <router-view v-if="!cachePage" :key="$route.fullPath"/>
      </v-container>
    </v-content>
    <Footer theme="dark" app />
  </v-app>
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import project from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  components: { Header, Footer },
  data () {
    return {
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    cachePage () {
      return this.$route.meta.cache !== false
    },
    isDark () {
      return {
        backgroundColor: project.theme === 'dark' ? 'black' : 'white'
      }
    }
  }
}
</script>

<style scoped>
::v-deep .unlogged {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  max-width: 100% !important;
  margin: 0 auto;
  padding: 0 !important;
}
</style>
