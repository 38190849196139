import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)

const paths = {
  mode: 'history',
  routes: routes.concat([
    {
      path: '*', redirect: '/home'
    }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }

}

// global middlewares
const middlewares = [
  require('./middlewares/locale').default,
  require('./middlewares/referrals').default,
  require('./middlewares/affiliation').default,
  require('./middlewares/promotion').default,
  require('./middlewares/authentication').default,
  require('./middlewares/whitelabel').default,
  require('./middlewares/abilities').default,
  require('./middlewares/layout').default
]

// Fixing routing same url navigation
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const router = new Router(paths)

router.beforeEach(async function (to, from, next) {
  for (let i = 0; i < middlewares.length; i++) {
    const middyResult = await middlewares[i](to, from)
    if (middyResult && middyResult.redirect) {
      next(middyResult.redirect)
      return
    }
  }
  next()
})

export default router





