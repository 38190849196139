import app, { Actions as AppActions } from '@/store/application'
import user, { Actions as UserActions } from '@/store/modules/users'
import ProfileApi from '@/api/ProfileApi'
import SubscriptionApi from '@/api/SubscriptionApi'
import i18n from '@/i18n'
import { currency } from '../plugins/currency'

const UpdateNotifications = `${app.namespaceName}/${AppActions.UpdateHasNotifications}`
const UpdateMessages = `${app.namespaceName}/${AppActions.UpdateHasMessages}`
const SetUser = `${user.namespaceName}/${UserActions.Set}`
const FetchUser = `${user.namespaceName}/${UserActions.Fetch}`

const actionsTypes = {
  Fetch: 'fetch',
  UpdateAccountData: 'update_account_data',
  UpdatePersonalData: 'update_personal_data',
  UpdateSubscriptionOffers: 'update_subscription_offers',
  CreateTrial: 'create_trial',
  SelectTrial: 'select_trial',
  DisableTrial: 'disable_trial',
  FetchTrials: 'fetch_trials',
  Set: 'set',
  UpdateWallet: 'set_wallet',
  UpdatePromotion: 'update_promotion',
  BlockCountry: 'block_country',
  UnblockCountry: 'unblock_country',
  BlockUser: 'block_user',
  UnblockUser: 'unblock_user'
}

const state = {
  currentUser: {},
  promotion: {},
  wallet: {},
  eurWallet: {},
  usdWallet: {},
  tags: [],
  bannedCountries: [],
  blockedUsers: [],
  trials: [],
  selectedTrial: null,
  isInfluencer: false,
  isFollower: false,
  isCompliance: false,
  isContentManager: false,
  isAdmin: false
}

const getters = {
  currentWallet: (state) => state.currentUser.billing.currency === 'EUR' ? state.eurWallet : state.usdWallet
}

const mutations = {
  reset: (state) => {
    state.currentUser = {}
    state.promotion = {}
    state.wallet = {}
    state.eurWallet = {}
    state.usdWallet = {}
    state.tags = {}
    state.bannedCountries = []
    state.blockedUsers = []
    state.trials = []
    state.isInfluencer = false
    state.isFollower = false
    state.isCompliance = false
    state.isContentManager = false
    state.isAdmin = false
  },

  set_user: (state, user) => {
    state.currentUser = user
    state.promotion = user.promotion || {}
    state.wallet = user.wallet || { currency: 'EUR' }
    const wallets = user.wallets || []
    state.eurWallet = wallets.find(w => w.currency === 'EUR') || state.wallet
    state.usdWallet = wallets.find(w => w.currency === 'USD') || { currency: 'USD' }
    state.tags = user.tags || []
    state.bannedCountries = user.bannedCountries || []
    state.blockedUsers = user.blockedUsers || []
    if (user.subscriptionOffers) {
      user.subscriptionOffers.forEach(offer => {
        offer.minPrice = offer.type === 'month' ? 100 : 500
      })
    }
    state.trials = user.subscriptionTrials || []
    state.isInfluencer = user && user.role === 'influencer'
    state.isFollower = user && user.role === 'follower'
    state.isCompliance = user && user.role === 'compliance'
    state.isContentManager = user && user.role === 'content_manager'
    state.isAdmin = user && user.role === 'admin'
    i18n.loadLocale(user.lang)
  },

  set_attrs: (state, attrs) => {
    Object.keys(attrs).forEach(key => {
      state.currentUser[key] = attrs[key]
    })
  },

  set_promotion: (state, promotion) => {
    if (state.currentUser) {
      state.currentUser.promotion = promotion
    }
    state.promotion = promotion
  },

  set_wallet: (state, wallet) => {
    if (state.currentUser) {
      state.currentUser.wallet = wallet
    }
    state.wallet = wallet
  },

  add_trial: (state, trial) => {
    state.currentUser.subscriptionTrials.push(trial)
    state.trials = state.currentUser.subscriptionTrials
  },

  remove_trial: (state, trial) => {
    const index = state.trials.findIndex(t => t.id === trial.id)
    if (index !== -1) {
      state.currentUser.subscriptionTrials.splice(index, 1)
      state.trials = state.currentUser.subscriptionTrials
    }
  },

  set_trials: (state, trials) => {
    state.currentUser.subscriptionTrials = trials
    state.trials = trials
  },

  set_selected_trial: (state, trial) => {
    state.selectedTrial = trial
  },

  set_banned_countries: (state, countries) => {
    state.bannedCountries = countries
  },

  add_banned_country: (state, country) => {
    if (state.bannedCountries.find(c => c === country)) {
      return
    }
    state.bannedCountries.push(country)
    state.bannedCountries.sort()
  },

  remove_banned_country: (state, country) => {
    state.bannedCountries = state.bannedCountries.filter(c => c !== country)
  },

  set_blocked_users: (state, users) => {
    state.blockedUsers = users
  },

  add_blocked_user: (state, user) => {
    if (state.blockedUsers.find(u => u.username === user.username)) {
      return
    }
    state.blockedUsers.push(user)
    state.blockedUsers.sort((user1, user2) => user1.username < user2.username)
  },

  remove_blocked_user: (state, user) => {
    state.blockedUsers = state.blockedUsers.filter(u => u.username !== user.username)
  }
}

const actions = {
  [actionsTypes.Fetch]: async (context) => {
    const response = await ProfileApi.profile()
    if (!response.error) {
      context.dispatch('set', response.data)
      currency.setCurrency(response.data.billing.currency)
    }
    return response
  },

  [actionsTypes.UpdateAccountData]: async (context, attrs) => {
    const response = await ProfileApi.updateAccountData(attrs)
    context.dispatch(actionsTypes.Fetch)
    return response
  },

  [actionsTypes.UpdatePersonalData]: async (context, attrs) => {
    const response = await ProfileApi.updatePersonalData(attrs)
    context.dispatch(actionsTypes.Fetch)
    return response
  },

  [actionsTypes.UpdateSubscriptionOffers]: async (context, attrs) => {
    const response = await ProfileApi.updateSubscriptionOffers(attrs)
    if (!response.error) {
      context.commit('set_attrs', response.data)
    }
    return response
  },

  [actionsTypes.CreateTrial]: async (context, trial) => {
    const response = await SubscriptionApi.createTrial(trial)
    if (!response.error) {
      context.commit('add_trial', response.data)
    }
    return response
  },

  [actionsTypes.SelectTrial]: async (context, trial) => {
    context.commit('set_selected_trial', trial)
  },

  [actionsTypes.DisableTrial]: async (context, id) => {
    const response = await SubscriptionApi.disableTrial(id)
    if (!response.error) {
      context.commit('remove_trial', { id })
    }
    return response
  },

  [actionsTypes.FetchTrials]: async (context, attrs) => {
    const response = await ProfileApi.listTrials(attrs)
    if (!response.error) {
      context.commit('set_trials', response.data)
    }
    return response
  },

  [actionsTypes.UpdatePromotion]: async (context, attrs) => {
    const response = await ProfileApi.requestPromotion(attrs)
    if (!response.error) {
      if (attrs.subscriptionPrice) {
        context.commit('set_attrs', { subscriptionPrice: attrs.subscriptionPrice })
      }
      context.commit('set_promotion', response.data)
    }
    return response
  },

  [actionsTypes.UpdateWallet]: (context, wallet) => {
    context.commit('set_wallet', wallet)
  },

  [actionsTypes.Set]: (context, user) => {
    context.commit('set_user', user)
    context.dispatch(SetUser, user, { root: true })
    context.dispatch(UpdateMessages, user.alerts.hasUnreadMessages, { root: true })
    context.dispatch(UpdateNotifications, user.alerts.hasNotifications, { root: true })

    ProfileApi.listBlockedCountries().then(({ data, error }) => {
      if (!error) {
        context.commit('set_banned_countries', data)
      }
    })
    ProfileApi.listBlockedUsers().then(({ data, error }) => {
      if (!error) {
        context.commit('set_blocked_users', data.data)
      }
    })
  },

  // FIXME move this to mediators
  [actionsTypes.BlockUser]: async (context, user) => {
    context.commit('add_blocked_user', user)
    const response = await ProfileApi.blockUser(user.username)
    context.dispatch(FetchUser, { username: user.username, force: true }, { root: true })
    if (response.error) {
      context.commit('remove_blocked_user', user)
    }
    return response
  },

  // FIXME move this to mediators
  [actionsTypes.UnblockUser]: async (context, user) => {
    context.commit('remove_blocked_user', user)
    const response = await ProfileApi.unblockUser(user.username)
    context.dispatch(FetchUser, { username: user.username, force: true }, { root: true })
    if (response.error) {
      context.commit('add_blocked_user', user)
    }
    return response
  },

  [actionsTypes.BlockCountry]: (context, country) => {
    ProfileApi.blockCountry(country)
    context.commit('add_banned_country', country)
  },

  [actionsTypes.UnblockCountry]: (context, country) => {
    ProfileApi.unblockCountry(country)
    context.commit('remove_banned_country', country)
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'profile',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
