<template>
  <v-row v-if="loading" align="center" justify="center" class="my-5">
    <v-progress-circular v-if="loading"
      indeterminate
      class="p-4"
      color="primary">
    </v-progress-circular>
  </v-row>
  <section v-else v-html="conditions">
  </section>
</template>
<script>
import marked from 'marked'
import project from '@/project'

export default {
  name: 'TermsConditions',
  props: {
    type: { type: String }
  },
  data() {
    return {
      loading: false,
      conditions: ''
    }
  },

  watch: {
    type: {
      async handler(value) {
        this.loadConditions()
      },
      immediate: true
    },
    '$i18n.locale': function() {
      this.loadConditions()
    }
  },
  methods: {
    async loadConditions () {
      const lang = this.$i18n.locale === 'es' ? 'es' : 'en'
      this.loading = true
      const terms = await import(`./texts/${this.type}_${lang}.md`)
      this.conditions = marked(terms.default
        .replaceAll('{{SITE}}', this.$t('site'))
        .replaceAll('{{PROJECT_UPPER}}', this.$t('project_upper'))
        .replaceAll('{{PROJECT}}', this.$t('project'))
        .replaceAll('{{CONTACT_EMAIL}}', this.$t('contact_email'))
        .replaceAll('{{INFO_EMAIL}}', this.$t('info_email'))
        .replaceAll('{{CIF_IMG}}', `images/cif_${project.linkProject === 'Loverfans' ? 'black' : 'white'}.png`)
        .replaceAll('{{COMPANY_IMG}}', `images/artemisa_${project.linkProject === 'Loverfans' ? 'black' : 'white'}.png`)
      )
      this.loading = false

    }
  }
}
</script>
