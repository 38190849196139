<template>
<section>
    <v-row justify="center">
      <v-col
        cols="12" md="3">
        <SessionForm :login="isLogin" />
      </v-col>
    </v-row>
</section>
</template>

<script>
import { mapState } from 'vuex'
import SessionForm from '@/components/sessions/SessionForm'

export default {
  name: 'SessionView',
  components: { SessionForm },
  props: {
    form: String
  },
  computed: {
    ...mapState('session', ['user']),
    isLogin () {
      return this.form === 'login'
    }
  },
  activated () {
    if (this.user) {
      this.$router.replace('/')
    }
  }
}
</script>
