module.exports = {
  ...require('@/project').locales,
  langs: {
    es: 'ES',
    en: 'EN',
    fr: 'FR',
    de: 'DE',
    pt: 'PT',
    it: 'IT',
    names: {
      es: 'Spanish',
      en: 'English',
      fr: 'French',
      de: 'Deutsch',
      pt: 'Portuguese',
      it: 'Italian'
    }
  },

  hints: {
    covid: 'You may experience shipping delays because of COVID-19',
    maintenance: 'Maintenance',
    wallet_1: 'We are doing a maintance. It is possible that during this maintenance the virtual wallet will not work correctly and you will not see the correct amount.',
    wallet_2: 'We apologize for the inconvenience.'
  },

  countries: require('./countries/en').sort((a, b) => a.text.localeCompare(b.text)),
  countries_v2: require('./countries_v2/en.json').sort((a, b) => a.text.localeCompare(b.text)),

  modal: {
    not_show_again: 'Don\'t show again',
    affiliate_program: 'We launched our Affiliate Program!',
    affiliate_desc: 'Next week we will begin to do advertising agreements with websites that will send external traffic to your profile and help you <strong>increase your revenue</strong>.',
    not_interested: 'If you are not interested, please uncheck the box.',
    more_info: 'MORE INFO',
    telegram: 'Join our STARs Telegram group to know all the news',
    month_challenge: 'Remember that you can now upload your video for the Monthly Challenge',
    trials_title: `Free Trials Availables!`,
    trials_desc: `You can now create free invitations for your followers to serve as a hook to your <strong>@:project</strong> profile.`
  },

  scat_modal: {
    title: 'Loverfans familiy is growing!',
    subtitle: 'Welcome to',
    star_platform: 'The first and only fans platform for <strong>100% extreme content</strong> made for your.',
    fan_platform: 'The first and only fans platform for <strong>100% extreme content</strong>. Take a look to the newest Star\'s content.',
    what_is: 'What is Scatbook?',
    faq_link: 'Solve your doubts',
    fan_platform_2: 'We are sure this will <strong>INTEREST YOU</strong>. ',
    fan_same_credentials: 'Use your same Loverfans\' username and password to login into Scatbook, Twitter login wont work.',
    is_now_on: 'is now at',
    go_to: 'Go to Scatbook'
  },

  footer: {
    cookies: 'Cookies',
    terms_conditions: 'Terms & Cond.',
    privacy: 'Privacy',
    disclaimer: 'All persons appearing in this website and or videos are eighteen years of age or older. Proof on file.'
  },

  home: {
    index: 'THIS IS A SMALL TEXT FOR INDEXING CONTENT'
  },

  session: {
    login: 'Sign in',
    signup: 'Sign Up in seconds',
    already_member_question: 'Already a member?',
    not_registered_question: 'Doesn\t have an account yet?',
    click_login: 'Click here to Log In',
    click_signup_seconds: 'Click here to Sign up in seconds'
  },

  generic: {
    home: `Home`,
    scatbook: `My Scatbook`,
    loverfans: `My Loverfans`,
    about: `About Me`,
    new_videos: `Newest`,
    top_rated: `Top rated`,
    most_viewed: `Most viewed`,
    health: `Health Disclaimer`,
    beginners: `Beginner's Guide`,
    past: `Past Reviews`,
    supporting: `Supporting Scat`,
    purchase: {
      price: 'Buy for {price}'
    },
    add: {
      cart: {
        post: `Add video to cart`,
        sale: `Add to cart`
      },
      credit: `Add Credit to Cart`,
    },
    videos: {
      filter: {
        popular: 'Popular',
        newest: 'Newest'
      }
    }
  },

  related: {
    title: 'Related Videos'
  },

  meta: {
    title: {
      scatface: {
        home: '🥇 Free Scat porn videos by Scatface | Best extreme tube',
        video: '{title} | Free scat videos on Scatface',
        user: 'Free Scat porn videos of {username} | Scatface'
      },
      loverfanstube: {
        home: '🥇 Free porn videos by Loverfanstube | Best porn tube',
        video: '{title} | Free videos on Loverfanstube',
        user: 'Free porn videos of {username} | Loverfanstube'
      },
      playdiaper: {
        home: '🥇 BEST TUBE OF FREE DIAPER SEX PORN VIDEOS | PLAYDIAPER',
        video: '{title} | Free diaper sex porn on Playdiaper',
        user: 'Diaper sex porn videos of {username} | Playdiaper'
      },
      onlyvids: {
        home: '🥇 Free porn videos by Onlyvids | Best porn tube',
        video: '{title} | Free videos on Onlyvids',
        user: 'Free porn videos of {username} | Onlyvids'
      }
    },
    description: {
      scatface: {
        home: 'Free scat porn videos of the best scat stars and free gay scat porn. The best tube of extreme content is Scatface',
        video: 'Watch {title} of {username} on Scatface. The best tube of scat porn videos with the best stars and free gay scat porn.',
        user: 'Are you looking for scat porn videos of {username}? Watch them free. The official profile of {username} on Scatface. The best porn tube of scat and gay scat'
      },
      loverfanstube: {
        home: 'Free porn videos of the best stars and free porn. The best tube of sexual content is Loverfanstube',
        video: 'Watch {title} of {username} on Loverfanstube. The best tube of porn videos with the best stars and free gay porn.',
        user: 'Are you looking for porn videos of {username}? Watch them free. The official profile of {username} on Loverfanstube. The best porn tube of straight, lesbian and gay'
      },
      playdiaper: {
        home: 'Do you like diaper sex? We have the best diaper porn videos on internet: mess, poop and dirty diapers wearing by girls, lesbians and gays. Al free on the unique free tube of diaper porn videos, Playdiaper',
        video: 'Watch {title} of {name} on Playdiaper. The best tube of sex diaper porn with with the best videos of mess, poop and dirty diapers',
        user: 'Are you looking for diaper sex porn videos of {username}? Watch them free. The official profile of {username} on Playdiaper. The best porn tube of diaper sex.'
      },
      onlyvids: {
        home: 'Free porn videos of the best stars and free porn. The best tube of sexual content is Onlyvids',
        video: 'Watch {title} of {username} on Onlyvids. The best tube of porn videos with the best stars and free gay porn.',
        user: 'Are you looking for porn videos of {username}? Watch them free. The official profile of {username} on Onlyvids. The best porn tube of straight, lesbian and gay'
      }
    },
    home: {
      scatface: 'FREE SCAT PORN VIDEOS STRAIGHT AND GAY',
      loverfanstube: 'FREE PORN VIDEOS',
      playdiaper: 'FREE DIAPER SEX PORN VIDEOS ON PLAYDIAPER',
      onlyvids: 'FREE PORN VIDEOS'
    },
    passwords: {
      title: 'Remember password | @:project'
    },
    conditions: {
      title: 'Terms and contitions | @:project',
      description: 'Terms and conditions for @:project usage. You will have all the information relative to @:project platform and your respective rights and obligations.'
    },
    contact: {
      title: 'Contact with @:project',
      description: 'You can send any message to us and we will answer as soon as possible'
    },
    cookies: {
      title: 'Cookies usage on @:project',
      description: 'Here is detailed what is the cookies usage we do on @:project.'
    },
    faq: {
      title: 'FAQ: Frequently asked questions | @:project'
    },
    privacy: {
      title: 'Our privacy | @:project'
    },
    signup: {
      title: 'Register | @:project',
      description: 'Register to @:project platform to become Star or enjoy watching all our private content'
    },
    users: {
      title: '{name}\'s @:project official profile',
      description: '{name}\'s official @:project profile where you have access to custom packs. All {name}\'s private photos and videos only for fans'
    },
    posts: {
      title: '@{username} post | @:project'
    },
    medialib: {
      title: 'Premium content | @:project',
      description: 'Premium Loverfan\'s content for you. You can search througth all our content and find out the most exclusive photos and videos on the web'
    }
  },

  words: {
    here: 'here',
    all: 'All',
    finish_in: 'Finishing',
    more_count: '{count} more',
    today: 'today',
    thank_you: 'Thank you!',
    month: 'month',
    months: 'months',
  },

  privacy: {
    tittle: 'PRIVACY',
    section_1: {
      tittle: '1 Who is responsible for processing your data?',
      desc_1: 'The responsible for the treatment of your personal data is <img src="images/artemisa_{color}.png" style="margin-bottom: -3px; width: 180px; height: 14px"/> (from now on "@:project_upper"), with N.I.F. <img src="images/cif_{color}.png" style="margin-bottom: 0px; width: 80px; height: 11px"/> and social address in Paseo de la Castellana 129, 1º, 28020, Madrid.',
      desc_2: 'We inform you that the ownership and the Website of <a href="https://@:site" target="_blank">@:project</a> (hereinafter "THE WEBSITE"), is <img src="images/artemisa_{color}.png" style="margin-bottom: -3px; width: 180px; height: 14px"/>',
      desc_3: 'The access and/or use of the Website, attributes you the condition of User (from now on, the "User"), and implies the acceptance, from such access and/or use, of the present Privacy Policy.',
      desc_4: 'The User may contact @:project_upper at the following email address: <a href="mailto:@:contact_email">@:contact_email</a>'
    },
    section_2: {
      tittle: '2 Why is @:project_upper entitled to carry out data processing?',
      desc_1: '@:project_upper is entitled to process your data in order to carry out the provision of its services.',
      desc_2: '@:project_upper takes the protection of your privacy and personal data very seriously. Therefore, your personal information is kept secure and treated with the utmost care.',
      desc_3: 'The present Privacy Policy regulates the access and use of the service (from now on, the "Service") that @:project_upper makes available to the User.'
    },
    section_3: {
      tittle: '3 What are the purposes of the processing of your personal data by @:project_upper?',
      desc_1: 'Your personal data collected by @:project_upper may be used for the following purposes, according to the consent given in the corresponding registration form:',
      desc_2: 'The provision of the services offered through the Website.',
      desc_3: 'The sending of commercial communications and/or Newsletter by @:project_upper about the services offered through the website.'
    },
    section_4: {
      tittle: '4 Veracity of data provided by Users and Influencers',
      desc_1: 'The User and the Influencer guarantee that the personal data provided are true and is responsible for communicating to @:project_upper any changes in these. The User and the Influencer will respond, in any case, of the veracity of the facilitated data, reserving @:project_upper the right to exclude from the registered Services to any User or Influencer that has facilitated false data, without damage of the other actions that proceed in Right.',
      desc_2: 'On the other hand, the Influencer will be considered as responsible for the treatment and @:project_upper in charge of processing the data provided through the Influencer Channel. In this sense, the Influencer declares to have fulfilled the requirements of current legislation on data protection. The Influencer agrees to use the website, specifically its Influencer Channel in accordance with the Terms and Conditions and this Privacy Policy.'
    },
    section_5: {
      tittle: '5 Data retention',
      desc_1: 'The personal data provided will be kept by @:project_upper while the User or the Influencer do not express their willingness to unsubscribe from the services of @:project_upper, and this with the aim of receiving information about products of the company.'
    },
    section_6: {
      tittle: '6 User/Influence rights in relation to your data',
      desc_1: 'The User has the right to',
      desc_2: 'Accessing your personal data',
      desc_3: 'Request the rectification of inaccurate data',
      desc_4: 'Request its removal',
      desc_5: 'Request the limitation of the treatment of your data',
      desc_6: 'Opposing the processing of your data',
      desc_7: 'Request your portability',
      desc_8: 'Not be subject to automated individual decisions',
      desc_9: 'In addition, the User may exercise his/her right to forget',
      desc_10: 'The User can exercise all these rights at the following email address <a href="mailto:@:contact_email">@:contact_email</a> indicating the reason for their request and providing a copy of their ID.',
      desc_11: 'The User can also send his request by ordinary mail to the following address:',
      desc_12: 'Paseo de la Castellana 129, 1º, 28020, Madrid.',
      desc_13: 'Without prejudice to any other administrative remedy or legal action, the User and Influencer shall have the right to file a complaint with a Control Authority, in particular in the Member State in which they have their habitual residence, place of work or place of the alleged infringement, in the event that they consider that the processing of their personal data is not appropriate to the regulations, as well as in the event that they are not satisfied with the exercise of their rights. The supervisory authority to which the complaint has been lodged shall inform the complainant of the course and outcome of the complaint.'
    },
    section_7: {
      tittle: '7 Data security',
      desc_1: 'The protection of the privacy and the personal data of the Users is very important for @:project_upper. Therefore, @:project_upper does everything in its power to prevent your data from being used inappropriately, allowing access to them only to authorized personnel.',
      desc_2: '@:project_upper maintains the security levels of personal data protection in accordance with applicable regulations and has established all the technical means at its disposal to prevent the loss, misuse, alteration, unauthorized access and theft of data you provide through the Website without prejudice to inform you that security measures on the Internet are not impregnable.',
      desc_3: '@:project_upper undertakes to comply with the duty of secrecy and confidentiality of personal data in accordance with applicable law, and to give them a safe treatment in the assignments and international transfers of data that, where appropriate, may occur.',
      desc_4: 'In those services of the Website need to register as a User and Influencer, you must choose a password. The User and the Influencer is responsible for maintaining the confidentiality of this password, as well as all activities that occur in the session initiated with your name and password.',
      desc_5: 'The User and Influencer agree to notify @:project_upper as soon as possible of any unauthorized use of their User/Influencer name and/or password or any other security failure. @:project_upper will not be responsible for any damage or loss that may arise due to the failure of the User/Influencer to comply with this obligation.',
      desc_6: 'Our website uses SSL security protocols to ensure the confidentiality of your information in data transmission.',
      desc_7: 'In no event shall Artemisa 3000 Tech Solutions S.L. sell, purchase, store, transfer or otherwise distribute your account or card transaction data, unless officially required by the Visa/MasterCard corporation or the appropriate government or entity. The transaction is carried out entirely through the secure server of our payment gateway.'
    },
    section_8: {
      tittle: '8 Changes',
      desc_1: '@:project_upper reserves the right to revise its Privacy Policy at any time. For this reason, please check this Privacy Policy regularly for the latest version.',
      desc_2: 'However, any changes to this Privacy Policy will be communicated to the User/Influence.'
    },
    section_9: {
      tittle: '9 Links to Websites',
      desc_1: 'The @:project_upper website may contain links to the websites of third party companies and entities. @:project_upper can not be responsible for the way these companies treat the protection of privacy and personal data, so we advise you to read carefully the Privacy Policy statements of these websites that are not owned by @:project_upper in relation to the use, processing and protection of personal data.',
      desc_2: 'The conditions offered by these websites may not be the same as those offered by @:project_upper.'
    },
    section_10: {
      tittle: '10 Questions',
      desc_1: 'If you have any questions about this Privacy Policy or the processing of your data please contact: <a href="mailto:@:contact_email">@:contact_email</a>'
    },
    section_11: {
      tittle: '11 Acceptance and Consent',
      desc_1: 'The User declares to have been informed of the conditions on protection of personal data, accepting and consenting to the treatment of these by @:project_upper, in the form and for the purposes indicated in this Privacy Policy.'
    }
  },

  actions: {
    actions: 'Actions',
    add: 'Add',
    already_subscribed: 'Subscribed',
    back: 'Back',
    ok: 'Accept',
    cancel: 'Cancel',
    cancel_subscription: 'Cancel Subscription',
    edit: 'Edit',
    continue: 'Continue',
    save: 'Save',
    update: 'Update',
    send: 'Send',
    pay: 'Pay',
    see: 'Show',
    show: 'Show',
    see_all: 'Show all',
    see_more: 'Show more',
    show_all: 'Show all',
    follow: 'Follow',
    subscribe: 'Subscribe',
    signup: 'Free Sign up',
    mini_signup: 'Sign Up',
    free_access: 'Access w/o Registration',
    search: 'Search',
    login: 'Log in',
    logout: 'Logout',
    publish: 'Publish',
    enter: 'Enter',
    exit: 'Exit',
    request_email: 'Request email',
    copy_link: 'Copy link',
    report: 'Report',
    close: 'Close',
    buy: 'Buy',
    show_details: 'Show details',
    show_edit: 'Show or edit',
    delete: 'Delete',
    filter: 'Filter',
    upload_image: 'Upload image',
    next: 'Next',
    previous: 'Previous',
    unfollow: 'Unfollow',
    reload: 'Reload',
    reload_wallet: 'Reload wallet',
    withdraw: 'Withdraw',
    other: 'Other',
    send_tip: 'Send tip',
    tip: 'Tip',
    like: 'Love',
    comment: 'Comment',
    repost: 'Repost',
    favorite: 'Favorite',
    share: 'Share in media',
    finish: 'Finish',
    show_item: 'Show {item}',
    show_post: 'Show post',
    show_product: 'Show product',
    go_to_item: 'Go to {item}',
    connect: 'Connect',
    disconnect: 'Disconnect',
    bid: 'Bid',
    bid_again: 'Bid again',
    twitter_login: 'Sign up/Login with Twitter',
    discover_them: 'Discover them',
    more: 'More',
    bring_me_there: 'Bring me',
    block: 'Block',
    unblock: 'Unblock',
    show_more: 'Show more...',
    reject: 'Reject',
    commit: 'Commited',
    chose: 'Chose',
    order_by: 'Order by',
    join: 'join',
    start: 'Start',
    retry: 'Retry',
    change: 'Change',
    placeholder_select_file: 'Choose or drop a File',
    download: 'Download',
    rotate_left: 'Rotate 90º to Left',
    rotate_right: 'Rotate 90º to Right',
    flipX: 'Flip horizontally',
    flipY: 'Flip Vertically',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    fly: 'Change fly position',
    record: 'Record',
    stop: 'Stop',
    fullVideo: 'SHOW FULL VIDEO',
    get_full_video: 'BE MY FAN AND SEE ALL MY VIDEOS ➤',
    mini: 'BE MY FAN ➤',
    be_fan: 'Be my fan on <strong>@:project community ➤</strong>'
  },

  application: {
    title: '@:project',
    company_name: 'Artemisa 3000 Tech Solutions S.L.',
    company_cif: 'CIF B - 8 8 4 6 6 7 4 3',
    company_address: 'Paseo de la Castellana 129, 1ª, 28020',
    company_city: 'Madrid',
    company_country: 'Spain',
    contact: 'Contact',
    imprint: 'Imprint',
    payments_imprint: 'Payments Imprint',
    contact_email: '@:contact_email',
    contact_number: '+34 654987321',
    under_construction: 'We are improving this section.',
    under_construction_description: 'We are actively working to improve your @:project experience and we put all our love and affection in what we do. You will heard from us soon with the new @:project\' features.',
    not_found: 'We couldn\'t find what you are looking for',
    not_found_description: 'We are sorry to tell you that we couldn\'t find what you were looking for.',
    commercegate_1: 'Facilidator de Pagos y ',
    commercegate_2: 'Soporte de Facturación',
    files_max_size: 'Max file size is 3GB',
    vat: 'VAT',
    total: 'Total',
    fee: 'Fee',
    invoice_fee: 'Tax Withholding',
    maintance: {
      warning_1: '@:project is currentyly down for maintance. ',
      warning_2: 'We are doing some scheduled maintance. We are improving some sections and our servers for giving you a better experience',
      warning_3: 'We\'re sorry for the inconvenience. Please check back in 30 minutes.'
    },
    menu: {
      home: 'Home',
      lives: 'Lives',
      post_promo: 'Promo',
      statistics: 'Statistics',
      notifications: 'Notifications',
      categories: 'Categorías',
      influencers: 'Stars',
      favorites: 'Favorites',
      videotube: 'Medialib',
      market: 'Shop',
      products: 'Products',
      settings: 'Settings',
      wallet: 'Wallet',
      referrals: 'Referrals',
      chat: 'Chat',
      resources: 'Resources',
      messages: 'Messages',
      users: 'Users',
      admin: 'Admin',
      billing: 'Monetization',
      faq: 'FAQ',
      tutorial: 'Tutorial',
      contact: 'Contact',
      language: 'Language',
      logout: 'Log out',
      terms_conditions: 'Terms & Cond.',
      privacy: 'Privacy',
      cookies: 'Cookies',
      light_theme: 'Change to light theme',
      imprint: 'Imprint',
      dark_theme: 'Change to dark theme',
      validation: 'Validation'
    }
  },
  share: {
    title: 'Share on...',
    via_app: 'Via application'
  },

  cancel_subscription: {
    title: 'Confirm Unsubscription',
    info: 'Do you really want to unsubscribe from <strong>{username}</strong>?<br>The next time you want to register as <strong>a {username}\'s subscriber</strong> it\'s possible that the current amount <strong>won\'t be maintained and will be updated</strong> according to the prices set by {username}.',
    note_1: '* You will continue to have access to your Followers content until the end of the subscription.',
    note_2: '* You will continue to have access to the premium and personalized content purchased for you'
  },

  login: {
    title: 'Acces',
    description: 'Love is in the fans',
    description_2: 'Your fans, their <strong>fantasies</strong>',
    description_3: 'Your <strong>business</strong>, your rules',
    login_field: 'User or email',
    password_field: 'Password',
    remember_password: 'Forgot password?',
    advice_1: "Find out what's going on in internet",
    advice_2: 'Follow your favorites Stars',
    advice_3: 'Become Star',
    or_you_can: 'or you can register'
  },

  signup: {
    already_registered: 'Already a member?',
    secure_net: 'Secure connection',
    form: {
      title: 'Personal data',
      description: 'Sign up to start using Lovefans'
    },
    promotion_check: {
      title: 'Do you want to become STAR?',
      description: 'Being an STAR will open a new world of possibilities and you will be able to earn money while enjoying @:project!',
      description_1: 'Upload Premium content only for your best FANS',
      description_2: 'Sell products or create Auctions',
      description_3: 'Earn money interacting with your fans',
      next: 'Yes, become STAR',
      skip: 'No thanks. continue with signup'
    },
    social_media: {
      title: 'Connect with your Social Media',
      description: 'You will be able to share content via your Social Media Accounts'
    }
  },

  email_validation: {
    title: 'Email verification is required!',
    description: 'The email must be verified to continue using @:project. To verify the email you must follow the verification link in the welcome email sent to you.',
    description_2: 'If you have not received the email, please check your spam or bulk email folder or request another email click the button.',
    validating: 'Verifying email. Please wait...',
    validated: 'Your email is verified. Enjoy @:project!',
    validation_error: 'There was an error verifying your email. Your can request another email with a new link clicking the button. This email will have a new link to verify your email.'
  },

  remember_password: {
    title: "Find your @:project' Account",
    hint: 'We will send you and email to change your password',
    instructions: 'Enter your email to request a new password. We will send you an email with the instructions to change the password.',
    email_sent: 'An email has been sent with the new password instructions. Follow the link in there to change the password.',
    email_sent_2: 'If you cannot find out the email, please check your spam or bulk email folder, social network emails or others.'
  },

  change_password: {
    title: 'New password',
    success_message: 'Password changed successfully! Enter in @:project with your new credentials:',
    error_message: 'Ops! There was an error changing the password. Your can request another password change and a new email will be sent to your account.'
  },

  admin: {
    profile: 'See Profile',
    optional: {
      displayName: '(Optional) Display name instead of your username.'
    },
    users: {
      actions: 'Selected actions',
      role_filter: 'Role: all',
      check_pending: 'Pending promotions',
      only_scat: 'Only Scat profiles',
      username_email: 'Username or email',
      country_city: 'Country or city',
      promotion: {
        review: 'Check',
        document_info: 'ID Document',
        photo_verification: 'Verification pics',
        verify_document: 'Accept pic',
        reject_detail: 'Why is rejected?',
        reject_detail_mandatory: 'This field is mandatory if pictures are not accepted',
        reject_detail_placeholder: 'ie. the pictures are not visibles or the info does not match'
      }
    }
  },

  favorites: {
    purchased_posts: 'Purchased publications',
    favorites_posts: 'Saved publications',
    no_favorites: 'There are not saved content yet.',
    no_purchased: 'There are not purchased content yet'
  },

  videotube: {
    recent: 'Recently added',
    trending: 'Trending',
    by_followings: 'Last added by your interest',
    tutorials: {
      chat: 'Messages',
      lives: 'Lives',
      referrals: 'Referrals'
    }
  },

  market: {
    sales: 'Sales',
    auctions: 'Auctions',
    recent: 'Recently added',
    sections: {
    }
  },

  publication: {
    create_post: 'Make your first post!',
    select_type: 'What do you wanna publish?',
    type_video: 'Video',
    type_image: 'Image',
    type_post: 'Post',
    type_product: 'Product',
    type_conference: 'Live',
    new_image_post: 'New image post',
    new_video_post: 'New video post',
    new_product_post: 'New product',
    new_conference_post: 'New Live',
    promo: 'Monthly challenge',
    promotional: 'This publication is marked to participate in the promotion'
  },

  videos: {
    views_label: 'Views',
    likes_label: 'Likes',
    duration_label: 'Duration'
  },

  posts: {
    recommended: 'Recommended content',
    programmed: 'Programmed for: ',
    search: 'Posts containing \'{query}\'',
    share_text: 'Hey! Check this publication',
    reposted_by: '{name} has reposted',
    draft_hint: 'We are optimizing this post for a better user experience. It will be available in a moment',
    draft_hint_2: 'You may leave this window and keep using @:project if you wish.',
    draft_continue: 'Continue to @:project',
    card_visibility: {
      open: 'Public',
      premium: 'Premium: {amount}',
      followers: 'My fans',
      exclusive: 'Exclusive'
    },
    card_overlay: {
      buy_action: 'Buy for {price}',
      subscribe_action: 'Subscribe',
      open_description: 'Signup for free to view this post',
      premium_description: 'to view this post',
      followers_description: "to view this STAR's content",
      exclusive_description: 'to view this exclusive content for you',
      private_description: 'This post is private',
      followers_text_1: 'Full video for Fans',
      followers_text_2: 'Be my Fan and gain access to all my videos and contact with me for only',
      premium_text_1: 'Buy this video for only',
      signup_text_1: 'Free signup {time}',
      signup_text_2: 'And watch full video in HD',
      free_signup_text_1: '{free} signup in less than 1 minute',
      free_signup_text_2: '!Watch all videos from @{username} and talk to her',
      signup_time: 'in less than 1 minute',
      free: 'free'

    },
    form: {
      title: 'Title',
      type: 'Premium',
      price: 'Price',
      content: 'Description',
      upload_file_text: 'Select or drop some files',
      visibility_message: 'Visibility',
      all_fans: 'My fans',
      all_followers: 'My followers',
      type_description: {
        open: 'Any Loverfan member can watch this post',
        followers: 'Only your subscribers can watch this post',
        premium: 'Only members who acquired this post can watch it',
        exclusive: 'Only the chosen member can acquire this post'
      },
      teaser: 'Add teaser',
      edit_teaser: 'Edit teaser',
      teaser_hint: '(Optional) Teaser to show as a preview for paid/fans videos. If not teaser is added then 3 seconds for this video will be chose.',
      edit_teaser_hint: '(Optional) You can change the teaser of this publication. If you dont do anything, previous teaser will be kept.'
    },
    edit: {
      title: 'Edit post',
      visibility_hint: 'Only public or fans posts can change their visibility'
    }
  },

  products: {
    description: 'Description',
    update_delivery_info: 'Add delivery info',
    guarantee_detail: 'Guarantee detail',
    no_more_guarantee: 'There are no additional guarantees in this product.',
    selling_engagement: 'Do you want to sell a product?',
    current_bidder_user: 'by @{username}',
    auction_winner_info: 'You have won this auction',
    buy_product: {
      sale: 'Buy',
      auction: 'Bid'
    },
    auction: {
      not_enough_amount: 'The amount must be greater than current bid',
      finished: 'This bid has finished',
      finished_auction: 'Auction finished',
      current_bid: 'Current bid:',
      winner_bid: 'Winner bid:',
      bid_count: 'no bids | one bid | {count} bids',
      duration_hint: 'Days to finish',
      no_winner: 'No one has bid'
    },
    sale: {
      acquired: 'This product has already been sold',
      user_acquired: 'You have purchased this product'
    },
    delivery: {
      detail: 'Delivery detail',
      sent: '{name} has already sent you this product',
      not_sent: '{name} hasn\'t sent you this product yet',
      not_completed: '{name} hasn\'t completed delivery details yet',
      mark_sent: 'Mark as sent',
      description: {
        digital: 'Digital content. We will send you an email with instructions to access this content.',
        packet: 'Physical delivery, this STAR will send you a package. Shipping costs are included with the price.'
      },
      shipment: {
        title: '{name} has acquired this product.',
        sent_to: 'Delivery details',
        email: 'Email: {email}',
        name: 'Name: {name}',
        country: 'Country: {country}',
        province: 'Province: {province}',
        city: 'City: {city}',
        postalCode: 'Postal/ZIP Code: {postalCode}',
        address: 'Address: {address}',
        sent: 'Already sent'
      }
    },
    pending: {
      title: 'PENDING VALIDATE'
    },
    validate: {
      title: 'REVIEWED'
    }
  },

  statistics: {
    nodata: 'Not enough data',
    stats: {
      creator: 'Top Creator',
      earner: 'Top Earner',
      loved: 'Top Loved',
      followed: 'Top Followed'
    },
    ranking: {
      title: 'Funds and actions',
      position: 'You\'re at',
      top: 'not enough data | top {position} % | top {position} %',
      users: {
        title: 'User Ranking',
        user: 'User',
        views: 'Views',
        tips: 'Tips',
        subscriber: 'Subscriber'
      },
      posts: {
        title: 'My Post Ranking',
        tabTitle: 'Title',
        date: 'Date',
        views: 'Views',
        likes: 'Likes',
        repost: 'Repost',
        content: 'Content',
        type: 'Type',
        visibility: 'Visibility',
        deposit: 'Deposit'
      }
    },
    help: {
      creator: 'Ranking of those who publish the most content',
      earner: 'Ranking of those who convert with their users',
      loved: 'Ranking of the most loved',
      followed: 'Ranking of the most followed'
    }
  },

  comments: {
    title: 'Comments'
  },

  conferences: {
    total: 'Total',
    private_hint: 'This Live is private and you cannot watch it.',
    exclusive_hint: 'This Live is private for you. Price is {amount}/min and will be charged only active minutes',
    public_hint: 'This Live is public and any user can join.',
    premium_hint: 'Live\'s price is {amount}/min and it will be charged only when the Star is online.',
    video_check: 'Checking camera availability',
    video_success: 'Your camera is available.',
    video_error: 'We couldn\'t connect with the camera. Keep it in mind if you are going Live with this device.',
    video_error_hint: 'We couldn\'t connect with the camera. If you denied camera access then you need to reload the page or change the page settings to allow us accessing the camera.',
    no_webcam_access: 'We couldn\'t connect with the camera. If you are on iPhone or iPad, you will need to use Safari since no other browers is allowed to use the camera.',
    connection_error: 'There was a problem connecting this Live. Please try again.',
    waiting_star: 'The Star has not connected yet. The session will start as soon as she connects. You will not be charged until then.',
    finished_message: 'This Live is already finished',
    tip_given: '@{user} sent you {amount} tip',
    free_fans: '{username}\'s subscribers can join for free',
    news_message: 'We just published Lives. It is at Beta stage and for this period, no fees will be applied to Lives charges',
    minute_price_label: '{amount}/min',
    change_conference: 'Change prefs',
    new_conference: {
      title: 'This Star has started other Live',
      description: 'This Live has finished because of the Star has started a new one with different conditions.'
    },
    form: {
      already_created: 'You already have one Live online. If you publish a new one, previous Live will be closed'
    },
    empty_wallet: {
      title: 'Not enough funds',
      description: 'You doen\'t have enough funds. You can reload your wallet here to join this Star\'s live.'
    }
  },

  users: {
    not_found: 'This profile does not exist',
    blocked_description: 'This profile has been blocked for violating @:project T&C',
    search_user: 'Search user...',
    search: 'Search Stars with \'{query}\'',
    published: 'Publications',
    following: 'Following',
    subscribed: 'Subscribed',
    subscribe: '{price}/month',
    profile: {
      edit: 'Edit profile',
      upload_banner: 'New banner',
      upload_avatar: 'New avatar',
      starMode: 'Star View',
      fanMode: 'Fan View',
      scatbook: 'Check out my Scatbook profile',
      tabs: {
        timeline: 'Timeline',
        mosaic: 'Mosaic',
        photo: 'Photo',
        video: 'Video',
        content: {
          open: {
            title: 'FREE: {post}',
            tooltip: '{post} Free Content'
          },
          followers: {
            title: 'FANS: {post}',
            tooltip: '{post} Followers\' content'
          },
          premium: {
            title: 'PREMIUM: {post}',
            tooltip: '{post} Pay content'
          }
        }
      }
    },
    discovery: {
      empty_title: 'There are a lot of Stars waiting for you!',
      title: 'Many more Stars are waiting for you!',
      empty_description: 'You are not seeing any content from your favorites Stars yet. Let us help you to discover some Stars for you to love',
      description: 'Wanna see more? Let us help you to find out more Stars for you to love'
    }
  },

  blocked_users: {
    title: 'Blocked users',
    description: 'These users cannot see your profile, your content or lives neither they wont be able to chat with you.',
    no_users: 'You haven\'t blocked any user yet',
    remaining_blocked_count: 'and {count} more',
    block_user: 'Block user',
    dialog: {
      question: 'Do you really want to block @{username}?',
      description: 'If you do so, he/she wont have access* to any of your content**, you wont be able to chat with you through @:project***.',
      description_1: '*Except subscribers. The access will be blocked at the moment subscription ends',
      description_2: '**User will still have access to the content he/she has purchased',
      description_3: '***Henceforth, this user wont be able to chat with you'
    }
  },

  subscriptions: {
    canceled: 'Canceled',
    subscribed: 'Subscribed',
    subscription_config: 'Configure subscriptions prices',
    discount: 'Discount',
    monthly_price: 'Monthly price',
    offer_packages: 'Offers',
    offer_packages_hint: 'Discount must be in range 1-70%',
    vat_explain: 'plus {price} VAT/Expenses',
    min_price_error: 'The total price cannot be lower than {price}',
    unique: 'PAYING {months} MONTH FOR {price}',
    uniques: 'PAYING {months} MONTHS FOR {price}',
    form: {
      title: 'Subscription',
      description: 'This is the modal with the payment form',
      cancel_description: 'We are going to cancel this subscription. Are your sure?'
    },
    discounts: {
      month: '1st month',
      trimester: '3 months',
      semester: '6 months',
      annual: '12 months',
      enabled_month: '1st month: {price}',
      enabled_trimester: '3 months: {price}',
      enabled_semester: '6 months: {price}',
      enabled_annual: '12 months: {price}',
      normal_monthly_price: 'Subsription monthly price without discount would be {price}',
      hints: {
        month: 'Monthly subscription discount. The first month the user pays discounted price, following months he pays the base price. This will only apply if the user has not been previously subscribed',
        trimester: 'Discount for 3 months. Payments will be done every 3 months',
        semester: 'Discount for 6 months. Payments will be done every 6 months',
        annual: 'Discount for 12 months. Payments will be done every 12 months'
      }
    },
    descriptions: {
      month: '<strong>1 Month</strong> Subscription | First month | First month',
      trimester: '<strong>3 months</strong> Subscription',
      semester: '<strong>6 months</strong> Subscription',
      annual: '<strong>12 months</strong> Subscription',
      hints: {
        month: 'After first month, price will be {price}/month'
      },
      unique: '<br>One-time payment of <strong>{price}</strong>*',
      offer: '<br><strong>{offer}%</strong> discount<br>After the first month <strong>{price}</strong>*'
    },
    descriptions_2: {
      make_me_fan: 'Subscribe as VIP Fan',
      monthly_payment: '{amount} per month  | {amount} per month  | {amount} per month ({months} months)',
      unique_payment: 'payment of {amount}'
    },
    beFan: `BE MY FAN AND GAIN ACCESS TO ALL MY VIDEOS AND DIRECT CHAT WITH ME FOR: `
  },

  avatar: {
    record_disabled: 'Your device is incompatible with recording the video. We are improving this section to make it avaialbe to all devices. We are sorry for the inconveniencies.',
    beta: {
      photo: {
        message_1: 'Welcome to the <strong> Beta </strong> of the <strong> Avatar </strong> of @:project "<strong> Lovely </strong>"',
        message_2: 'You can <strong> customize </strong> and place it with the <strong> expression </strong> that you want, to <strong> create a banner </strong> with text, which allows you to invite your fans from other social networks to <strong> follow your profile </strong> of @:project',
        message_3: 'We recommend that if you are going to use a <strong> mobile device </strong> you do it in <strong> vertical position </strong> for a better experience',
        message_4: 'Have fun!'
      },
      video: {
        message_1: 'Welcome to the <strong> Beta </strong> of the <strong> Avatar </strong> of @:project "<strong> Lovely </strong>"',
        message_2: 'You can <strong> personalize it </strong> and create a <strong> video with your voice </strong> to invite your fans from other social networks to <strong> follow your profile </strong> of @:project',
        message_3: 'To use the <strong> video functionality </strong>, you must <strong> allow </strong> access to the camera and microphone of your device',
        message_4: '<strong> not wearing glasses </strong>, having a <strong> clear forehead </strong> and <strong> good lighting </strong> will help Lovely\'s Artificial Intelligence to better follow your expressions',
        message_5: 'Likewise, if you are going to use a <strong> mobile device </strong>, we recommend that you do it in <strong> vertical position </strong> for a better experience.',
        message_6: 'Have fun!'
      },
      accept: 'Ok, I understand'
    },
    comment: 'Hello, \nmy name is \n\'Lovely\',your \nVirtual Avatar\nYou can \ncustomize me!',
    content: 'What type of content do you want to generate with your avatar?',
    photo: 'Photo',
    photo_content: 'Create your avatar, place it with the expression you want, write a personalized text and share it on your networks.',
    video: 'Video',
    video_content: 'Create your avatar, and use your webcam, to animate it and record an audio with your voice. <br> Surprise your fans.',
    placeholder: 'Max 92 Characters',
    skintone: 'Skintone',
    eyeColour: 'Eye Colour',
    hair: 'Hair',
    hairColour: 'Hair Colour',
    sections: {
      mouth: {
        title: 'Mouth',
        smile: 'Smile',
        disgusted: 'Disgusted',
        open: 'Open',
        round: 'Round'
      },
      eyes: {
        title: 'Eyes/Eyebrows',
        right_eyebrow: 'Right Eyebrow',
        left_eyebrow: 'Left Eyebrow',
        right_eye: 'Right Eye',
        left_eye: 'Left Eye'
      },
      posrot: {
        title: 'Position/Rotation',
        position: 'Position',
        rotation: 'Rotation'
      }
    }
  },

  payments: {
    table_title: 'Pagos',
    email_verification_needed: 'It is mandatory to have a verified email to purchase',
    go_to_settings: 'Go to account settings',
    paying: 'Making the payment. Please wait.',
    successful: 'Successful payment',
    failed: 'Error in payment',
    chose_payment_type: 'Chose the payment type',
    credit_not_enought_amount: 'The minimum amount to pay with Credit Card is {amount}',
    wallet_not_enought_amount: 'You don\'t have enought funds in your wallet',
    include_vat: 'Its free! | Buy for {price} | Buy for {price} (price + VAT/Ex.)',
    last_payments: 'Last payments',
    buyer_name: 'Billing name',
    title: {
      post: 'Acquire {username}\'s publication',
      subscription: '{username}\'s Subscription',
      product: 'Acquire {username}\'s product',
      wallet: 'Reload wallet'
    },
    cascade: {
      description: 'There was an error with the payment and we have not charged your credit card for this purchase. Please, try again.'
    },
    posts: {
      engagment_1: 'Complete access to this post',
      engagment_2: 'Comment and chat',
      engagment_3: 'Save on your bookmarks',
      success_message_1: 'Congratulations! The payment was successful and you have full access to post made by {username}.',
      success_message_2: 'Remember you can comment and interact with this STAR aside from watch the publication. This content will be alwayas available at your profile or in your Favorites section.',
      failed_message: 'We have not charged your credit card for this publication.'
    },
    products: {
      success_message_1: 'Congratulations! The payment was successful and you have acquired the product {name} from {username}.',
      success_message_2: 'Please, go to the product\'s page and tell us where the delivery should be done. We will notify you when {username} sends the product.',
      failed_message: 'We have not charged your credit card for this product.'
    },
    subscriptions: {
      engagment_1: 'Access to membership content',
      engagment_2: 'Chatting with this user',
      engagment_3: 'Cancel the subscription whenever you want',
      success_message_1: 'Congratulations! The payment was successful and you are subscribed to {username}\'s channel.',
      success_message_2: 'You have access to all his membership content now. You don\'t need to worry anymore, the subscription is automatically renewed so you\'ll will this Star\'s content whenever you want. You can cancel this subscription whenever you want.',
      failed_message: 'We have not charged your credit card for this subscription'
    },
    wallets: {
      success_message_1: 'The payment was successful and you have the money in your wallet now.',
      success_message_2: 'Your wallet amount is {amount}',
      failed_message: 'We have not charged your credit card for this reload.'
    },
    types: {
      credit: 'Credit card',
      wallet: 'Wallet'
    },
    checkout: {
      subscription_line_title: 'Subscription for {username}',
      subscription_line_description: 'nothing | for 1 month | for {count} months'
    },
    errors: {
      695: 'There was an issue with your payment, some data was not correct. Check it and try again.'
    }
  },

  timeline: {
    no_posts: 'There are no post in your timeline. Use the search engine to find out Stars.',
    engagment: 'Explore what\'s happening in @:project:',
    include_recommendations: 'Include recommended'
  },

  messages: {
    title: 'Messages',
    users: 'Users',
    allow_chat: 'Allow messages',
    new_message: 'New message',
    search: 'Search in messages',
    placeholder: 'Write here your message',
    send_message_to: 'Sends message to...',
    cannot_create: 'You can chat only with subscribed Stars',
    must_be_approved: 'You must request permission for sending messages to this user',
    pending_approved: 'We are waiting for @{username} to accept your request',
    request_permission: 'Send request',
    pending_approval: 'Pending approval',
    tabs: {
      messages: 'Messages',
      reports: 'Reports'
    },
    status: {
      blocked: 'Chat Blocked',
      blocked_hint: 'This user cannot send you messages',
      approved: 'Chat Approved',
      approved_hint: 'This user can send you as messages as he/she wants',
      premium: 'Paid Chat',
      premium_hint: 'This user will be charged {price}/message '
    },
    premium: {
      title: 'Message price',
      description_1: 'Pricing setup for this chat.',
      description_2: 'We will charge the price you chose for every message this user sends you. The amount will be added to your wallet automatically.',
      warning_1: 'This Star has marked the chat as Paid Chat.',
      warning_2: 'The price will be {price}/message you send. This amount will be charged to your wallet automatically.'
    }
  },

  notifications: {
    title: 'Notifications',
    users: '<strong>{usernames}</strong> | <strong>{usernames} y {more}</strong>',
    new_trial: {
      desktop: 'No trial claimed | <strong>{usernames}</strong> has claimed a trial | <strong>{usernames} and {more}</strong> have claimed trials',
      mobile: 'No trial claimed | Trial claimed | Trials claimed'
    },
    new_subscription: {
      desktop: 'No one is subscribed | <strong>{usernames}</strong> has subscribed to your channel | <strong>{usernames} and {more}</strong> have subscribed to your channel',
      mobile: 'No one is subscribed | New Subscriber | New Subscribers to your channel'
    },
    new_follower: {
      desktop: 'No one follows you | <strong>{usernames}</strong> has started to follow your channel | <strong>{usernames} and {more}</strong> have started to follow your channel',
      mobile: 'No one follows you | New Follower | New Followers'
    },
    post_liked: {
      desktop: 'No one likes it | <strong>{usernames}</strong> has liked your post | <strong>{usernames} and {more}</strong> have liked your post',
      mobile: 'No one likes it | Liked your Post | Liked your Post'
    },
    post_saved: {
      desktop: 'No one saves it | <strong>{usernames}</strong> has saved your post| <strong>{usernames} and {more}</strong> have saved your post',
      mobile: 'No one saves it | Post saved | Post saved'
    },
    post_shared: {
      desktop: 'No one shares it| <strong>{usernames}</strong> has shared your post | <strong>{usernames} and {more}</strong> have shared your post',
      mobile: 'Shared your post | Shared your Post'
    },
    new_comment: {
      desktop: 'No one commented it | <strong>{usernames}</strong> has comment your post | <strong>{usernames} and {more}</strong> have comment your post',
      mobile: 'New Comment | New comments'
    },
    tip: {
      desktop: '<strong>{usernames}</strong> has sent your a tip for <strong>{amount}</strong>',
      mobile: 'Has sent your a tip for <strong>{amount}</strong>'
    },
    post_published: {
      desktop: '<strong>{usernames}</strong> has publish a new post',
      mobile: 'New post'
    },
    product_published: {
      desktop: '<strong>{usernames}</strong> has put on sale a new product',
      mobile: 'New product'
    },
    post_acquired: {
      desktop: 'No one acquired it | <strong>{usernames}</strong> has acquired your post | <strong>{usernames} and {more}</strong> have acquired your post',
      mobile: 'Has acquired your post | have acquired your post'
    },
    product_acquired: {
      desktop: '<strong>{usernames}</strong> has acquired your product',
      mobile: 'Has acquired your Product'
    },
    product_bidded: {
      desktop: 'No none bidded for it | <strong>{usernames}</strong> has bidded for your product | <strong>{usernames} and {more}</strong> have bidded for your product',
      mobile: 'No none bidded for it | Has bidded for your product | Have bidded for your product'
    },
    product_auction_end: {
      desktop: 'The auction has finished without bids',
      mobile: 'The auction has finished without bids'
    },
    product_auction_winner: {
      desktop: 'The auction has finished and <strong>{usernames}</strong> has won it',
      mobile: 'Has won the auction'
    },
    comment_mention: {
      desktop: '<strong>{usernames}</strong> has mentioned you on a comment | <strong>{usernames}</strong> have mentioned you on a comment',
      mobile: 'Has mentioned you on a comment | Have mentioned you on a comment'
    },
    post_mention: {
      desktop: '<strong>{usernames}</strong> has mentioned you on a post | <strong>{usernames}</strong> has mentioned you on a post',
      mobile: 'Has mentioned you on a post | Have mentioned you on a post'
    }
  },

  user_profile: {
    edit: 'Edit profile',
    subscriptions_count: '0 subscriptions | 1 subscription | {count} subscriptions',
    followers_count: '0 followers | 1 follower | {count} followers',
    followings_count: '{count} following',
    post_count: '{count} post',
    premium_count: '{count} Premium',
    subscribers_count: 'No fans | 1 fan | {count} fans',
    sections: {
      publications: 'Publications',
      fans: 'My fans',
      followers: 'My followers',
      following: 'Following',
      market: 'Shop',
      subscriptions: 'My subscriptions',
      recommendations: 'According to your preferences',
      purchases: 'My purchases'
    },
    publications: {
      promote: {
        title: 'Become STAR!',
        button: 'Become Star'
      }
    }
  },

  resources: {
    banner: 'Banner',
    new_banner: 'New Banner',
    banner_promo: 'Banner 10000 @:project',
    header_promo: 'Header 10000 @:project',
    header_black_friday: 'Header Black Friday',
    banner_black_friday: 'Banner Black Friday',
    header_christmas: 'Header Christmas',
    banner_christmas: 'Banner Christmas',
    twitter: {
      comment: 'If you\'re looking for something unusual, this is your place, visit my extreme profile at {link} and enjoy with me.'
    },
    download: {
      title: 'Downloads',
      watermark_flies: {
        title: 'Watermarks/flies',
        watermark: 'WaterMark',
        flies: 'Flies',
        help: {
          watermark: 'Add a watermark to your content and protect it from unauthorized use.\nYou can add the watermark directly to the @:project platform\nor you can download it to add it to your preferred graphic editing software.',
          fly: 'Add a fly to your content and protect it from unauthorized use.\nYou can add the fly directly to the @:project platform\nor you can download it to add it to your preferred graphic editing software.'
        }
      },
      logo: {
        title: 'Logo',
        help: 'Download the @:project logo, to be able to use it in the material you generate.',
        horizontal: 'Horizontal Logo',
        vertical: 'Vertical Logo'
      },
      video: {
        title: 'Videos',
        help: 'Download the videos with the animation of the @:project logo to be able to use it in your own videos, as a header, as a closure or as both.'
      }
    },
    avatar: 'Lovely'
  },

  recommendation: {
    stars: 'Stars',
    market: 'Market'
  },

  promotion: {
    title: 'Identity verification',
    description: 'You are just one step away to become STAR! To continue with the process you only need to choose your subscription\'s price and give us some information to verify your age. That is, you don\'t need anything more!',
    document_data: 'Fill these fields with the info of your ID Document',
    subscription_price: 'The monthly price your FANs will pay to see your member\'s content.',
    dni: 'ID Document',
    dni_description: 'Upload a photo of each side of your ID Document',
    dni_invalid: 'Invalid ID Document',
    verification: 'Verification photo',
    verification_description: 'Take a photo with your ID Document. You and the document must be visible.',
    verification_invalid: 'Invalid photo',
    pictures: 'Photos',
    chose_price_placeholder: 'Chose price...',
    state: {
      pending: 'We are processing request to become STAR. We will notify you when it is completed.'
    },
    engagment: {
      title: 'Do you want to be an Star?',
      description: 'Descripción o no',
      engagment_1: 'Look for every type of content and products',
      engagment_2: 'Follow you fav Stars',
      engagment_3: 'Become the new @:project Star and earn money interacting with your fans',
      become_influencer: 'Yes, I wanna become Star',
      continue: 'No thank you, go to the site.'
    },
    october: {
      date: 'October 5',
      rules: 'changes the rules of the game',
      banner_1: 'Stay tuned',
      banner_2: 'on October 5, 2020',
      banner_3: '<b>@:project</b> is going to give a <b>bombshell</b>,',
      beStar: 'Become Star for free, to learn more'
    }

  },

  settings: {
    tabs: {
      account: 'Account',
      personal: 'Personal data',
      promotion: 'Service',
      payments: 'Account Settings',
      notifications: 'Notifications',
      privacy: 'Privacy'
    },
    account: {
      delete_account: 'Delete account',
      delete_account_hint: 'For security check, first write your @:project username',
      email_validated_hint: 'The email is verified',
      email_not_validated_hint: 'It is mandatory verify your email address to have full access',
      password: 'Password',
      change_password: 'Change password',
      delete: {
        warning: 'Important!',
        description: 'We are going to delete your account. <strong>This action cannot be undone and all your data is going to be removed</strong>. The actions to be carried out are detailed below:',
        personal_data: 'Deleting your personal data.',
        wallet: 'You wont be able to recover your wallet funds.',
        subscriptions: 'Canceling your active subscriptions (no refund).',
        social_media: 'Deleting all social media conection like Twitter, etc.',
        loverfans_actions: 'Deleting all @:project actions: Likes, saved posts, messajes, notifications, etc.',
        subscribers: 'If you have any active subscriber, they wont be renew and your account will be deleted after the subscription period ends.',
        posts: 'Deleting your posts and comments if you have them.',
        premium_posts: 'Users who bought premium posts will still have access to them.'
      }
    },
    personal: {
      personal_data: 'Personal data',
      address: 'Address data',
      contact: 'Contact Information'
    },
    payments: {
      legal_doc: 'Legal docs',
      epayment: 'ePayments data',
      bank_data: {
        datas: 'Bank Data',
        description: 'Transfer to your bank account'
      },
      paypal: {
        datas: 'Paypal',
        id: 'Paypal ID'
      },
      western: {
        datas: 'Western Union',
        id: 'Western Union ID'
      },
      subscription_price: 'Subscription price',
      subscription_price_description: 'Price your fans will pay to subscribe your private content',
      pending_activation: 'Star profile is not activated yet.',
      methods: 'Payment methods'
    },
    twitter: {
      signup: 'Twitter signup finished',
      description: 'We have chosen some fields for you but you have the chance to change them now. Remember that you need your email verified to have complete access to @:project so we recommend you to setup one and verify it.'
    }
  },

  faq: {
    title: 'FAQ: Frequent asked questions',
    loverfans: {
      tittle: 'Why @:project?',
      question_1: 'What is @:project?',
      response_1: 'Is the new and worldwide Fans Web Platform, that allows influencers to manage their whole Fans Business in an unique site.',
      question_2: 'All in a single Action',
      response_2: '@:project allows you to create with a simple action from generate content for your subscribers, upload paid content, create custom content for a special fan, to put on sale a real or digital garment or merchandising. Additionally, it includes a differential tool for which you can auction among all your fans.',
      question_3: 'How to signup a STAR profile?',
      response_3: 'Signup in @:project, chose your username and display name. Select "I want to become STAR" and fill the personal data and your ID verification. Our admins will check it and will promote you as soon as possible. Register via Twitter and keep your fans updated to your @:project feed. Once you have filled your bank or card (wallet) information you’ll be ready to manage your influencer business in the unique global platform for fans.',
      question_4: 'Why be a FAN in @:project?',
      response_4: 'Because in @:project we’re commited in bringing you the most popular STARS, uarantees your privacy and maximum security. It includes a secure payment platform and a technological environment, so that all your content is always available at the highest possible quality and speed.'
    },

    stars: {
      tittle: 'Most Popular STARS FAQs',
      question_1: 'What amount do I choose in my subscription?',
      response_1: 'Our commitment to your FANS is to bring the most popular STARS, and our commitment to the STARS, is to bring the most faithful FANS, we eliminate the transactional risk, assuming all of it, so you can choose the subscription that is most associated with your STAR profile. Try us and you will see that quality starts with you and stays with your FANS.',
      question_2: 'What does Visibility mean during the content publication?',
      response_2_1: 'Open: The content you’re uploading could be seen by any member of @:project Community. Use it, to allow our community knows more about you.',
      response_2_2: 'My fans: Content only for those FANS with an active subscription.',
      response_2_3: 'Premium: Are selected content, done with Love and work, theses contents are not included in the subscription model, so anybody who wants to see them have to pay.',
      response_2_4: 'User: Custom content, you have prepare for a special FAN, once the content is ready the FAN proceed to its payment, and it that moment the content will be ready for your FAN. You can use this modality for a customize greetings, a hight quality video, you make their fantasies real, you fix the price and you define your rules.',
      question_3: 'How do I put a product on sale?',
      response_3: 'It is very easy, from the same publishing action, you first select product then you choose between direct sale or auction mode.',
      question_4: 'What is the difference between sale and auction?',
      response_4: `A product for sale, can be something both physical (garment, object that you use or merchandising material) or digital (a dedicated photo, a personalized poster, etc.), you put a price, and your faster FAN will buy it and you will send to him in the conditions that you agree.
                While an auction is an innovative tool, we offer you, you propose the starting price and the duration in days of the auction and at the end of the period the FAN that has bid the most for your product, will be the winner, We will confirm when the payment has been made successfully, and you can send the product as you have agreed.`,
      question_5: 'Should I include the shipping price in the amount?',
      response_5: 'The amount for which you bid will be the total amount that the FAN will pay, so you will have to include all the costs including, You can explain your conditions in the product description.',
      question_6: 'From where can create a new direct?',
      response_6: 'Everything from the publish button or "+", gives live option, check the operation of the camera, select the visibility you want for your new live connection, just have to start it from your new live room.',
      question_7: 'What are the different visibility options?',
      response_7_1: 'Public: Visible for any user registered in @:project, use it to make yourself know or call other @:project\' users.',
      response_7_2: 'Premium: Set a price per minute for connected users, if you aren\'t connected, users won\'t be charged until you start the show, they can pause at your convenience. Additionally you have an option to activate if this modality is free for your fans (for a fee).',
      response_7_3: 'Private: In this mode you can indicate a minute price and the user with whom you want to share that moment.',
      question_8: 'How many live shows can be open?',
      response_8: 'You can only have one room either live or scheduled.',
      question_9: 'Can I use my camera in some other application while doing live?',
      response_9: 'For security reasons, you can only use the camera while you @:site direct.',
      question_10: 'Can I have my profile open on some other device while doing direct?',
      response_10: 'Yes, you can, but you cannot be in the direct room. It\'ll automatically disconnect. You can change devices, it will automatically remove you from the session on the previous device and you will have to start DIRECT from the room again.',
      question_11: 'Does it work on all devices and browsers?',
      response_11: 'It works on all browsers and devices, like mobiles, tablets or landlines, but Apple blocks the use of the camera in mobile operating systems other than Safari. For whether you have an iPhone or an iPad, use Safari to ensure proper operation.',
      question_12: 'What is "content type" filtering and where can I complete it so my fans can find me better?',
      response_12_1: 'Our @:project\' community is growing every day, we want to help your fans find you more easily and to show them the content they like more precisely.',
      response_12_2: 'If you go to "SETTINGS/LOVERSTAR/Types of Content" and click to modify, you can change your profile. The categories are:',
      response_12_3: 'What are you? It is a unique category, select what best defines you.',
      response_12_4: 'How do you categorize your content? It is multiple selection, if you create different types of content select those that represent you the most.',
      response_12_5: 'As you look? Identify the age range that represents you the most.'
    },

    fans: {
      tittle: 'Most Popular FANS FAQs',
      question_1: 'Why my final statement is different that the price in @:project?',
      response_1: 'At the end amount we add your country taxes, in that way we could guarantee all your consumer rights, all under the supervision of our legal department.',
      question_2: 'What information Will be displayed in my card statement?',
      response_2: 'Your card statement will show that the payment was made to Cgbilling*artemisa 3',
      question_3: 'How to cancel a subscription?',
      response_3: 'You can cancel your subscription at any time, from your list of active subscriptions in your profile, you will see a button with the text "Subscribed", when you hover over it, it will change to "CANCEL". Once clicked, you will see a confirmation pop-up and warning you of what the cancellation will entail.',
      question_4: 'I don’t like the STAR content',
      response_4: 'All purchases made on @:project are final and non-refundable. Bur if you want, you cancel your subscription at any time.',
      question_5: 'What can I pay by wallet and what can I pay by card?',
      response_5_1: 'Only with the credit card you can make subscription payments, since they are periodic purchases, they cannot depend on whether there is a balance in the wallet or not.',
      response_5_2: 'On the other hand, from the wallet, you can make any type of transaction except subscription payments.',
      response_5_3: 'Only from wallet, you can make payments of amounts less than € 10, with the exception of subscriptions.',
      response_5_4: 'For example, a tip to a STAR, the payment of a content or a product that has an amount less than € 10.'
    },

    lives: {
      stars: {
        tittle: 'Most Popular Lives FAQs between STARs',
        question_1: 'What are live shows?',
        response_1_1: '<p>It\'s a new way to view and chat with your <strong>FANS</strong> in real time.</p>',
        question_2: 'When and how do I charge for live shows?',
        response_2: 'In real time, you will see the income console and also an additional tip. At the end of the live show, this income will be consolidated in your wallet.',
        question_3: 'How can I go from a public room to another Premium or Private?',
        response_3: 'You have to create a new direct, all users who are in the previous room will receive a new link to the new room and they could connect directly if they accept the new conditions.',
        question_4: 'Have you tried to challenge your followers during the Live?',
        response_4: 'Challenge your followers, proposing new challenges. Ask them to hit a defined tip amount and challenge against that amount. It\'s a way to encourage the use of tips during the live show.',
        question_5: 'Importance of planning direct.',
        response_5_1: 'You will see that you\'ve an option when create a new live called, "schedule for later", this functionality is the key. Live connection is a functionality specially designed to have a closer experience with your fans and followers. Let them know when you\'ll be going to connect and communicate through your networks when you\'ll be online. This way your followers will be waiting for you.',
        response_5_2: 'When you start the live show, @:project will notify your followers via email sending them a shortcut to your new live room.'
      },
      fans: {
        tittle: 'Most Popular Lives FAQs between FANs',
        question_1: 'What are live shows?',
        response_1_1: '<p>It\'s a new way to view and chat with your favourite <strong>STARS</strong> in real time, stay tuned to notifications, follow or become a <strong>FAN</strong> of <strong>STARS</strong> that you want to be notified of when they will connect.</p>',
        response_1_2: '<p>Stay tuned for the schedule of your favourite <strong>STARS\'</strong> events, these will appear in the <strong>DIRECT</strong> section of the menu on your left.<p>',
        response_1_3: '<p>We\'ll send you an email when a <strong>STAR</strong> that you follow or you\'re a FAN is <strong>LIVE</strong>.<p>',
        question_2: 'My Favourite STAR is planning a Live, how could I know when?.',
        response_2_1: 'If your favourite Star is planning a live show, she/he should communicate via social networks when will be online.',
        response_2_2: 'When the broadcast time comes, @:project will notified you via Email with a shortcut link when the Live show start.'
      }
    },

    common: {
      tittle: 'Most Popular All Users FAQs',
      question_1: 'Why my @:project profile photo is a low quality one?',
      response_1: 'Pretty sure you\'ve registered via Twitter, Twitter send us your photo in low quality, but if you get into yor profile you can change your photo for one with better quality.',
      question_2: 'I get a warning that a word is not allowed, what are those words?',
      response_2: 'For legal and security reasons, certain words are not allowed in any content on the site, unless it is to inform what they are. Here is the list of prohibited words that we currently have enabled:',
      question_3: 'How can I delete my account?',
      response_3_1: 'Go to Settings -> Account Section, at the end, you\'ll see a dropdown menu "Delete account". On that menu, you must indicate your account name, press "Delete" button and confirm delete.',
      response_3_2: 'If you\'re a STAR and your content has been purchased, it\'ll be available to users who have purchased it, and if you\'ve fans at the end of the subscription, their subscription will be canceled and they won\'t have access to their FAN and public content, the account will be deleted with the last fan.',
      response_3_3: 'All payments and collections will be public access and anonymous as law required.'
    }
  },

  contact: {
    title: 'Contact',
    subject: 'Subject',
    message: 'Message',
    no_email: 'In order to answer you an email is required in your account settings.'
  },

  wallet: {
    balance: 'Balance',
    current_balance: 'Current balance',
    current_balance_amount: 'Wallet current balance is {amount}',
    subscriptions_balance: 'Subscriptions',
    sales_balance: 'Sales',
    tips_balance: 'Tips',
    conference_balance: 'Lives',
    chats_balance: 'Chats',
    withdrew_balance: 'Withdrew',
    reload: {
      title: 'Reload funds',
      amount: 'Chose the amount for reloading your wallet | Chose the amount for reloading your wallet ({count}% Ex.) | Chose the amount for reloading your wallet ({count}% Ex.)',
      remember: 'Remember you can reload your wallet'
    },
    events: {
      type_reload: 'Reload',
      type_fee: 'Fee',
      type_purchase: 'Purchase',
      type_sale: 'Market',
      type_subscription: 'Subscription',
      type_rebill: 'Rebill',
      type_tip: 'Tip',
      type_conference: 'Live',
      type_referral: 'Referral Fee',
      type_chat: 'Chat',
      type_bonus: 'Bonus',
      type_refund: 'Refund',
      type_scatbook_transfer: 'Scatbook transfer',
      credit_card: 'credit card',
      affiliate_user: 'SFS Revolution'
    },
    referral: {
      posts: '@{username}\'s post',
      products: '@{username}\'s product',
      subscriptions: '@{username}\'s subscription',
      tips: '@{username}\'s tip'
    },
    bonus: {
      black_friday: 'Black Friday'
    }
  },

  withdraws: {
    title: 'Withdraw funds',
    last_withdraws: 'Last withdraws',
    amount: 'Amount to withdraw',
    change_payment: 'Change payment method',
    go_to_config: 'Configure payment methods',
    no_config: 'You must configure a payment method',
    not_enough_funds: 'You have not enough funds to withdraw funds. The minimun amount is {amount}',
    payment_info: 'Payment information',
    payment_method: 'Payment method: {method}',
    fullname: 'Name: {name}',
    warning: 'The payout will become effective the last business day of the week. You wont be enable to request a new payout until the pending request is resolved.',
    method_account: 'Account: {account}',
    bank: {
      uncomplete: 'More bank data is needed. Please, finish the Bank form at payment methods to use Bank Account Transfers.',
      username: 'Owner: {name}',
      user_country: 'Country: {country}',
      country: 'Bank Country: {country}',
      currency: 'Bank Currency: {currency}',
      name: 'Bank Name: {name}',
      account: 'Account Number: {account}',
      swift: 'SWIFT: {swift}',
      routing_number: 'ABA: {aba}'
    },
    paypal: {
      id: 'Paypal ID: {paypalID}'
    },
    western_union: {
      id: 'Western Union ID: {westernId}'
    },
    options: {
      bank: 'Bank Account',
      paypal: 'Paypal',
      western: 'Western Union',
      epayment: 'ePayments'
    },
    descriptions: {
      bank: 'In the following days we will make a transfer to your configured bank account',
      epayment: 'In the following days we will make a payment to your configured eWallet'
    },
    state: {
      pending: 'You have a withdrawal {amount} on going. We will notify you when it is accomplished.'
    },
    withdraw_data: {
      title: 'Personal payout data',
      description: 'This is the personal data for payouts, .',
      uncomplete: 'Payout data is mandatory for requesting payments'
    },
    withdraw_methods: {
      title: 'Payout methods',
      no_data: 'There aren\'t any payout method added yet.',
      withdraw_data_needed: 'You need to add personal payout data before setting up a payout method',
      chose_description: 'Chose the payout method',
      types: {
        bank: 'Wire transfer',
        cosmo: 'CosmoPayments',
        e_pay_services: 'ePayService',
        paxum: 'Paxum',
        skrill: 'Skrill',
        transfer_wise: 'TransferWise',
        btc: 'Bitcoin',
        paypal: 'PayPal',
        western: 'Western Union'
      },
      bank: {
        form_description: 'Bank data',
        info: {
          description_1: 'Recommended for EU and USA',
          description_2: 'Payouts directly to your bank account',
          description_3: 'Local currency of your bank',
          description_4: 'Estimated payout arrival: between 1 and 3 days'
        }
      },
      cosmo: {
        info: {
          description_1: 'Recommended for EU, UK, CO, MX, BR, PE, CL, UY',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      e_pay_services: {
        info: {
          description_1: 'Recommended for: check your country {link}',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      paxum: {
        info: {
          description_1: 'Recommended for EU, USA and LATAM',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      paypal: {
        info: {
          description_1: 'Recommended for: world wide',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      skrill: {
        info: {
          description_1: 'Recommended for: EU, USA',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      transfer_wise: {
        info: {
          description_1: 'Recommended for: EU, USA',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      btc: {
        info: {
          description_1: 'Recommended for: world wide',
          description_2: 'Our recommended partner: {link}',
          description_3: 'Currency: BTC',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      western: {
        info: {
          description_1: 'Recommended for: LATAM',
          description_2: 'Currency: Local currency of your country',
          description_3: 'Fee: 4,90€ (it could be increased depending on the amount received)',
          description_4: 'Estimated payout arrival: Immediately',
          description_5: 'It is strictly necessary that the receptor must be the same of account holder'
        }
      }
    }
  },

  tags: {
    title: 'Info',
    content_info: 'Content tags',
    description_1: 'Hello {name}! We are asking for some info about the content you are creating. With this info, fans with similar interests will have much easier to find you.',
    description_2: 'It won\'t take too much time. Just a few clicks and it\'s done.',
    male: 'Male',
    female: 'Female',
    couple: 'Couple',
    trans: 'Transexual',
    producer: 'Producer',
    model: 'Model',
    soft: 'Soft',
    straight: 'Straight',
    swinger: 'Couples/Swinger',
    homo: 'Gay',
    Gay: 'Gay',
    lesbian: 'Lesbian',
    bdsm: 'BDSM',
    mistress: 'Mistress',
    trans_content: 'Transexual',
    extreme: 'Extreme',
    scat: 'Scat',
    teen: '18-25',
    young: '26-35',
    adult: '36-45',
    mature: '>46',
    category: {
      gender: 'What are you?',
      content: 'How you describe your contents?',
      age: 'How old are you?'
    },
    filter: {
      gender: 'Gender',
      content: 'Categories',
      age: 'Age'
    }
  },

  blocked_countries: {
    title: 'Blocked countries',
    description: 'Users from chosen countries won\'t be able to see your content nor your profile',
    no_countries: 'There are no selected countries',
    select_country: 'Block country',
    content_blocked: 'This content is blocked on your country'
  },

  tips: {
    dialog: {
      title: 'Send tip',
      hint: 'Tips are sent with wallet\'s funds only',
      amount: 'Tip',
      receiver: 'Send to:'
    }
  },

  referrals: {
    title: 'Referrals',
    url: 'Referral Url'
  },

  widgets: {
    recommendations: {
      title: 'Maybe you are interested in...',
      title_2: 'Our recommendations',
      all: 'Show all',
      product: {
        title: 'Recommended products:'
      }
    },
    lives: {
      recorded: {
        title: 'STARs who do Lives frequently'
      }
    }
  },

  twitter: {
    notifications: {
      title: 'Twitter notifications',
      subscription: 'You subscribe to an Star',
      subscriber: 'You have a new subscriber',
      follower: 'You have a new follower',
      premium_content: 'You have created a Premium post',
      other_content: 'You create other posts',
      product: 'You publish a product',
      purchased: 'You sell a product',
      auction_finished: 'An auction ends',
      lives: 'You start a Live'
    }
  },

  models: {
    validate: {
      id: 'Id',
      username: 'Username',
      date: 'Publication date',
      validation_date: 'Validation date',
      validated: 'Validated',
      details: 'Detalles'
    },
    users: {
      name: 'Stars',
      singular_name: 'Star',
      order: {
        verifiedAt: 'Latest additions',
        ranking: 'Relevance',
        username: 'Name'
      },
      showCount: {
        label: 'Show Fans and Followers Public',
        tooltip: 'Your visitors will be able to see the number of fans and followers you have on the platform publicly.<br>This will be automatically displayed if minimum fan (100) and follower (20) requirements are met.'
      }
    },
    conferences: {
      name: 'Lives',
      singular_name: 'Live'
    },
    payments: {
      name: 'Payments',
      singular_name: 'Payment'
    },
    posts: {
      name: 'Posts',
      singular_name: 'Post',
      auto_publish: 'The content will be published as soon as it\'s created since the date is in the past.'
    },
    products: {
      name: 'Products',
      singular_name: 'Product'
    },
    wallets: {
      name: 'Wallets',
      singular_name: 'Wallet'
    },
    withdraws: {
      name: 'Withdraws',
      singular_name: 'Withdraw'
    },
    roles: {
      name: 'Roles',
      admin: 'Admin',
      influencer: 'Star',
      compliance: 'Compliance',
      content_manager: 'Content Manager',
      follower: 'Follower',
      influencer_plural: 'Stars',
      follower_plural: 'Followers'
    },
    tag: {
      name: 'Tags'
    },
    user: {
      username: 'Username',
      displayName: 'Display Name',
      scatProfile: 'Scat Profile',
      displayName_hint: '(optional) Name displayed in @:project instead of your username',
      createdAt: 'Register',
      name: 'Name',
      name_hint: 'Your real name',
      surname: 'Surnames',
      birthdate: 'Birthdate',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      email: 'Email',
      email_confirmation: 'Repeat email',
      current_password: 'Current password',
      password: 'Password',
      new_password: 'New Password',
      password_confirmation: 'Repeat new password',
      address: 'Address',
      country: 'Country',
      postal_code: 'Postal code',
      province: 'Province',
      city: 'City',
      role: 'Role',
      blocked: 'Blocked',
      active: 'Active',
      description: 'Description',
      e_wallet: 'eWallet number',
      bank_account_number: 'Bank account number',
      iban_number: 'IBAN',
      swift_number: 'Bank SWIFT',
      real_name: 'Full Name',
      live_country: 'Country',
      bank_name: 'Bank name',
      bank_currency: 'Bank Currency',
      bank_country: 'Bank Country',
      select_option: 'Select an option',
      subscription_price: 'Monthly subscription price',
      subscription_fee: 'Subscription fee',
      premium_fee: 'Premium post fee',
      market_fee: 'Market fee',
      tip_fee: 'Tip fee',
      accept: 'I accept',
      acceptedTerms: 'I accept terms & conditions',
      isAdult: 'I confirm that i am 18 years or older',
      state: 'State',
      routing_number: 'Routing Number (ABA)',
      telegram: 'Telegram ID',
      twitter: 'Twitter',
      skype: 'Skype ID',
      phone_prefix: 'Intl Prefix',
      mobile: 'Mobile Phone',
      trustedProfile: 'Verified Star',
      untrustedProfile: 'Untrusted Star',
      subscribed_by: 'is your Fan',
      followed_by: 'is following you'
    },
    conference: {
      title: 'Title',
      visibility: 'Visibility',
      minute_price: '€/min',
      free_fans: 'Free fans',
      free_fans_hint: 'My fans can watch the show without paying',
      choose_starts_at: 'Starts at chosen date',
      starts_at: 'Starts date',
      starts_date: 'Date to start',
      starts_time: 'Time to start',
      only_actives: 'Emitting now',
      selected_user: 'User',
      selected_users: 'Users',
      types: {
        public: 'Public',
        premium: 'Premium',
        exclusive: 'Private'
      },
      type_description: {
        unsigned: 'Login to watch this Live',
        public: 'Any Loverfan member can watch your Live',
        premium: 'Only members who pay per minute will watch your Live',
        exclusive: 'Only the chosen member will watch your Live'
      }
    },
    influencer_request: {
      description: 'Description',
      id_document: 'ID Document',
      id_document_type: 'Type',
      id_document_number: 'Number',
      id_document_country: 'Country',
      id_document_expiration: 'Expiration',
      verification_photo: 'Verification photo',
      status: 'Status',
      detail: 'Detail',
      id_document_types: {
        passport: 'Passport',
        id_document: 'National ID Document'
      }
    },
    post: {
      content: 'Title',
      selected_user: 'User',
      selected_users: 'Users',
      visibility: 'Who can see this post',
      price: 'Price',
      publish_at: 'Schedule at',
      choose_publish_at: 'Schedule post',
      starts_date: 'Date',
      starts_time: 'Time',
      visibility_types: {
        open: 'Free',
        followers: 'My fans',
        premium: 'Premium',
        exclusive: 'Users'
      }
    },
    product: {
      name: 'Product name',
      description: 'Description',
      price: 'Price',
      auction_duration: 'Auction duration (days)',
      delivery_type: 'Delivery type',
      sale_type: 'Sale type',
      delivery_types: {
        digital: 'Digital',
        packet: 'Postal/Package'
      },
      sale_types: {
        market: 'Normal',
        sale: 'Normal',
        auction: 'Auction'
      }
    },
    payment: {
      billed_at: 'Date',
      bruto: 'Gross',
      influencer_cost: 'Influencer Cost',
      loverfans_fee: 'Loverfans fee',
      transaction_id: 'Transaction',
      payment_user_ip: 'IP',
      country: 'Country',
      user: 'User',
      receiver: 'Receiver',
      resource: 'Source',
      name: 'Name',
      card_holder: 'Card Holder',
      credit_card: 'Card',
      amount: 'Amount',
      net_amount: 'Net Amount',
      influencer_amount: 'Stars',
      total_amount: 'Total',
      vat: 'VAT',
      vat_country: 'Country VAT',
      fee: 'Fee',
      exes: 'Expenses',
      user_transaction: 'Transaction Id: {transaction}',
      user_country: 'Country: {country}',
      user_ip: 'IP: {ip}',
      types: {
        tips: 'Tip',
        subscriptions: 'Subscription',
        posts: 'Post',
        products: 'Product',
        wallets: 'Wallet',
        conferences: 'Live',
        chats: 'Chat',
        back_friday: 'Bonus (Black Friday)'
      }
    },
    comment: {
      comment: 'Comment',
      placeholder: 'Leave a comment'
    },
    withdraw: {
      date: 'Date',
      uuid: 'Id',
      type: 'Type',
      user: 'User',
      name: 'Name',
      amount: 'Amount',
      status: 'Status',
      user_email: 'Email: {email}',
      user_bank_user: 'Owner: {name}',
      user_bank_user_country: 'Country: {country}',
      user_bank_name: 'Bank: {name}',
      user_bank_country: 'Bank country: {country}',
      user_bank_currency: 'Currency: {account}',
      user_bank: 'Acc. Number: {account}',
      user_swift: 'SWIFT: {account}',
      user_paypal: 'PaypalId: {paypal}',
      user_western: 'Western: {western}',
      email: 'Email: {email}',
      full_name: 'Fullname: {name}',
      country: 'Country: {country}',
      bank_name: 'Bank: {name}',
      bank_country: 'Bank Country: {country}',
      bank_currency: 'Currency: {currency}',
      bank_account: 'Bank account: {account}',
      bank_aba: 'Routing Number (ABA): {aba}',
      bank_swift: 'SWIFT: {swift}',
      account_id: 'Account: {account}',
      payment_types: {
        bank: 'Bank',
        western: 'Western Union',
        paypal: 'PayPal'
      },
      statuses: {
        pending: 'Pending',
        processing: 'Processing',
        commited: 'Commited',
        rejected: 'Rejected',
        canceled: 'Canceled',
        accepted: 'Accepted'
      },
      trusted: {
        true: {
          title: 'Verify Trusted creator',
          text: 'You are going to verify <strong>{user}</strong> as a <strong>trusted user</strong>, this process assumes that he has passed the tests and can receive payments normally.'
        },
        false: {
          title: 'Remove Trusted Creator',
          text: 'You are going to remove the <strong>Trusted status</strong> to <strong>{user}</strong>, this process assumes that he has not passed the tests and should be verified again.'
        }
      }
    },
    withdraw_method: {
      currency: 'Currency',
      account_id: 'Account ID',
      cosmo_account: 'COSMO 9 or 10-digit SAN',
      e_pay_account: 'ePayServices wallet ID',
      paxum_account: 'Email of your Paxum\'s account',
      paypal_account: 'Paypal ID or email associated',
      skrill_account: 'Email of your Srill\'s account',
      transfer_wise_account: 'Your Transferwise account number or email associated',
      btc_account: 'Coinbase email or BTC wallet address',
      western_account: 'Western Union ID'
    },
    withdraw_bank_info: {
      bank_account: 'Bank account number',
      iban_number: 'IBAN',
      swift_number: 'Bank SWIFT',
      bank_country: 'Bank country',
      bank_name: 'Bank name',
      bank_currency: 'Bank Currency',
      routing_number: 'ABA Routing Number'
    },
    wallet: {
      date: 'Date',
      type: 'Type',
      source: 'From',
      base: 'Base price',
      amount: 'Amount',
      vat: 'VAT',
      fee: 'Fee',
      exes: 'Expenses',
      total_price: 'Total',
      total: 'Wallet Total'
    },
    referral: {
      username: 'User',
      date: 'Date',
      fee: 'Fee',
      totalFee: 'Total Fee'
    },
    ranking: {
      postsCount: 'Posts',
      earningCount: 'Earned',
      fansCount: 'Fans'
    }
  },

  types: {
    birthdate: 'Birthdate',
    currency: {
      format_hint: 'Enter the amount in this format: XXXX,YY',
      minValue: 'Default value for {name}: {quantity} (Write 0 or let blank to use default). Minimun value for {name}: {quantity}'
    },
    date: {
      format: 'MM/DD/YYYY',
      name: 'Date',
      day: 'Day',
      days: 'Days',
      month: 'Month',
      year: 'Year',
      hour: 'Hour',
      minute: 'Minute',
      hours: 'Hours',
      minutes: 'Minutes',

      invalid: 'The date is not correct'
    },
    datetime: {
      format: 'MM/DD/YYYY HH:MM:SS'
    },
    boolean: {
      true: 'Yes',
      false: 'No'
    },
    payment: {
      mail: 'Write the mail to send the payment',
      id: 'ID'
    }
  },

  labels: {
    filter_dates: 'Filter between dates',
    file_input: 'Select a file'
  },

  listview: {
    no_users_found: 'We couldn\'t find any STAR with \'{query}\'',
    no_posts_found: 'We couldn\'t find any publication with \'{query}\'',
    no_content: 'There is no content yet',
    no_posts: 'You haven\'t published anything yet',
    no_posts_visit: 'This STAR has not published any content yet',
    no_market: 'You don\'t have articles in the market yet',
    no_market_visit: 'This STAR doesn\'t have any article for sale yet',
    no_subscriptions: 'You are not subscribed to any Star yet',
    no_subscribers: 'You haven\'t got any Fan yet',
    no_conferences: 'There are no scheduled Lives at the moment',
    no_followers: 'You haven\'t got any follower yet',
    no_following: 'You are not following anybody yet',
    no_purchases: 'You haven\'t purchased any content yet',
    no_favorites: 'You haven\'t saved any content yet',
    no_follower_content: 'There are no recently added content',
    no_tutorials_content: 'There are no tutorials content',
    no_sales: 'There are no products for sale',
    no_auctions: 'There are no products for auctions',
    not_found: 'We couldn\'t find anything',
    no_notifications: 'You haven\t got notifications',
    pagination:
      {
        per_page: 'Show per page'
      }
  },

  file_uploader: {
    uploaded: 'Uploaded',
    error: 'Error uploading file',
    retry: 'Retry',
    max_images: 'one picture | {count} pictures',
    max_videos: 'one video | {count} videos',
    max_items_hint: 'You can upload {items1} | You can upload {items1} or {items2}.',
    files_size_exceded: 'The file { files } is too large.Max 3GB | The files { files } are too large.Max 3GB per file',
    max_video_size: 'Max video size is 3GB.'
  },

  snackbar: {
    email_sent_success: 'Email sent to your account\'s email',
    email_sent_failed: 'Wait some minutes before requesting another email',
    item_delete_success: '{item} was deleted successfully',
    delete_success: 'Deletion was successful',
    report_success: 'Report was successful',
    create_success: 'Created successfully',
    update_success: 'Updated successfully',
    item_create_success: '{item} was created successfully',
    publish_success: 'Publication created successfully',
    item_publish_success: '{item} was successfully published',
    clipboard_copied: 'Link copied to clipboard',
    contact_success: 'Message sent successfully',
    save_success: 'Saved succesfully',
    request_success: 'Request sent successfully',
    cannot_process_request: 'The request cannot be processed. Try again later.'
  },

  alerts: {
    product_payment_required: 'An auction finished and you have been the winner! Go to the product\'s page to make the payment and fill the delivery details.',
    product_delivery_required: 'One of the products you have acquired doesn\'t have delivery details.'
  },

  errors: {
    _default_length_field: 'chars',
    not_found: 'Ops... You haven\'t found what you are looking for',
    no_min_amount: 'The min amount is {amount}',
    required: 'This field is required',
    no_white_spaces: 'This field cannot include white spaces',
    bad_email: 'Invalid email',
    bad_password: 'The password must be at least 8 chars length, have at least a lowercase letter, a capital letter and a number',
    dont_match: 'Does not match',
    amount_too_low: 'Minimun amount is {amount}',
    not_enough_funds: 'You don\'t have enough funds in your wallet',
    min_value: 'Min value is {value}',
    max_value: 'Max value is {value}',
    no_min_length: 'At least {min} {field}',
    no_max_length: 'Cannot have more than {max} chars',
    no_min_max_length: 'The length must be between {min} and {max}',
    no_min_max_field_length: 'The length of {field} must be between {min} and {max}',
    only_alphanumerics: 'Allowed chars are letters, numbers and \'_\'',
    only_integers: 'Only integers numbers are allowed',
    already_taken_username: 'This username is already in use',
    too_many_files: 'You have selected too many files. Max. {max} files',
    discarted_too_many_files: 'Too many files selected (max {max} files). Some files has been discarted',
    files_size_exceded: 'The file {files} is too large. Max 3GB | The files {files} are too large. Max 3GB per file.',
    mime_type_not_valid: 'The file{file} does not seem an image or video.',
    banned_word: 'One or more banned words detected: {word}',
    invalidNif: 'Invalid Document Number.'
  },

  remote_errors: {
    security_exception: 'You must be logged in to continue',
    bad_credentials: 'The username/email or password are incorrect',
    not_allowed: 'You cannot do this action',
    not_found: 'Ops... You haven\'t found what you are looking for',
    invalid_field: 'The field {field} is not valid',
    already_finished: 'Already finished',
    not_enough_funds: 'You don\' have enough funds',
    no_white_spaces: 'White spaces are not allowed',
    does_not_match: 'Does not match',
    already_resolved: 'This withdraw is already resolved',
    lower_field: 'The amount must be greater than {min}',
    already_exists: '{field} already exists',
    must_be_adult: 'You must be an adult in your country to continue',
    must_accept_terms: 'Terms & Cond. must be accepted to continue',
    user_blocked: 'Your user is blocked',
    already_pending_withdraw: 'There already is a pending withdraw',
    dont_delete_acquired: 'Cannot be deleted because it has been already acquired',
    dont_delete_premium: 'Cannot delete premium content at the moment'
  },

  termsAndCond: {
    title: 'TERMS AND CONDITIONS',
    section1: {
      title: '1 IDENTIFICATION, ACCEPTANCE AND ACCESS TO THE WEBSITE',
      paragraph1: {
        paragraph1_1: 'These Terms and Conditions regulate the access and use of the Web Site owned by ARTEMISA 3000 TECH SOLUTIONS S.L. (from now on, ',
        paragraph1_2: '@:project_upper',
        paragraph1_3: ') with NIF B-8 8 4 6 7 4 3 and address at Paseo de la Castellana 129, 1ªA, 28006, Madrid (Valid address to receive correspondence)'
      },
      paragraph2: 'You can contact @:project_upper through an email sent to the address: @:contact_email.',
      paragraph3: {
        paragraph3_1: 'The access to the Website @:site, (from now on, the',
        paragraph3_2: '"Website"',
        paragraph3_3: ') implies the acceptance of these Terms and Conditions, as well as the different modifications and/or additional legal texts that @:project_upper may include in the future.'
      },
      paragraph4: '@:project_upper offers the possibility of viewing streaming videos of adult and sexual content through the Website. The User is interested in hiring the services of @:project_upper in order to enjoy the content available through the Website.',
      paragraph5: {
        paragraph5_1: 'In addition, content providers ("',
        paragraph5_2: 'Influencers',
        paragraph5_3: '"), may create their own channel where they upload videos with adult and sexual content for Users to enjoy in streaming through the Website.'
      },
      paragraph6: 'Access and navigation through the Website will require registration of Users and Influencers.'
    },
    section2: {
      title: '2 OBJECT',
      paragraph1: 'The purpose of these Terms and Conditions is to regulate the conditions under which @:project_upper will provide its services to Users and Influencers through the Website.',
      paragraph2: 'The services that @:project_upper will provide to the User will consist of making available streaming videos of sexual content for their enjoyment. On the other hand, the videos offered on the Website, will be videos provided by the Influencers through its own channel located on the Website.',
      paragraph3: 'The content of the services includes videos, audios (e.g. music and other sounds), graphics, text, photos, etc. The content of the videos is the sole responsibility of the Influencer that has uploaded the video to its Channel hosted on the Website. If any User or Influencer would like to report any content that violates these Terms and Conditions, they can do so through the Report Channel or at the following email address @:info_email.',
      paragraph4: 'Also, the description of the service offered to the Users, its characteristics, and the different modalities of contracting are determined in the Web site @:site.'
    },
    section3: {
      title: '3 WEB SITE OPERATION ',
      paragraph1: 'The User and Influencer must create a profile through a login by completing a registration form, which will include an email, password and username.',
      paragraph2: '@:project_upper will proceed to assign a login and password to the User and Influencer for access to the services available on the Website. This login and password allow the authentication of the User for access to the Website, which @:project_upper makes available. @:project_upper uses this system to identify, authenticate and verify the person of the User.',
      paragraph3: {
        paragraph3_1: 'Once registered the User and Influencer will have access to all the contents of the Website, depending on the type of account selected:',
        paragraph3_2: 'Free, Monthly or Video to Video.'
      },
      paragraph4: 'The contents of the Website will be organized according to the following parameters: most viewed videos, latest news, free and channels of each of the Influencers.'
    },
    section4: {
      title: '4 WEBSITE ACCOUNTS',
      section1: {
        title: '4.1 USER ACCOUNT',
        paragraph1: 'In order to access the content published on the Website, the User must create an account through an online form that must be filled in with the data required on the form. They can also sign up via Twitter.',
        paragraph2: 'Users will have full responsibility for the use of their User account. Consequently, Users are responsible for the proper custody and confidentiality of the User name and/or passwords, as well as all data allowing access to their account, and agree not to transfer their use to third parties, either temporarily or permanently, nor to allow access to them by third parties.',
        paragraph3: 'By virtue of the above, users must notify immediately to @:project_upper via email @:info_email any misuse of your username and / or password, due to circumstances such as theft, loss or unauthorized access to them, so that @:project_upper can proceed to its cancellation or blocking and / or disabling as soon as it is aware of the improper use of your user account. While not reported such facts, @:project_upper will be exempt from any liability that may arise from the misuse of usernames or passwords by unauthorized third parties.'
      },
      section2: {
        title: '4.2 INFLUENCERS\' ACCOUNT',
        paragraph1: {
          paragraph1_1: 'Influencers to provide content to the Web site must be registered on the Web site (like the Users), and once registered, they must access the section "Influencer Account" where they must ',
          paragraph1_2: 'complete their registration by uploading a personal photo to the Web site showing the Influencer and their ID, and ',
          paragraph1_3: 'select the form of payment of fees: via bank transfer or debit card. In addition,',
          paragraph1_4: 'it is a requirement for the use of the Influencer Account to be of legal age (18 years), or 21 years of age, depending on the age of majority in your country of origin.'
        },
        paragraph2: 'Once they have successfully completed the registration by meeting all the above requirements, the Influencer may begin to upload content to its Channel for viewing by Users',
        paragraph3: 'Influencers will have full responsibility for the use of their Account and their Channel. Consequently, the Influencers are responsible for the proper custody and confidentiality of the User name and/or passwords, as well as all data, which allows access to your account. The Influencers are exclusively responsible for all the content videos, images, sounds, texts, graphics, music, audios, etc. that they upload to their channel. In this sense, @:project_upper is not responsible for the inclusion by the Influencers in the videos of their channel of any content that allows their personal identification (objects or personal contact details, among others).',
        paragraph4: {
          paragraph4_1: 'In this sense, the Influencer will have full responsibility for its content and the consequences of publishing it. The Influencer guarantees that:',
          paragraph4_2: 'it is the creator and owner of its Influencer Channel Content,',
          paragraph4_3: 'its Influencer Content will not infringe, violate, or usurp any rights of any third party, including copyrights, trademarks, patents, trade secrets, moral rights, privacy rights, image rights, or any other intellectual property rights, or defame any other person; and ',
          paragraph4_4: 'its Influencer Content does not contain viruses, adware, spyware, worms, or any other harmful or malicious code.'
        },
        paragraph5: 'The Influencer is fully responsible that the Content uploaded and published through its Channel does not infringe the intellectual or industrial property rights of third parties, as material protected by copyright',
        paragraph6: '@:project_upper will take reasonable security measures to attempt to protect the Content of the Influencer from unauthorized copying and distribution. Notwithstanding the foregoing, @:project_upper does not guarantee that such unauthorized copying, use or distribution of the Influence Content by third parties will not take place. The Influencer agrees that @:project_upper is not responsible for any copying, use or distribution of the Influencer Content by third parties, and disclaims all liability and claims against @:project_upper for any unauthorized copying or use of the Influencer Content. @:project_upper does not guarantee that the security measures can resist attempts to circumvent the security mechanisms or that there will be no cracks, deactivations or other ways to circumvent these security measures',
        paragraph7: 'The Influencer can remove the videos you want from your Channel at any time, as well as publish as many videos as you want without restriction, always within the law',
        paragraph8: 'Under the above, the Influencers must immediately notify @:project_upper via email @:info_email any misuse of your Influencer name and / or password, due to circumstances such as theft, loss or unauthorized access to them, so that @:project_upper can proceed to its cancellation or blocking and / or disabling as soon as it knows the improper use of your Influencer Account. @:project_upper will be exempt from any responsibility that could derive from the improper use of the names of Influencers or passwords by third parties not authorized.'
      }
    },
    section5: {
      title: '5 CONDUCT OF USERS AND INFLUENCERS ON THE WEBSITE',
      paragraph1: 'The Users and Influencers commit to make a licit, diligent, honest and correct use of all the information or contents that they have access to through the Web site, and all this under the principles of good faith and respecting at all times the current legality and the present Terms and Conditions. It is not permitted to download the Content offered through the Website',
      paragraph2: 'In particular, but without limitation, Users and Influencers shall not:',
      list1: 'Register or communicate data that is not true, accurate, complete and/or updated, nor access the Website using the name, identification data or password of another User or influence or impersonate any person or identity',
      list2: 'Use the Website for fraudulent purposes, or in connection with criminal offences or unlawful activities of any kind ',
      paragraph3: '@:project_upper reserves the right to deny any attempt to access the Website, cancel accounts, remove or modify content, or cancel the provision of Services in cases where there is an incorrect use of the Website',
      section5_1: {
        title: '5.1 PUBLICATION OF CONTENT',
        paragraph1: 'Influencers will be able to publish content of different types and with different viewing permissions.',
        paragraph2: 'The contents should not violate the law and may be reviewed by @:project_upper and withdrawn if deemed necessary for legal reasons, moral or others.',
        section5_1_1: {
          title: '5.1.1 Multimedia Contents',
          paragraph1: 'These are Images, Videos or Texts published on the website by the Influencers. They can be made available to followers in different ways, including free viewing, pay per view, content for subscribers, paid content focused on specific users. The modalities of consumption of the service may be modified at the will of @:project_upper.',
          paragraph2: 'Once the User has paid for the paid content, he will be able to view this content through the Website.'
        },
        section5_1_2: {
          title: '5.1.2 Products in store',
          paragraph1: 'The products offered for sale in the store may be offered for sale directly, or the Influencer may assign a price to the product for direct sale, or auction it, assigning a minimum price at which users may equal or exceed, obtaining the product the user who has offered the highest price for the product.',
          paragraph2: 'The Influencer shall be responsible for delivering the product to the buying User in an efficient and diligent manner within a reasonable time. The shipping method and a tracking number or detailed information may be indicated on the platform to the User who purchased the product.',
          paragraph3: 'The maximum delivery time of the products will be 15 days, unless the Influencer indicates another term in the product description.',
          section5_1_2_1: {
            title: '5.1.2.1 Social Media Publishing',
            paragraph1: 'The user will be able to link his Social Networking accounts with his @:project user profile',
            paragraph2: 'For those accounts linked to your profile, @:project may publish messages related to your activity on @:project.'
          },
          section5_1_2_2: {
            title: '5.1.2.2 Prohibited products',
            paragraph1: 'The sale of products that fall into any of the following categories is expressly prohibited:',
            list1: 'Any type of Pharmacological Product',
            list2: 'Any type of Vitamin Product',
            list3: 'Restorative or recommended for health',
            list4: 'Any type of birth control method'
          }
        }
      },
      section5_2: {
        title: '5.2 PROHIBITED CONTENTS',
        paragraph1: 'Videos or photographs containing any of the following will not be allowed to be published',
        list1: 'Copyrighted material.',
        list2: 'Drugs or any related items.',
        list3: 'Content that incites hatred or violence.',
        list4: 'Child pornography.'
      },
      section5_3: {
        title: '5.3 SUSPENSION AND CANCELLATION OF THE ACCOUNT ',
        section5_3_1: {
          title: '5.3.1 Cancellation of the account by the User/Influencer',
          paragraph1: 'The User may stop using the Service at any time. However, if the User has contracted the Monthly Rate, he must communicate @:project_upper in writing his decision not to continue with the services offered by @:project_upper',
          paragraph2: 'The Influencer can remove Content from your Influencer account, which means closing your @:project_upper channel with the option to first download a copy of your Content. '
        },
        section5_3_2: {
          title: '5.3.2 Cancellations and suspensions by @:project_upper',
          paragraph1: '@:project_upper may suspend or cancel access to the User/Influence account if: ',
          list1: 'are in breach of any of the obligations described in these Terms and Conditions;',
          list2: 'when @:project_upper must do so to comply with a legal requirement or a court order;',
          list3: 'when @:project_upper has reasonable grounds to believe that there has been conduct by the User or the Influencer that gives rise to liability or damage to a User, a third party, or @:project_upper'
        }
      }
    },
    section6: {
      title: '6 PRICES AND PAYMENT ',
      paragraph1: 'All prices of the Services offered through the Website are expressed in Euros and do not include the taxes corresponding to the country where the purchase originates. Before making the payment we will inform you of the taxes applicable in your country to make the payment and be able to offer you the corresponding documentation.',
      section6_1: {
        title: '6.1 PRICES AND PAYMENT BY USERS',
        paragraph1: 'The User will be able to enjoy the contents offered on the Website, depending on the package selected:',
        section6_1_1: {
          title: '6.1.1 Free content',
          paragraph1: 'Content made available to the User free of charge, limited to 1000 monthly views of free content, this figure may vary at the discretion of @:project_upper. This content will be available to registered Users on the platform.'
        },
        section6_1_2: {
          title: '6.1.2 Payment Content',
          paragraph1: 'The User, each time he wants to visualize one of the paid contents under this modality, will have to pay the price assigned to that content. Once the payment has been made, the content will be available to the User through the platform',
          paragraph2: 'The service is provided immediately after payment, giving access to premium web content. All content is distributed exclusively through the website.'
        },
        section6_1_3: {
          title: '6.1.3 Content Subscription',
          paragraph1: 'In this modality the User will be able to make the monthly payment of a fee to be able to subscribe to the contents published by each Influencer. The price of the subscription will be established by the Influencer. This fee may be updated by the Influencer, which will imply the express acceptance of the new fee by the User to continue subscribing to the contents of the Influencer',
          paragraph2: 'The payment of this fee will give the User access to the contents published by the Influencer as long as the subscription is active and up to date.',
          paragraph3: 'The MONTHLY rate will be for one month from the time of the contract, renewable for periods of equal duration, unless the User cancels the subscription within 15 working days before the renewal of the rate.'
        },
        section6_1_4: {
          title: '6.1.4 Methods of Payment',
          paragraph1: 'The User may pay the monthly fee or video to video by the means of payment accepted on the Website: Payment by bank card.',
          paragraph2: 'Within a maximum period of twenty-four (24) hours, @:project_upper will send an email to the User, confirming the details of the contracted service, its price and the details of the option chosen to make the payment for the service offered, as well as the corresponding proof once the payment has been formalized, that is to say, after the acceptance of these Terms and Conditions and the corresponding payment',
          paragraph3: 'Users are guaranteed that the payment process is carried out with absolute security, in accordance with the protocols and security services required',
          paragraph4: 'The User must notify @:project_upper of any undue or fraudulent charges in the method of payment used for the provision of services by sending an email to @:info_email, in the shortest possible time so that @:project_upper can make the appropriate arrangements.'
        }
      },
      section6_2: {
        title: '6.2 RETURN POLICY',
        paragraph1: 'Given that we have informed the user of the prices and conditions of our products and he has accepted the terms and conditions contained in this text, we inform that each claim will be evaluated individually before making any return. By default, only duplicates charges made by the payment gateway will be refunded. When the user has not carried out the automatic unsubscription process within the established period (a user can cancel their subscription for the next renewal period, never for the current one), no refund will be made. Please contact @:info_email to make any claims. If accepted, it will be available between the next 10 working days after confirmation.',
        paragraph2: 'Subscriptions can be cancelled at any time through the website, being active for the period already charged and until the end of it',
        paragraph3: 'In the event that a refund is approved, you will have the amount available on your card within a maximum of 10 working days from confirmation. No refunds are made through other means of payment such as checks or cash.'
      },
      section6_3: {
        title: '6.3 REMUNERATION AND PAYMENT TO INFLUENCERS',
        paragraph1: 'The Influencer will offer to the Users through its own channel hosted in the Web site, the multimedia Contents and Products in store that it considers, always within the Terms and Conditions of the present document. These publications and / or sales / auctions will generate revenues that will be received by @:project_upper by the methods of payment indicated',
        paragraph2: 'In this sense, the following distribution of the total income obtained is defined according to the origin of the income:',
        list1: 'Of the income obtained by subscriptions to the Influencer channel and the tips received, the Influencer will receive 80% of the total and @:project_upper the remaining 20%.',
        list2: 'Of the income received through the Store section, both from direct sales and auctions, the Influencer will receive 70% of the total and @:project_upper the remaining 30%.',
        list3: 'Of the income obtained from the sale of videos, the Influencer will receive 60% of the total and @:project_upper the remaining 40%.',
        list4: 'Of the income obtained from the use of live content, the Influencer will receive 75% of the total and @:project_upper the remaining 25%.',
        list5: 'Of the income obtained from the use of the messaging platform, the Influencer will receive 75% of the total and @:project_upper the remaining 25%.',
        paragraph3: 'The Influencer may request the corresponding remuneration as long as it has a minimum credit balance of 100 euros',
        paragraph4: 'In the case that the Influencer needs an invoice in relation to the invoicing of its services, you should contact the Accounting Department of @:project_upper, at the following email address: @:info_email',
        paragraph5: '@:project_upper will allow Influencer the possibility of being paid through a debit card. This method must be requested by the Influencer through its private area of its profile, specifically in the payment options.'
      }
    },
    section7: {
      title: '7 INFORMATION ABOUT @:project_upper SERVICES',
      paragraph1: 'The data included on the Website in the descriptions of the Services have a purely informative function.',
      paragraph2: 'Therefore, @:project_upper declines any responsibility for the appearance of errors in this information, but undertakes to take all measures within its power to correct, as soon as possible, such errors or omissions after being informed of them',
      paragraph3: 'It is not allowed to download the Contents published through the Website.',
      section7_1: {
        title: '7.1 PUBLISHING/FOLLOWING UP SOCIAL NETWORKING',
        paragraph1: '@:project_upper makes available to the Influencers a Social Network platform in which through the same may communicate with users, publish content in text and image format, always with the compliance of these Terms and Conditions regarding content (point 5.2)'
      },
      section7_2: {
        title: '7.2 MESSAGING CHANNEL',
        paragraph1: '@:project_upper offers users a messaging channel where users can send messages to the Influencers and interact with them in cases of purchase of a product through the established methods or requests related to the videos of the Influencer',
        paragraph2: 'The Influencers will set a price on each of the messages. Also, Influencers will be able to modify the price rates of the messages with the Users. In the event that the Influencer changes the price of the message, the User must accept the new rate and continue the conversation by making the corresponding payment.',
        paragraph3: 'The User who wishes to communicate with the Influencer will make the payment with the funds from his wallet and each message will be paid at the time of generation.',
        paragraph4: 'At any time, one of the two parties may terminate the conversation.'
      },
      section7_3: {
        title: '7.3 PRODUCT AND SERVICE SHOP',
        paragraph1: '@:project_upper offers to Influencers a platform for the sale of products and / or services self-managed. Through it the Influencers will be able to sell the products and/or services that they consider, always within the scope of the current legality.',
        paragraph2: 'For the purchase of products, the User must be registered on the Website, as well as carry out the payment by any of the payment methods offered by @:project_upper.'
      },
      section7_4: {
        title: '7.4 AUCTION PLATFORM',
        paragraph1: '@:project_upper offers users the opportunity to participate in different bids for some of the products that may appear in the videos of the Influencers. The User must be registered on the Website to participate in an auction. The bidding period and its conditions will be included in the mentioned form.'
      },
      section7_5: {
        title: '7.5 LIVE VIDEO PUBLISHING PLATFORM',
        paragraph1: '@:project_upper offers to Influencers a platform for the publication of multimedia contents (video and audio) in real time, contents that can be enjoyed by the Users of the platform.',
        paragraph2: 'The contents may be published according to the different privacy and payment methods described in this document and always in compliance with these Terms and Conditions regarding contents (point 5.2).'
      }
    },
    section8: {
      title: '8 INTELLECTUAL PROPERTY',
      section8_1: {
        title: '8.1 INTELLECTUAL AND INDUSTRIAL PROPERTY RELATING TO THE WEBSITE FOR USERS',
        paragraph1: 'All rights to the content, design and source code of this website and in particular, but not limited to, all rights to logos, designs, trademarks, trade names, data included in the website and any other rights of intellectual property are owned by @:project_upper, or third parties who have expressly authorized @:project_upper for the use of them on its website',
        paragraph2: 'Therefore, and by virtue of the provisions of Royal Legislative Decree 1/1996, of April 12, 1996, which approves the revised text of the Intellectual Property Law, regularizing, clarifying and harmonizing the legal provisions in force on the subject, as well as in Law 17/2001, of December 7, 2001, on Trademarks and complementary legislation on intellectual and industrial property, is expressly prohibited the reproduction, transmission, adaptation, translation, distribution, public communication, including its method of making available, all or part of the contents of the Website owned by @:project_upper, in any medium and by any technical means, unless express permission is granted in writing by @:project_upper',
        paragraph3: '@:project_upper does not grant any license or authorization of use of any kind on their rights of intellectual property or any other property or right related to the Website, and in no case shall be construed that access and navigation of users implies a waiver, transmission, license or transfer all or part of such rights by @:project_upper',
        paragraph4: 'Any use of these contents not previously authorized by @:project_upper will be considered a serious breach of intellectual or industrial property rights and will give rise to the legally established responsibilities'
      },
      section8_2: {
        title: '8.2 INTELLECTUAL AND INDUSTRIAL PROPERTY REGARDING THE WEBSITE IN RELATION TO THE INFLUENCERS',
        paragraph1: 'The Influencers are the owners of all the Content published by them in their Channel and, therefore, owners of the intellectual and industrial property rights that may correspond to them, by virtue of the provisions of the Intellectual Property Law, regularizing, clarifying and harmonizing the legal provisions in force on the subject, as well as in Law 17/2001, of December 7, 2001, on Trademarks and complementary legislation on intellectual and industrial property',
        paragraph2: 'At no time, @:project_upper will be held legally responsible for any infringement of any unauthorized published Content protected by law',
        paragraph3: 'However, if @:project_upper has reasonable grounds to believe that the Content published by the Influencer in its Channel, breaches the provisions of these Terms and Conditions or that may generate a damage to @:project_upper, our Users or third parties, @:project_upper may remove or disable such Content, either in part or in whole. In this case, @:project_upper will inform the Influencer of the reason for this action, unless it has reasonable grounds to believe that doing so would: violate the law or the guidelines of a competent authority, or would entail a legal responsibility for @:project_upper'
      }
    },
    section9: {
      title: '9 AVAILABILITY AND INTERRUPTION OF SERVICE',
      paragraph1: {
        paragraph1_1: 'Access to the Website is available 24 hours, every day of the year, being expressly accepted by the User and Influencer that @:project_upper reserves the right to suspend access to the service particularly in the following cases: ',
        paragraph1_2: 'for the maintenance needs of the Website (including the corresponding updates),',
        paragraph1_3: 'for the improvement and installation of new functionalities of the Website,',
        paragraph1_4: 'for the verification of the functioning and use of the Website, as well as ',
        paragraph1_5: 'to solve any incidence in case of breakdown or possibility of breakdown. '
      },
      paragraph2: 'In no case, @:project_upper will compensate the User or Influencer with any compensation or payment of any amount, as a result of the facts indicated in this Clause.',
      paragraph3: {
        paragraph3_1: 'The User and Influencer acknowledge and accept ',
        paragraph3_2: 'the characteristics and limits of the Internet,',
        paragraph3_3: 'that the confidentiality of data transmitted over the Internet cannot be fully guaranteed and that this data may be intercepted by malicious users, ',
        paragraph3_4: 'and that the response times of the services may be affected by network malfunctioning.'
      }
    },
    section10: {
      title: '10 EXCLUSION OF LIABILITY',
      paragraph1: '@:project_upper does not give any guarantee nor is responsible, in any case, for damages of any kind that may arise from access or use of the contents of the Website. Among others, and by way of illustration and not limitation, @:project_upper is not responsible for the following circumstances: ',
      list1: 'For the lack of availability, maintenance and effective operation of the Website and/or its services or content, excluding, to the maximum extent permitted by current legislation, any liability for damages of any kind that may be due to the lack of availability or continuity of operation of the Website.',
      list2: 'Of the lack of usefulness of the Website or the contents for any service',
      list3: 'Of the content of other Internet pages to which links placed on the Website may be directed',
      list4: 'From third parties who might appear in the Influencer videos',
      list5: 'Of the illicit, negligent, fraudulent use, contrary to the present Terms and Conditions or to the good faith, of the Website or its contents, on the part of the Users and Influencers, including any infraction of the rights of Intellectual and/or Industrial Property of @:project_upper or of third parties',
      list6: 'Of the incidents caused by the lack of due diligence by the User and Influencer, as well as the improper use of the Website by any of them. ',
      list7: 'Of the publication through the Website of the comments and opinions that infringe the rules mentioned in these Terms and Conditions, the applicable legislation or the rights of third parties',
      list8: 'Of the cases of force majeure, understood as the failure, suspension or interruption of services or use of the Website as a result of energy restrictions, blocking of telecommunications or the Internet network, actions or omissions of third parties, telecommunications operators or service companies, supply or transport or any other cause or circumstance beyond the control of @:project_upper that prevent the normal use of the Website.'
    },
    section11: {
      title: '11 HYPERLINKS',
      paragraph1: 'The Website may contain hyperlinks that allow the User to access third party websites. @:project_upper assumes no responsibility for the content, information or services that may appear on these sites, which will be offered exclusively for information purposes by @:project_upper, and in no case imply any relationship, acceptance or support between @:project_upper and the persons or entities owners of such content or owners of the sites where they are'
    },
    section12: {
      title: '12 DISPUTE RESOLUTION',
      paragraph1: 'These Terms and Conditions are subject to Spanish law. The parties, in accordance with the Royal Legislative Decree 1/2007, of 16 November, which approves the revised text of the General Law for the Defence of Consumers and Users and other complementary laws, are subject to the Courts and Tribunals of the User\'s place of residence',
      paragraph2: 'On the other hand, for the interpretation and resolution of the conflicts that could arise between the Influencer and @:project_upper for any discrepancy, question or claim resulting from the execution or interpretation of the present Terms and Conditions they are submitted to the jurisdiction of the Courts and Tribunals of Madrid Capital'
    },
    section13: {
      title: '13 MODIFICATIONS',
      paragraph1: '@:project_upper reserves the right to make as many changes as it deems appropriate in these Terms and Conditions, in which case they will be communicated in advance to users',
      paragraph2: 'These modifications will be valid from the moment they are published on the Website.'
    },
    section14: {
      title: '14 SAFEGUARD CLAUSE',
      paragraph1: 'All the clauses or extremes of these Terms and Conditions must be interpreted independently and autonomously, the rest of the stipulations not being affected in case one of them has been declared null and void by a judicial sentence or final arbitration resolution. The affected clause or clauses will be replaced by another or others that preserve the effects sought by the Terms and Conditions'
    }
  },
  cookies: {
    accept: 'Accept',
    showCookieInfo: 'More Info',
    content: {
      part1: 'This website uses analytical and validation cookies to allow access to content and analyze traffic. By pressing ',
      part2: ' or continue browsing, you accept its use. If you continue browsing, we understand that you accept their use. If you want more information about the use of cookies, you can consult it at the following link: '
    },
    title: 'Cookies Policy',
    paragraph1: `<img src="images/artemisa_{color}.png" style="margin-bottom: -3px; width: 180px; height: 14px"/> (onwards, the "OWNER") informs, through this Cookies Policy, about the use of data storage and recovery devices in terminal equipment of the Users.`,
    access: {
      question: '1 What are Cookies?',
      answer: {
        part1: 'Cookies are files that are downloaded to the User\'s computer / smartphone / tablet when accessing to certain web pages and applications that allow the user\'s browsing preferences to be stored, making the interaction between the User and the Website faster and easy.',
        part2: 'The information collected by cookies is anonymous and doesn\'t contain any sensitive information since they don\'t collect data that can personally identify the User. In any case, the User may access the settings of their browser to modify and / or block the installation of Cookies sent from the Website, without preventing access to the content.'
      }
    },
    object: {
      question: '2 What does the owner use cookies for?',
      answer: {
        part1: 'In accordance with Recital 30 of the General Data Protection Regulation ("RGPD"), this Website uses cookies or other devices for storing and retrieving information to track the interactions of Users with the services offered in the website.',
        part2: 'Cookies allow the recognition of a User\'s browser, as well as the type of device from which the Website is accessed and are used to facilitate the User\'s next visit and make the Website more useful to them.'
      }
    },
    functionallity1: {
      question: '3 How to disable cookies?',
      answer: {
        part1: 'Those cookies that aren\'t mandatory for browsing this Website may be disabled in the configuration of the Website. These settings are found in the footer of the Website. In addition, all browsers allow changes to be made to disable cookie settings.',
        part2: 'This is the reason why most browsers offer the possibility of managing cookies, to obtain more precise control over privacy. These settings are located in the "options" or "preferences" of the menu of your browser.',
        part3: 'Here you can find links to each browser to disable cookies by following directions:',
        part4: 'If you don\'t want to be tracked by cookies, Google has developed a plug-in to install in your browser that you can access at the following link: '
      }
    },
    functionallity2: {
      question: '4 Cookies in Mobile Devices',
      answer: {
        part1: 'The owner of the Website also uses cookies or other storage devices on mobile devices.',
        part2: 'Those cookies that aren\'t mandatory for browsing this Website may be disabled in the configuration of the Website.',
        part3: 'These settings can be found in the footer of the Website. In addition, as in computer browsers, mobile device browsers allow you to make changes to privacy options or settings to disable or delete cookies.',
        part4: 'If you want to modify the privacy options, follow the instructions specified by the developer of your mobile device browser.'
      }
    },
    conduct: {
      question: '5 Cookie acceptance',
      answer: {
        part1: 'This Website doesn\'t install our cookies on users\' devices until they have accepted their installation.',
        part2: 'We inform you that in the case of not accepting the installation of cookies or disabling any of these in the configuration, it\'s possible that certain services are not available without the use of these or that you can\'t access certain services or take full advantage of everything what this Website offers you.'
      }
    }
  },
  category: {
    title: 'Choose your Preferences',
    subtitle: 'Enhance the @:project Experience',
    all: 'All',
    straight: 'Straight',
    homo: 'Gay Boys',
    trans: 'Trans'
  }
}
